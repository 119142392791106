import { useState } from 'react';
import { Grid, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { DefaultButton } from 'ui/DefaultButton';
import { useLoader } from 'contexts/LoaderContext';

const useConfirmAlert = (title, body, onSuccess, onFailure) => {
    const { isAlertOpen,closeAlert } = useLoader();

    const handleClose = (confirmed) => {
        closeAlert();
        if (confirmed) {
            onSuccess();
        } else {
            onFailure();
        }
    };

    return (
        <Dialog open={isAlertOpen} onClose={() => handleClose(false)}>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                <DialogContentText>{body}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Grid spacing={1} container justifyContent={"center"}>
                    <Grid item >
                        <DefaultButton onClick={() => handleClose(false)} color="error">
                            No
                        </DefaultButton>
                    </Grid>
                    <Grid item>
                        <DefaultButton onClick={() => handleClose(true)} color="success" autoFocus>
                            Yes
                        </DefaultButton>
                    </Grid>
                </Grid>
            </DialogActions>
        </Dialog>
    );
};

export default useConfirmAlert;
