import React, { useEffect, useState, useMemo } from 'react';
import { Box, ButtonBase, Grid, Stack, Typography, MenuItem, Backdrop, CircularProgress } from '@mui/material';
import { Popup } from 'components/Popup/index.js';
import { DefaultInput } from 'ui/DefaultInput/index.js';
import { getSiteDetailByIDApi, getUserDataApi, manageUserApi } from './UsersRepository';
import { useLoader } from 'contexts/LoaderContext';
import { enqueueSnackbar } from 'notistack';
import { DefaultButton } from 'ui/DefaultButton';
import { DefaultSelect } from 'ui/DefaultSelect';
import { arrayRole } from 'modules/Settings/data/role';
import { Formik } from 'formik';
import * as Yup from 'yup';
import api from 'lib/axios';
import { ConfirmAlert } from 'CustomControls/confirmAlert copy';
const ManageUser = ({ userID, setIsOpenedUserDetails, getData }) => {
    const { openAlert } = useLoader();
    const [isLoading, setIsLoading] = useState(false);
    const [arrayShifts, setArrayShifts] = useState([]);
    const [selectedRole, setSelectedRole] = useState(null);
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [mobileNumber, setMobileNumber] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [siteName, setSiteName] = useState("");
    const [selectedShift, setSelectedShift] = useState(null);
    const [isAllowNotifications, setIsAllowNotifications] = useState(true);
    const [isEmailNotifications, setIsEmailNotifications] = useState(true);
    const [isMobileNotifications, setIsMobileNotifications] = useState(true);
    const [deleteConfirmationShouldOpen, setDeleteConfirmationShouldOpen] = useState(false);

    useEffect(() => {
        setIsLoading(true);
        getSideDetailByID();
    }, []);

    useEffect(() => {
        if (userID !== "" && userID !== "0") {
            getUserData();
        } else {
            setIsLoading(false);
        }
    }, [userID, siteName]);

    const getSideDetailByID = async () => {
        setIsLoading(true);
        var siteDetail = await getSiteDetailByIDApi();
        if (siteDetail) {
            const { shifts, companySiteData } = siteDetail
            setArrayShifts(shifts || []);
            setSiteName(companySiteData?.name || "");
        }

    };

    const getUserData = async () => {
        const userData = await getUserDataApi(userID);
        if (userData && arrayShifts.length > 0) {
            const { firstName, lastName, email, mobile, role, productionShifts, allowNotifications, emailNotifications, mobileNotifications, siteName, zoneID } = userData;
            setFirstName(firstName || '');
            setLastName(lastName || '');
            setEmail(email || '');
            setMobileNumber(mobile || '');
            setSelectedRole(arrayRole.find((r) => r.id == role) || null);
            setSelectedShift(arrayShifts.find(s => s?._id == productionShifts?._id) || null);
            setIsAllowNotifications(allowNotifications || false);
            setIsEmailNotifications(emailNotifications || false);
            setIsMobileNotifications(mobileNotifications || false);
            setSiteName(siteName || '');
        }
        setIsLoading(false);
    };

    async function apiCalling(values) {
        var data = {
            companyID: localStorage.getItem("publicID"),
            siteID: localStorage.getItem("siteID"),
            firstName: values.firstName,
            lastName: values.lastName,
            email: values.email,
            siteName: values.siteName,
            mobile: values.mobileNumber,
            role: values.selectedRole.id,
            ...(userID === "0" && { password: values.password }),
            productionShifts: values.selectedShift,
            allowNotifications: isAllowNotifications,
            emailNotifications: isEmailNotifications,
            mobileNotifications: isMobileNotifications,
        }
        console.log("userID", userID);
        if (userID != "0") {
            return await api.put('/apis/companyUsers/' + userID, data)
        } else {
            return await api.post('/apis/companyUsers/', data)
        }
    }

    function deleteUser() {
        setIsLoading(true)
        api.delete('/apis/companyUsers/' + userID).then((res) => {
            if (res.data.success) {
                enqueueSnackbar("User deleted", {
                    anchorOrigin: {
                        horizontal: 'right',
                        vertical: 'top'
                    },
                    variant: 'success'
                });
                setIsOpenedUserDetails(null)
                getData()
            } else {
                setDeleteConfirmationShouldOpen(false)
                enqueueSnackbar(res.data.message, {
                    anchorOrigin: {
                        horizontal: 'right',
                        vertical: 'top'
                    },
                    variant: 'error'
                });
            }
            setIsLoading(false)
        }).catch(function (error) {
            console.log(error);
        });
    }

    const updateConfirmAlert = (value) => {
        if (value) {
            deleteUser()
        } else {
            setDeleteConfirmationShouldOpen(false)
        }
    }
    return (
        <Popup
            isOpened
            width={700}
            bgcolor={'common.white'}
            sx={{
                bottom: 20,
                right: 20,
                border: '1px solid #DBDDE2',
                borderColor: '#DBDDE2',
                borderRadius: '10px',
            }}
        >
            <Formik
                enableReinitialize
                initialValues={{
                    submit: null,
                    firstName: firstName,
                    lastName: lastName,
                    selectedRole: selectedRole,
                    selectedShift: selectedShift,
                    mobileNumber: mobileNumber,
                    email: email,
                    password: password,
                    confirmPassword: confirmPassword,
                    siteName: siteName
                }}
                validationSchema={Yup
                    .object()
                    .shape({
                        firstName: Yup.string().required('First name is required'),
                        lastName: Yup.string().required('Last name is required'),
                        selectedRole: Yup.object().required('Role is required'),
                        selectedShift: Yup.object().required('Shift is required'),
                        mobileNumber: Yup.string().required('Cellular is required'),
                        email: Yup.string().email('Must be a valid email').required('Email is required'),
                        password: userID == "0" ? Yup.string().required('Password is required') : "",
                        confirmPassword: userID == "0" ? Yup.string().required('Confirm password is required').oneOf([Yup.ref('password')], 'Password does not same') : "",
                    })}
                onSubmit={async (values, { resetForm, setErrors, setStatus, setSubmitting }) => {
                    console.log("values::", values)
                    try {
                        setIsLoading(true)
                        setFirstName(values.firstName)
                        setLastName(values.lastName)
                        setSelectedRole(values.selectedRole)
                        setSelectedShift(values.selectedShift)
                        setMobileNumber(values.mobileNumber)
                        setEmail(values.email)
                        setPassword(values.password)
                        setConfirmPassword(values.confirmPassword)
                        setSiteName(values.siteName)
                        var data = await apiCalling(values)
                        setIsLoading(false)
                        if (data !== undefined && data.data.success) {
                            setStatus({ success: true });
                            setSubmitting(false);
                            enqueueSnackbar('User ' + (userID == "0" ? "added" : "updated"), {
                                anchorOrigin: {
                                    horizontal: 'right',
                                    vertical: 'top'
                                },
                                variant: 'success'
                            });
                            setIsOpenedUserDetails(null)
                            getData()
                        } else {
                            setStatus({ success: false });
                            setErrors({ submit: data.data.message });
                            setSubmitting(false);
                            enqueueSnackbar(data.data.message, {
                                anchorOrigin: {
                                    horizontal: 'right',
                                    vertical: 'top'
                                },
                                variant: 'error'
                            });
                        }
                    } catch (err) {
                        console.error(err);
                        setStatus({ success: false });
                        setErrors({ submit: err.message });
                        setSubmitting(false);
                    }
                }}
            >
                {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values, setFieldValue }) => (
                    <form
                        onSubmit={handleSubmit}
                        style={{ height: "100%" }}
                    >
                        <Stack px={3} pt={3} pb={3} gap={'32px'} height={'100%'} alignItems={'flex-start'}>
                            <Typography fontSize={20}>User Details</Typography>
                            <Box width={'100%'}>
                                <Grid spacing={3} container>
                                    <Grid item xs={4}>
                                        <DefaultInput
                                            label={'First Name'}
                                            placeholder={'E.g. xyz'}
                                            value={values.firstName}
                                            name={"firstName"}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            error={Boolean(touched.firstName && errors.firstName)}
                                            helperText={touched.firstName && errors.firstName}
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <DefaultInput
                                            label={'Last Name'}
                                            placeholder={'E.g. xyz'}
                                            value={values.lastName}
                                            name={"lastName"}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            error={Boolean(touched.lastName && errors.lastName)}
                                            helperText={touched.lastName && errors.lastName}
                                        />
                                    </Grid>
                                    <Grid item xs={4} />
                                    <Grid item xs={4}>
                                        <DefaultSelect
                                            onBlur={handleBlur}
                                            onChange={(event, newValue) => {
                                                values.selectedRole = newValue
                                                console.log("st", newValue)
                                            }}
                                            helperText={touched.selectedRole && errors.selectedRole && <p>Select role</p>}
                                            error={Boolean(touched.selectedRole && errors.selectedRole)}
                                            value={values.selectedRole}
                                            options={arrayRole}
                                            getOptionLabel={(option) => option.value}
                                            isOptionEqualToValue={(option, value) => value != null ? option._id === value._id : ""}
                                            name="selectedRole"
                                            label={"Role"}
                                            placeholder="Select Role" />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <DefaultSelect
                                            onBlur={handleBlur}
                                            onChange={(event, newValue) => {
                                                values.selectedShift = newValue
                                                console.log("st", newValue)
                                            }}
                                            helperText={touched.selectedShift && errors.selectedShift && <p>Select shift</p>}
                                            error={Boolean(touched.selectedShift && errors.selectedShift)}
                                            value={values.selectedShift}
                                            options={arrayShifts}
                                            getOptionLabel={(option) => option.name}
                                            isOptionEqualToValue={(option, value) => value != null ? option._id === value._id : ""}
                                            name="selectedShift"
                                            label={"Shift"}
                                            placeholder="Select Shift" />
                                    </Grid>
                                    <Grid item xs={4} />
                                    <Grid item xs={4}>
                                        <DefaultInput
                                            label={'Mobile'}
                                            placeholder={'E.g. 1234567891'}
                                            value={values.mobileNumber}
                                            name={"mobileNumber"}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            error={Boolean(touched.mobileNumber && errors.mobileNumber)}
                                            helperText={touched.mobileNumber && errors.mobileNumber}
                                        />
                                    </Grid>
                                    <Grid item xs={8}>
                                        <DefaultInput
                                            label={'Email'}
                                            placeholder={'E.g. example@test.com'}
                                            value={values.email}
                                            name={"email"}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            error={Boolean(touched.email && errors.email)}
                                            helperText={touched.email && errors.email}
                                        />
                                    </Grid>
                                </Grid>
                            </Box>
                            {userID == "0" && (<>
                                <Box height={'1px'} width={'80%'} border={'1px solid #DBDDE2'} />
                                <Box width={'100%'}>
                                    <Grid spacing={3} container>
                                        <Grid item xs={4}>
                                            <DefaultInput
                                                label={'Password'}
                                                placeholder={'E.g. **************'}
                                                type={'password'}
                                                value={values.password}
                                                name={"password"}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                error={Boolean(touched.password && errors.password)}
                                                helperText={touched.password && errors.password}
                                            />
                                        </Grid>
                                        <Grid item xs={4}>
                                            <DefaultInput
                                                label={'Confirm Password'}
                                                placeholder={'E.g. **************'}
                                                type={'password'}
                                                value={values.confirmPassword}
                                                name={"confirmPassword"}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                error={Boolean(touched.confirmPassword && errors.confirmPassword)}
                                                helperText={touched.confirmPassword && errors.confirmPassword}
                                            />
                                        </Grid>
                                    </Grid>
                                </Box>
                            </>
                            )}
                            <Box height={'1px'} width={'80%'} border={'1px solid #DBDDE2'} />
                            {/* <Stack spacing={1}>
                                <Typography fontWeight={700}>Invitation Email</Typography>
                                <ButtonBase
                                    sx={{
                                        bgcolor: 'blue.light',
                                        px: 3,
                                        py: 1,
                                        borderRadius: '5px',
                                        fontWeight: 700,
                                    }}
                                >
                                    Send
                                </ButtonBase>
                            </Stack> */}
                            <Stack direction={'row'} spacing={1} mt={'auto'} width={'100%'}>
                                <Box flexGrow={1}>
                                    {userID != "0" && (<DefaultButton
                                        onClick={() => {
                                            setDeleteConfirmationShouldOpen(true)
                                        }}
                                        color={'error'}>
                                        Delete
                                    </DefaultButton>)}
                                </Box>
                                <DefaultButton onClick={() => setIsOpenedUserDetails(null)}>Cancel</DefaultButton>
                                <DefaultButton
                                    color={'success'}
                                    disabled={isSubmitting}
                                    type="submit"
                                    onClick={() => {
                                        console.log("errors", errors);
                                    }}
                                >
                                    Save
                                </DefaultButton>
                            </Stack>
                        </Stack>
                    </form>
                )}

            </Formik>
            {
                deleteConfirmationShouldOpen && (<ConfirmAlert title={"Confirmation"} body={"Are you sure you want to delete?"}
                    updateConfirmAlert={(e, value) => {
                        console.log(e, value)
                        setDeleteConfirmationShouldOpen(false)
                        updateConfirmAlert(value)
                    }} />)
            }
            <Backdrop style={{ zIndex: 1 }} open={isLoading} >
                <CircularProgress color="inherit" />
            </Backdrop>
        </Popup>
    );
};

export default React.memo(ManageUser);
