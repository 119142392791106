import React, { useEffect, useState } from 'react';
import { Box, Grid, Stack, Typography, Backdrop, CircularProgress } from '@mui/material';
import { Popup } from 'components/Popup/index.js';
import { DefaultInput } from 'ui/DefaultInput/index.js';
import { arrayTimeZone } from 'modules/Settings/tabs/Site/SitesRepository.jsx';
import { useLoader } from 'contexts/LoaderContext';
import { enqueueSnackbar } from 'notistack';
import { DefaultButton } from 'ui/DefaultButton';
import { DefaultSelect } from 'ui/DefaultSelect';
import { Formik } from 'formik';
import * as Yup from 'yup';
import api from 'lib/axios';
import { ConfirmAlert } from 'CustomControls/confirmAlert copy';

const ManageSite = ({ siteID, setIsOpenedSiteDetails, arrayDivision }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [arrayZones, setArrayZones] = useState([]);
    const [name, setName] = useState("");
    const [managerName, setManagerName] = useState("");
    const [email, setEmail] = useState("");
    const [telephone, setTelephone] = useState("");
    const [addressOne, setAddressOne] = useState("");
    const [addressTwo, setAddressTwo] = useState("");
    const [city, setCity] = useState("");
    const [state, setState] = useState("");
    const [zipCode, setZipCode] = useState("");
    const [selectedTimeZone, setSelectedTimeZone] = useState(null);
    const [selectedZone, setSelectedZone] = useState("");
    const [overTimeFatigue, setOverTimeFatigue] = useState("");
    const [shouldCountAdjusting, setShouldCountAdjusting] = useState(false);
    const [selectedDivision, setSelectedDivision] = useState(null);
    const [deleteConfirmationShouldOpen, setDeleteConfirmationShouldOpen] = useState(false);

    useEffect(() => {
        console.log("siteID:", siteID)
        if (siteID !== "0") {
            getSideDetailByID(arrayDivision);
        }
    }, [siteID]);

    // function getDivision() {
    //     api.get('/apis/Divisions/getAll').then((res) => {
    //         if (res.data.success) {
    //             var arrayDivision = res.data.data
    //             setArrayDivision(arrayDivision)
    //         }
    //        
    //     }).catch(function (error) {
    //         console.log(error);
    //     });
    // }

    // const getSideDetailByID = async () => {
    //     var siteDetail = await getSiteDetailByIDApi();
    //     if (siteDetail) {
    //         setIsLoading(true);
    //         const { arrayZones: selectedZones, shifts, companySiteData } = siteDetail
    //         setArrayZones(selectedZones || []);
    //         if (companySiteData) {
    //             const { addressTwo, city, name, addressOne, telephone, state, country, zipCode, regionName, managerName, email, selectedTimeZone, finishZoneID, shouldCountAdjusting, devisionID } = companySiteData;
    //             setAddressOne(addressOne || '');
    //             setAddressTwo(addressTwo || '');
    //             setCity(city || '');
    //             setState(state || '');
    //             setCountry(country || '');
    //             setName(name || '');
    //             setManagerName(managerName || '');
    //             setEmail(email || '');
    //             setTelephone(telephone || '');
    //             setZipCode(zipCode || '');
    //             setRegionName(regionName || '');
    //             setShouldCountAdjusting(shouldCountAdjusting || false);
    //             setSelectedDivision(arrayDivision.find(division => division._id == devisionID) || null)
    //             setSelectedZone(selectedZones.find(z => z?._id == finishZoneID) || null);
    //             setSelectedTimeZone(arrayTimeZone.find(tz => tz.value == selectedTimeZone) || null);
    //         }
    //     }
    //     setIsLoading(false)
    // };

    function getSideDetailByID(arrayDivision) {
        setIsLoading(true)
        api.post('/apis/companysites/byID', { id: localStorage.getItem("siteID") }).then((res) => {
            if (res.data.success) {
                var siteDetail = res.data.data
                console.log("companySiteData", siteDetail.companySiteData);
                setAddressOne(siteDetail.companySiteData.addressOne);
                setAddressTwo(siteDetail.companySiteData.addressTwo);
                setCity(siteDetail.companySiteData.city);
                setState(siteDetail.companySiteData.state);
                setName(siteDetail.companySiteData.name);
                setManagerName(siteDetail.companySiteData.managerName);
                setEmail(siteDetail.companySiteData.email);
                setTelephone(siteDetail.companySiteData.telephone);
                setZipCode(siteDetail.companySiteData.zipCode);
                setShouldCountAdjusting(siteDetail.companySiteData.shouldCountAdjusting);
                setOverTimeFatigue(siteDetail.companySiteData.overTimeFatigue);
                setSelectedDivision(arrayDivision.find(division => division._id == siteDetail.companySiteData.devisionID))
                setSelectedZone(siteDetail.arrayZones.find(z => z?._id == siteDetail.companySiteData.finishZoneID));
                setSelectedTimeZone(arrayTimeZone.find(tz => tz.value == siteDetail.companySiteData.timeZone));
            }
            setIsLoading(false)
        }).catch(function (error) {
            console.log(error);
        });
    }

    // const validateEmail = (email) => {
    //     const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    //     return emailRegex.test(email);
    // };

    // const validateFormData = () => {
    //     if (!name.trim()) {
    //         return "Name is required.";
    //     } else if (!managerName.trim()) {
    //         return "Manager's name is required.";
    //     } else if (!telephone.trim()) {
    //         return "Telephone number is required.";
    //     } else if (!email.trim()) {
    //         return "Email address is required.";
    //     } else if (!validateEmail(email)) {
    //         return "Please enter a valid email address.";
    //     } else if (!addressOne.trim()) {
    //         return "Address is required.";
    //     } else if (!city.trim()) {
    //         return "City is required.";
    //     } else if (!state.trim()) {
    //         return "State is required.";
    //     } else if (!zipCode.trim()) {
    //         return "Zip code is required.";
    //     } else if (!country.trim()) {
    //         return "Country is required.";
    //     } else if (!regionName.trim()) {
    //         return "Region name is required.";
    //     } else if (!selectedTimeZone || (selectedTimeZone && selectedTimeZone.value == "")) {
    //         return "Time selectedZone is required.";
    //     }

    //     return null;
    // };

    // const submitForm = async () => {
    //     const validationData = validateFormData();
    //     if (validationData) {
    //         enqueueSnackbar(validationData, {
    //             anchorOrigin: {
    //                 horizontal: 'right',
    //                 vertical: 'top'
    //             },
    //             variant: 'error'
    //         });
    //         return;
    //     }
    //     setIsLoading(true);
    //     var siteDetail = await manageSiteApi(siteID == "0" ? "" : siteID, {
    //         companyID: localStorage.getItem("publicID"),
    //         addressTwo,
    //         city,
    //         name,
    //         addressOne,
    //         telephone,
    //         state,
    //         country,
    //         zipCode,
    //         regionName,
    //         managerName,
    //         shouldCountAdjusting,
    //         selectedTimeZone: selectedTimeZone.value,
    //         email,
    //         finishZoneID: selectedZone?._id || "",
    //     });
    //     setIsLoading(false);
    //     if (siteDetail) {
    //         setIsOpenedSiteDetails("");
    //         window.location.reload()
    //     }
    // };

    async function apiCalling(values) {
        var data = {
            companyID: localStorage.getItem("publicID"),
            name: values.name,
            addressOne: values.addressOne,
            addressTwo: values.addressTwo,
            city: values.city,
            state: values.state,
            zipCode: values.zipCode,
            email: values.email,
            telephone: values.telephone,
            managerName: values.managerName,
            devisionID: values.selectedDivision._id,
            overTimeFatigue: values.overTimeFatigue,
            shouldCountAdjusting: shouldCountAdjusting,
            timeZone: values.selectedTimeZone.value,
            finishZoneID: values.selectedZone?._id
        }
        if (siteID != "0") {
            return await api.put('/apis/companysites/' + siteID, data)
        } else {
            return await api.post('/apis/companysites/', data)
        }
    }

    function deleteSite() {
        setIsLoading(true)
        api.delete('/apis/companysites/' + siteID).then((res) => {
            if (res.data.success) {
                enqueueSnackbar("Site deleted", {
                    anchorOrigin: {
                        horizontal: 'right',
                        vertical: 'top'
                    },
                    variant: 'success'
                });
                localStorage.setItem("timeZone", "")
                localStorage.setItem("siteID", "")
                window.location.href = "/"
            } else {
                setDeleteConfirmationShouldOpen(false)
                enqueueSnackbar(res.data.message, {
                    anchorOrigin: {
                        horizontal: 'right',
                        vertical: 'top'
                    },
                    variant: 'error'
                });
            }
            setIsLoading(false)
        }).catch(function (error) {
            console.log(error);
        });
    }

    const updateConfirmAlert = (value) => {
        if (value) {
            deleteSite()
        } else {
            setDeleteConfirmationShouldOpen(false)
        }
    }

    return (
        <Popup
            isOpened
            width={700}
            bgcolor={'common.white'}
            sx={{
                bottom: 20,
                right: 20,
                border: '1px solid #DBDDE2',
                borderColor: '#DBDDE2',
                borderRadius: '10px',
            }}
        >
            <Formik
                enableReinitialize
                initialValues={{
                    submit: null,
                    name: name,
                    managerName: managerName,
                    telephone: telephone,
                    email: email,
                    addressOne: addressOne,
                    addressTwo: addressTwo,
                    city: city,
                    state: state,
                    zipCode: zipCode,
                    selectedDivision: selectedDivision,
                    overTimeFatigue: overTimeFatigue,
                    selectedZone: selectedZone,
                    selectedTimeZone: selectedTimeZone,
                }}
                validationSchema={Yup
                    .object()
                    .shape({
                        name: Yup.string().required('Site name is required'),
                        managerName: Yup.string().required('Manager name is required'),
                        telephone: Yup.string().required('Cellular is required'),
                        email: Yup.string().email('Must be a valid email').required('Email is required'),
                        addressOne: Yup.string().required('Address one is required'),
                        addressTwo: Yup.string().required('Address two is required'),
                        city: Yup.string().required('City is required'),
                        state: Yup.string().required('State is required'),
                        zipCode: Yup.string().required('Zip code is required'),
                        selectedDivision: Yup.object().required('Division is required'),
                        overTimeFatigue: Yup.string().required('Overtime fatigue is required'),
                        selectedZone: localStorage.getItem("assetTrackingEnabled") === "1" ? Yup.object().required('Zone is required') : "",
                        selectedTimeZone: Yup.object().required('Time Zone is required'),
                    })}
                onSubmit={async (values, { resetForm, setErrors, setStatus, setSubmitting }) => {
                    console.log("values::", values)
                    try {
                        setIsLoading(true)
                        setName(values.name)
                        setManagerName(values.managerName)
                        setTelephone(values.telephone)
                        setEmail(values.email)
                        setAddressOne(values.addressOne)
                        setAddressTwo(values.addressTwo)
                        setCity(values.city)
                        setState(values.state)
                        setZipCode(values.zipCode)
                        setSelectedDivision(values.selectedDivision)
                        setSelectedZone(values.selectedZone)
                        setSelectedTimeZone(values.selectedTimeZone)
                        var data = await apiCalling(values)
                        setIsLoading(false)
                        if (data !== undefined && data.data.success) {
                            setStatus({ success: true });
                            setSubmitting(false);
                            enqueueSnackbar('Site ' + (siteID == "0" ? "added" : "updated"), {
                                anchorOrigin: {
                                    horizontal: 'right',
                                    vertical: 'top'
                                },
                                variant: 'success'
                            });
                            setIsOpenedSiteDetails(null)
                            window.location.reload()
                        } else {
                            setStatus({ success: false });
                            setErrors({ submit: data.data.message });
                            setSubmitting(false);
                            enqueueSnackbar(data.data.message, {
                                anchorOrigin: {
                                    horizontal: 'right',
                                    vertical: 'top'
                                },
                                variant: 'error'
                            });
                        }
                    } catch (err) {
                        console.error(err);
                        setStatus({ success: false });
                        setErrors({ submit: err.message });
                        setSubmitting(false);
                    }
                }}
            >
                {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values, setFieldValue }) => (
                    <form
                        onSubmit={handleSubmit}
                        style={{ height: "100%" }}
                    >
                        <Stack px={3} pt={3} pb={3} gap={'32px'} height={'100%'} alignItems={'flex-start'}>
                            <Typography fontSize={20}>Site Details</Typography>
                            <Box width={'100%'}>
                                <Grid spacing={3} container>
                                    <Grid item xs={4}>
                                        <DefaultInput
                                            label={'Site Name'}
                                            placeholder={'E.g. xyz'}
                                            value={values.name}
                                            name={"name"}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            error={Boolean(touched.name && errors.name)}
                                            helperText={touched.name && errors.name}
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <DefaultInput
                                            label={'Manager Name'}
                                            placeholder={'E.g. xyz'}
                                            value={values.managerName}
                                            name={"managerName"}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            error={Boolean(touched.managerName && errors.managerName)}
                                            helperText={touched.managerName && errors.managerName}
                                        />
                                    </Grid>
                                    <Grid item xs={4} />
                                    <Grid item xs={4}>
                                        <DefaultInput
                                            label={'Mobile'}
                                            placeholder={'E.g. 1234567890'}
                                            value={values.telephone}
                                            name={"telephone"}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            error={Boolean(touched.telephone && errors.telephone)}
                                            helperText={touched.telephone && errors.telephone}
                                        />
                                    </Grid>
                                    <Grid item xs={8}>
                                        <DefaultInput
                                            label={'Email'}
                                            placeholder={'E.g. example@gmail.com'}
                                            value={values.email}
                                            name={"email"}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            error={Boolean(touched.email && errors.email)}
                                            helperText={touched.email && errors.email}
                                        />
                                    </Grid>

                                    <Grid item xs={4}>
                                        <DefaultInput
                                            label={'Address One'}
                                            placeholder={'E.g. xyz'}
                                            value={values.addressOne}
                                            name={"addressOne"}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            error={Boolean(touched.addressOne && errors.addressOne)}
                                            helperText={touched.addressOne && errors.addressOne}
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <DefaultInput
                                            label={'Address Two'}
                                            placeholder={'E.g. xyz'}
                                            value={values.addressTwo}
                                            name={"addressTwo"}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            error={Boolean(touched.addressTwo && errors.addressTwo)}
                                            helperText={touched.addressTwo && errors.addressTwo}
                                        />
                                    </Grid>


                                    <Grid item xs={4} />
                                    <Grid item xs={4}>
                                        <DefaultInput
                                            label={'City'}
                                            placeholder={'E.g. xyz'}
                                            value={values.city}
                                            name={"city"}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            error={Boolean(touched.city && errors.city)}
                                            helperText={touched.city && errors.city}
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <DefaultInput
                                            label={'State'}
                                            placeholder={'E.g. xyz'}
                                            value={values.state}
                                            name={"state"}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            error={Boolean(touched.state && errors.state)}
                                            helperText={touched.state && errors.state}
                                        />
                                    </Grid>
                                    <Grid item xs={4} />

                                    {/* <Grid item xs={4}>
                            <DefaultInput label={'Country'} onChange={(e) => setCountry(e.target.value)} value={country} />
                        </Grid> */}

                                    <Grid item xs={4}>
                                        <DefaultInput
                                            label={'Zip Code'}
                                            placeholder={'E.g. xyz'}
                                            value={values.zipCode}
                                            name={"zipCode"}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            error={Boolean(touched.zipCode && errors.zipCode)}
                                            helperText={touched.zipCode && errors.zipCode}
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <DefaultSelect
                                            onBlur={handleBlur}
                                            onChange={(event, newValue) => {
                                                values.selectedDivision = newValue
                                                console.log("st", newValue)
                                            }}
                                            helperText={touched.selectedDivision && errors.selectedDivision && <p>Select division</p>}
                                            error={Boolean(touched.selectedDivision && errors.selectedDivision)}
                                            value={values.selectedDivision}
                                            options={arrayDivision}
                                            getOptionLabel={(option) => option.name}
                                            isOptionEqualToValue={(option, value) => value != null ? option._id === value._id : ""}
                                            name="selectedDivision"
                                            label={"Division"}
                                            placeholder="Select division" />
                                    </Grid>
                                    {/* <Grid item xs={8}>
                                        <DefaultInput label={'Region Name'} onChange={(e) => setRegionName(e.target.value)} value={regionName} />
                                    </Grid> */}
                                    <Grid item xs={4} />
                                    <Grid item xs={4}>
                                        <DefaultInput
                                            label={'Over Time Fatigue'}
                                            placeholder={'E.g. xyz'}
                                            type={"number"}
                                            value={values.overTimeFatigue}
                                            name={"overTimeFatigue"}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            error={Boolean(touched.overTimeFatigue && errors.overTimeFatigue)}
                                            helperText={touched.overTimeFatigue && errors.overTimeFatigue}
                                        />
                                    </Grid>
                                </Grid>
                            </Box>
                            <Box height={'1px'} width={'80%'} border={'1px solid #DBDDE2'} />
                            <Box width={'100%'}>
                                <Grid spacing={3} container>
                                    {(localStorage.getItem("assetTrackingEnabled") === "1") &&
                                        <Grid item xs={4}>
                                            <DefaultSelect
                                                onBlur={handleBlur}
                                                onChange={(event, newValue) => {
                                                    values.selectedZone = newValue
                                                    console.log("st", newValue)
                                                }}
                                                helperText={touched.selectedZone && errors.selectedZone && <p>Select selectedZone</p>}
                                                error={Boolean(touched.selectedZone && errors.selectedZone)}
                                                value={values.selectedZone}
                                                options={arrayZones}
                                                getOptionLabel={(option) => option.zoneName}
                                                isOptionEqualToValue={(option, value) => value != null ? option._id === value._id : ""}
                                                name="selectedZone"
                                                label={"Zone"}
                                                placeholder="Select Zone" />
                                        </Grid>
                                    }
                                    <Grid item xs={8}>
                                        <DefaultSelect
                                            onBlur={handleBlur}
                                            onChange={(event, newValue) => {
                                                values.selectedTimeZone = newValue
                                                console.log("st", newValue)
                                            }}
                                            helperText={touched.selectedTimeZone && errors.selectedTimeZone && <p>Select time zone</p>}
                                            error={Boolean(touched.selectedTimeZone && errors.selectedTimeZone)}
                                            value={values.selectedTimeZone}
                                            options={arrayTimeZone}
                                            getOptionLabel={(option) => option.label}
                                            isOptionEqualToValue={(option, value) => value != null ? option._id === value._id : ""}
                                            name="selectedTimeZone"
                                            label={"Time Zone"}
                                            placeholder="Select Time Zone" />
                                    </Grid>
                                </Grid>
                            </Box>
                            <Box height={'1px'} width={'80%'} border={'1px solid #DBDDE2'} />
                            {/* <Box flexGrow={1}>
                    <Stack
                        spacing={1}
                        pl={2}
                        pr={6}
                        pt={1}
                        borderRadius={'5px'}
                        bgcolor={'blue.light'}
                        display={'inline-flex'}
                    >
                        <Typography>Active Adjusting Time</Typography>
                        <Stack direction={'row'} alignItems={'center'}>
                            <DefaultToggle checked={shouldCountAdjusting} onChange={(e) => setShouldCountAdjusting(e.target.checked)} />
                            <Typography>{shouldCountAdjusting ? "Activated" : "Deactivated"}</Typography>
                        </Stack>
                    </Stack>
                </Box> */}
                            <Stack direction={'row'} spacing={1} mt={'auto'} width={'100%'}>

                                <Box flexGrow={1}>
                                    {localStorage.getItem("role") !== "2" && siteID != "0" && (<DefaultButton
                                        onClick={() => {
                                            setDeleteConfirmationShouldOpen(true)
                                        }}
                                        color={'error'}
                                    >
                                        Delete
                                    </DefaultButton>)}
                                </Box>
                                <DefaultButton onClick={() => setIsOpenedSiteDetails(null)}>Cancel</DefaultButton>
                                <DefaultButton
                                    color={'success'}
                                    disabled={isSubmitting}
                                    type="submit"
                                >
                                    Save
                                </DefaultButton>
                            </Stack>
                        </Stack>
                    </form>
                )}
            </Formik>
            {
                deleteConfirmationShouldOpen && (<ConfirmAlert title={"Confirmation"} body={"Are you sure you want to delete?"}
                    updateConfirmAlert={(e, value) => {
                        console.log(e, value)
                        setDeleteConfirmationShouldOpen(false)
                        updateConfirmAlert(value)
                    }} />)
            }
            <Backdrop style={{ zIndex: 1 }} open={isLoading} >
                <CircularProgress color="inherit" />
            </Backdrop>
        </Popup>
    );
};

export default React.memo(ManageSite);
