import React from 'react';
import { Box, Typography, Stack, Grid, IconButton, Avatar, ButtonBase, } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import { useLocation, useNavigate } from 'react-router-dom';
import StatusListTable from './StatusListTable';

export const ReportingDetails = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const arrayData = location.state.arrayData

    return (
        <Box width='100%'>
            <Stack direction={'row'} spacing={1} justifyContent={'flex-end'} my={"10px"}>
                <ButtonBase
                    type={'button'}
                    bgcolor={'blue.light'}
                    onClick={() => {
                        localStorage.setItem('printData', JSON.stringify({
                            arrayData: arrayData
                        }));
                        const newWindow = window.open('/reporting/print', '_blank');
                    }}
                    sx={{
                        minWidth: 100,
                        textTransform: 'none',
                        borderRadius: '5px',
                        height: '40px',
                        border: '0px solid black',
                        backgroundColor: 'primary.main',
                        fontSize: "13px",
                        fontWeight: "normal",
                        color: 'common.white',
                        '&:hover': {
                            bgcolor: 'primary.main',
                        },
                    }}
                >
                    Print
                </ButtonBase>
                <IconButton aria-label='cancel' sx={{ marginLeft: 'auto' }} onClick={() => navigate(-1)}>
                    <ClearIcon />
                </IconButton>
            </Stack>

            {arrayData.map(data => {
                return (<Box width='100%' p={2} sx={{ border: '1px solid #D9D6D6', marginBottom: "30px" }} key={data._id}>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Typography fontSize={26} variant="h4" sx={{ fontWeight: 'bold' }}>Approvals Report Details</Typography>

                    </Box>

                    <Grid container spacing={2} alignItems='center' sx={{ borderBottom: '1px solid #D9D6D6' }}>
                        <Typography fontSize={20} color={'gray'} sx={{ paddingTop: '16px', marginLeft: '50px', fontWeight: 'bold' }}>
                            {`${data.firstName} ${data.middleName} ${data.lastName}`}
                        </Typography>

                        <Box sx={{ display: 'inline-flex', padding: '20px', marginTop: '26px', marginLeft: 'auto' }}>
                            <Grid sx={{ alignItems: 'flex-end' }}>
                                <Typography fontSize={18} sx={{ color: '#4CAF50', marginRight: '30px', fontWeight: 'bold' }}>
                                    {data.clockInTime}
                                </Typography>
                            </Grid>
                            <Grid>
                                <Typography fontSize={18} sx={{ color: '#810202', marginRight: '30px', fontWeight: 'bold' }}>
                                    {data.overTime}
                                </Typography>
                            </Grid>
                            <Grid>
                                <Typography fontSize={18} sx={{ color: '#000000', marginRight: '30px', fontWeight: 'bold' }}>
                                    {data.totalTime}
                                </Typography>
                            </Grid>
                        </Box>
                    </Grid>

                    <Box mt={2} sx={{ display: 'flex', alignItems: 'flex-start' }}>
                        {/* <Avatar src={data.path} sx={{ width: 200, height: 200, marginRight: 10, marginLeft: 4 }} /> */}
                        <Box sx={{ marginLeft: 2, marginRight: 4, marginBottom: '60px' }}>
                            <Typography variant='h6' sx={{ fontWeight: 'bold' }}>Job Breakout</Typography>
                            <Typography>  {data.siteName}</Typography>
                            <Typography sx={{ fontWeight: 'bold' }}>MN: {data.totalTime} hrs</Typography>
                        </Box>
                        <Box sx={{ textAlign: 'left' }}>
                            <Typography variant='h6' sx={{ fontWeight: 'bold' }}>Overtime Details</Typography>
                            {
                                data.employeeStatus.map((activity) => {
                                    return (<Typography key={activity._id}>{activity.weekDates}: {activity.overTime}</Typography>)
                                })
                            }

                        </Box>
                    </Box>
                    <StatusListTable arrayData={data.employeeStatus}></StatusListTable>
                </Box>)
            })
            }
        </Box>
    );
};
