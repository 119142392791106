import React, { useEffect, useState } from 'react';
import { Grid, Box, Stack, Typography, Backdrop, CircularProgress } from '@mui/material';
import { ConfigurationIcon } from 'assets/icons/ConfigurationIcon';
import ManageCompany from './ManageCompany';
import api from 'lib/axios';

export const SettingsCompanyDetailsTab = ({ isOpenedCompanyDetails, setIsOpenedCompanyDetails }) => {
    const [isLoading, setIsLoading] = useState(true);
    const [companyData, setCompanyData] = useState({
        addressOne: "",
        addressTwo: "",
        city: "",
        companyID: "",
        country: "",
        timeZone: "",
        email: "",
        isActive: "",
        managerName: "",
        name: "",
        regionName: "",
        state: "",
        telephone: "",
        zipCode: "",
        _id: "",
        zones: ""
    });
    useEffect(() => {
        console.log("test");
        getData()
        //setIsOpenedCompanyDetails(null)
    }, [isOpenedCompanyDetails]);

    useEffect(() => {
        getData()
    }, []);

    function getData() {
        setIsLoading(true)
        api.post('/apis/companies/byID/', { id: localStorage.getItem("publicID") }).then((res) => {
            if (res.data.success) {
                var resData = res.data.data
                setCompanyData(resData)
            }
            setIsLoading(false)
        }).catch(function (error) {
            console.log(error);
        });
    }

    return (
        <Stack width={'100%'} height={'100%'}>
            <Box bgcolor={'#F6F6F7'} p={2} border={'1px solid #DBDDE2'} borderTop={0} mt={-1}>
                <Box flexGrow={1}>
                    <Stack my={1} direction={'row'} alignItems={'center'} spacing={2}>
                        <Typography fontWeight={700}>Company Details</Typography>
                        <ConfigurationIcon />
                    </Stack>
                    <Grid container spacing={2}>
                        <Grid item xs={1.5}>
                            <Stack spacing={1}>
                                <Typography color={'#A8A8A7'}>Company Name</Typography>
                                <Typography>{companyData.companyName ? companyData.companyName : "-"}</Typography>
                            </Stack>
                        </Grid>
                        <Grid item xs={1.5}>
                            <Stack spacing={1}>
                                <Typography color={'#A8A8A7'}>Phone</Typography>
                                <Typography>{companyData.telephone ? companyData.telephone : "-"}</Typography>
                            </Stack>
                        </Grid>
                        <Grid item xs={1.5}>
                            <Stack spacing={1}>
                                <Typography color={'#A8A8A7'}>Timezone</Typography>
                                <Typography>{companyData.timeZone ? companyData.timeZone : "-"}</Typography>
                            </Stack>
                        </Grid>
                        <Grid item >
                            <Stack spacing={1}>
                                <Typography color={'#A8A8A7'}>Email</Typography>
                                <Stack width={'50%'}>
                                    <Typography>{companyData.email ? companyData.email : "-"}</Typography>
                                </Stack>
                            </Stack>
                        </Grid>
                    </Grid>
                </Box>
                <Box flexGrow={1}>
                    <Stack mt={2} mb={1} direction={'row'} alignItems={'center'} spacing={2}>
                        <Typography fontWeight={700}>Address Details</Typography>
                    </Stack>
                    <Grid container spacing={2}>
                        <Grid item xs={1.5}>
                            <Stack spacing={1}>
                                <Typography color={'#A8A8A7'}>Address One</Typography>
                                <Typography>{companyData.address1 ? companyData.address1 : "-"}</Typography>
                            </Stack>
                        </Grid>
                        <Grid item xs={1.5}>
                            <Stack spacing={1}>
                                <Typography color={'#A8A8A7'}>Address Two</Typography>
                                <Typography>{companyData.address2 ? companyData.address2 : "-"}</Typography>
                            </Stack>
                        </Grid>
                        <Grid item xs={1.5}>
                            <Stack spacing={1}>

                                <Typography color={'#A8A8A7'}>City</Typography>
                                <Typography>{companyData.city ? companyData.city : "-"}</Typography>
                            </Stack>
                        </Grid>
                        <Grid item xs={1.5}>
                            <Stack spacing={1}>
                                <Typography color={'#A8A8A7'}>State</Typography>
                                <Typography>{companyData.state ? companyData.state : "-"}</Typography>
                            </Stack>
                        </Grid>
                        <Grid item>
                            <Stack spacing={1}>
                                <Typography color={'#A8A8A7'}>Zip Code</Typography>
                                <Typography>{companyData.zipCode ? companyData.zipCode : "-"}</Typography>
                            </Stack>
                        </Grid>
                    </Grid>
                </Box>

            </Box>

            {isOpenedCompanyDetails != null && <ManageCompany companyID={isOpenedCompanyDetails} setIsOpenedCompanyDetails={setIsOpenedCompanyDetails} />}
            <Backdrop style={{ zIndex: 1 }} open={isLoading} >
                <CircularProgress color="inherit" />
            </Backdrop>
        </Stack>
    );
};
