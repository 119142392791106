import { Box, Typography, Stack } from '@mui/material';

export const Bar = ({ percent, bgcolor, title }) => {
    return (
        <Stack height="100%" direction="column" justifyContent="flex-end" alignItems="center">
            <Box
                sx={{
                    width: 10,
                    flexShrink: 0,
                    height: `${percent}%`,
                    bgcolor: bgcolor || 'secondary.main',
                }}
            />
            <Typography>{title}</Typography>
        </Stack>
    );
};
