import React from 'react';
import ReactDOM from 'react-dom/client';
import 'mapbox-gl/dist/mapbox-gl.css';
import './assets/styles/general.scss';
import { App } from './App.jsx';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from '@mui/material';
import { theme } from './core/theme/theme.js';
import { SnackbarProvider } from 'notistack';
import { AuthProvider } from './contexts/JWTContext.js';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <BrowserRouter>
        <AuthProvider>
            <ThemeProvider theme={theme}>
                <SnackbarProvider>
                    <App />
                </SnackbarProvider>
            </ThemeProvider>
        </AuthProvider>
    </BrowserRouter>
);
