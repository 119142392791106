import React, { useEffect, useRef, useState } from 'react';
import { ClusterIcon } from 'assets/icons/Cluster';
import { Metrics } from 'components/Metrics';
import { SearchInput } from 'components/SearchInput';
import { CircleButton } from 'ui/CircleButton';
import { DefaultDataGrid } from 'ui/DefaultDataGrid';
import { Filters } from 'ui/Filters';
import { Backdrop, Box, ButtonBase, CircularProgress, Grid, MenuItem, Stack, Typography } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { PipeProfilesIcon } from 'assets/icons/PipeProfilesIcon.jsx';
import { LinesIcon } from 'assets/icons/LinesIcon.jsx';
import { filterStatus } from 'modules/Dashboard/data/status';
import { getEmployeesList } from '../Employees/Repository/EmployeeRepository';
import { getSitePerformanceData } from 'modules/Dashboard/Repository/DashboardRepository';
import api from 'lib/axios';
import { EmployeeNew } from 'modules/Employees/EmployeeNew';
import { MetricsEmployeeActivity } from 'components/Metrics/MetricsEmployeeActivity';
import moment from 'moment';
import { columns } from './Data/EmployeeActivityData';
import { useLoader } from 'contexts/LoaderContext';
import { AccessTime, Close, LockClock } from '@mui/icons-material';
import { Tabs } from 'ui/Tabs';
import { tabs } from './Data/tabs';
import { Details } from './Detail';
import { TimeIcon } from 'assets/icons/TimeIcon';
import { History } from './History';
import { arrayEmployeeStatus, arrayStatus } from 'modules/Employees/data/ArrayData/status';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import { Bar } from './Bar';


export const EmployeeDetail = () => {
    let refChart = useRef(null)

    let refPieChart = useRef(null)
    const navigate = useNavigate();
    const location = useLocation();
    const selectedDataId = location.state.id
    const arrayShift = location.state.arrayShift
    const [isLoading, setIsLoading] = useState(false);
    const [arrayData, setArrayData] = useState([])
    const [objShift, setObjShift] = useState({})
    const [isEditOpened, setIsEditOpened] = useState(selectedDataId == "" ? true : false);
    const [activeTab, setActiveTab] = useState(tabs[0].value);
    const [objEmployee, setObjEmployee] = useState({});
    const [employeeName, setEmployeeName] = useState("");
    const [overTimeFatigue, setOverTimeFatigue] = useState(0);
    const [totalWorkingHours, setTotalWorkingHours] = useState(0);
    const startOfWeek = moment().startOf('isoWeek');
    const endOfWeek = moment().endOf('isoWeek');
    const [metrics1, setMetrics1] = useState([
        {
            title: 'Clocked-In (hrs)',
            value: '-',
        },
        // {
        //     title: 'Break Time',
        //     value: '0',
        // },
        {
            title: 'Over Time (hrs)',
            value: '-',
            isRed: true
        },
        {
            title: 'Total (hrs)',
            value: '-',
        },
    ]);
    const [metrics2, setMetrics2] = useState([
        {
            title: 'Status',
            value: '-',
            isGreen: true,
        },
        {
            title: 'Current Week',
            value: '-',
        },
        {
            title: 'Net Salary',
            value: '-',
        },
    ]);
    useEffect(() => {
        if (selectedDataId != "") {
            getData(arrayData)
        }
    }, []);

    useEffect(() => {
        graphData();
    }, [overTimeFatigue, totalWorkingHours]);

    function getChartData() {
        am5.addLicense("AM5C349425111");
        am5.array.each(am5.registry.rootElements, function (root) {
            if (root && root.dom && root.dom.id == "pieChart") {
                root.dispose();
            }
        });
        let root = am5.Root.new("pieChart");

        root.setThemes([
            am5themes_Animated.new(root)
        ]);

        let chart = root.container.children.push(
            am5xy.XYChart.new(root, {
                layout: root.verticalLayout,
            })
        );

        let xAxis = chart.xAxes.push(
            am5xy.CategoryAxis.new(root, {
                categoryField: "category",
                renderer: am5xy.AxisRendererX.new(root, {}),
            })
        );

        let yAxis = chart.yAxes.push(
            am5xy.ValueAxis.new(root, {
                renderer: am5xy.AxisRendererY.new(root, {}),
            })
        );

        let series = chart.series.push(
            am5xy.ColumnSeries.new(root, {
                name: "Series 1",
                xAxis: xAxis,
                yAxis: yAxis,
                valueYField: "value",
                categoryXField: "category",
            })
        );

        series.data.setAll([
            { category: "Total Hours", value: 100 },
            { category: "Worked Hours", value: 70 },
        ]);


        series.appear(1000, 100);

        refPieChart = root;
    }
    function getData() {
        console.log("employeeID", localStorage.getItem("siteID"));
        setIsLoading(true)
        api.post('/apis/EmployeesStatus/getActivityWithSummery/' + selectedDataId, {
            siteID: localStorage.getItem("siteID"),
            startDate: startOfWeek.format('MM-DD-YYYY'),
            endDate: endOfWeek.format('MM-DD-YYYY')
        }).then((res) => {
            if (res.data.success) {
                var resData = res.data.data
                setArrayData(resData.arrayData)
                setObjShift(resData.shift)
                setOverTimeFatigue(resData.overTimeFatigue)
                setTotalWorkingHours(resData.totalHour)
                setMetrics1([
                    {
                        title: 'Clocked-In (hrs)',
                        value: resData.clockInTime,
                    },
                    // {
                    //     title: 'Break Time',
                    //     value: resData.breakTime,
                    // },
                    {
                        title: 'Over Time (hrs)',
                        value: resData.overTime,
                        isRed: true
                    },
                    {
                        title: 'Total (hrs)',
                        value: resData.totalTime,
                    },
                ])
                getEmployeeDetailByID(resData.salary)
            }
            //setIsLoading(false)
        }).catch(function (error) {
            console.log(error);
        });
    }
    function graphData() {
        am5.addLicense("AM5C349425111");
        am5.array.each(am5.registry.rootElements, function (root) {
            if (root && root.dom && root.dom.id == "chartdiv") {
                root.dispose();
            }
        });
        let root = am5.Root.new("chartdiv");

        // Set themes
        root.setThemes([
            am5themes_Animated.new(root)
        ]);

        // Create chart
        let chart = root.container.children.push(am5xy.XYChart.new(root, {
            panX: false,
            panY: false,
            wheelX: "panX",
            wheelY: "zoomX",
            paddingLeft: 0,
            layout: root.verticalLayout
        }));

        // Add legend
        // let legend = chart.children.push(am5.Legend.new(root, {
        //     centerX: am5.p50,
        //     x: am5.p50
        // }))

        // Data
        let data = [{
            year: "2017",
            income: overTimeFatigue,
            expenses: totalWorkingHours

            // year: "2017",
            // income: 100,
            // expenses: 40
        }];

        // Create axes
        let yAxis = chart.yAxes.push(am5xy.CategoryAxis.new(root, {
            categoryField: "year",
            renderer: am5xy.AxisRendererY.new(root, {
                inversed: true,
                cellStartLocation: 0.1,
                cellEndLocation: 0.9,
                minorGridEnabled: true
            })
        }));
        yAxis.get("renderer").labels.template.set("forceHidden", true); // Hide year labels
        yAxis.data.setAll(data);

        let xAxis = chart.xAxes.push(am5xy.ValueAxis.new(root, {
            renderer: am5xy.AxisRendererX.new(root, {
                strokeOpacity: 0.1,
                minGridDistance: 50
            }),
            min: 0
        }));

        xAxis.get("renderer").labels.template.set("forceHidden", true);  // Hide year labels

        // Add series
        function createSeries(field, name, color) {
            let series = chart.series.push(am5xy.ColumnSeries.new(root, {
                name: name,
                xAxis: xAxis,
                yAxis: yAxis,
                valueXField: field,
                categoryYField: "year",
                sequencedInterpolation: true,
                tooltip: am5.Tooltip.new(root, {
                    pointerOrientation: "horizontal",
                    labelText: "[bold]{name}[/]\n{valueX}"
                })
            }));

            series.columns.template.setAll({
                height: am5.p100,
                // height: 20,
                width: am5.percent(80),
                strokeOpacity: 0,
                fill: am5.color(color),
            });


            series.bullets.push(function () {
                return am5.Bullet.new(root, {
                    locationX: 1,
                    locationY: 0.5,
                    sprite: am5.Label.new(root, {
                        centerY: am5.p50,
                        text: "{valueX}",  // remove data right side text
                        populateText: true,
                        //oversizedBehavior: "wrap", 
                        width: am5.percent(50),
                        textAlign: 'left',
                        fontSize: 12,
                    })
                });
            });

            series.bullets.push(function () {
                return am5.Bullet.new(root, {
                    locationX: 1,
                    locationY: 0.5,
                    sprite: am5.Label.new(root, {
                        centerX: am5.p100,
                        centerY: am5.p50,
                        text: "{name}",
                        fill: am5.color(0xffffff),
                        populateText: true,
                        //oversizedBehavior: "wrap", // Ensure text wraps if it overflows
                        width: am5.percent(0),
                        textAlign: "right",
                        fontSize: 12,
                    })
                });
            });

            series.data.setAll(data);
            series.appear();

            return series;
        }



        createSeries("income", `OTF (${overTimeFatigue})`, "#ED373B");
        createSeries("expenses", `WT (${totalWorkingHours})`, "#24D5AA");

        // legend.data.setAll(chart.series.values);

        // Add cursor
        let cursor = chart.set("cursor", am5xy.XYCursor.new(root, {
            behavior: "zoomY"
        }));
        cursor.lineY.set("forceHidden", true);
        cursor.lineX.set("forceHidden", true);

        // Make stuff animate on load
        chart.appear(1000, 100);
        refChart = root;
    }
    function sortCategoryAxis(series) {
        series.dataItems.sort(function (x, y) {
            return y.get("graphics").y() - x.get("graphics").y();
        });
        let easing = am5.ease.out(am5.ease.cubic);

        am5.array.each(yAxis.dataItems, function (dataItem) {
            let seriesDataItem = getSeriesItem(dataItem.get("category"));

            if (seriesDataItem) {
                let index = series.dataItems.indexOf(seriesDataItem);
                let column = seriesDataItem.get("graphics");
                let fy =
                    yRenderer.positionToCoordinate(yAxis.indexToPosition(index)) -
                    column.height() / 2;

                if (index != dataItem.get("index")) {
                    dataItem.set("index", index);

                    let x = column.x();
                    let y = column.y();

                    column.set("dy", -(fy - y));
                    column.set("dx", x);

                    column.animate({ key: "dy", to: 0, duration: 600, easing: easing });
                    column.animate({ key: "dx", to: 0, duration: 600, easing: easing });
                } else {
                    column.animate({ key: "y", to: fy, duration: 600, easing: easing });
                    column.animate({ key: "x", to: 0, duration: 600, easing: easing });
                }
            }
        });

        yAxis.dataItems.sort(function (x, y) {
            return x.get("index") - y.get("index");
        });
    }
    const handleChangeTab = (tab) => {
        if (!isEditOpened) {
            setActiveTab(tab);
            if (tab === tabs[0].value) {
                if (selectedDataId != "") {
                    getData()
                }
            }
        }
    };

    const onEditClick = () => {
        setIsEditOpened(true);
    };

    function getEmployeeDetailByID(netSalary) {
        // setIsLoading(true)
        api.get('/apis/Employees/getById/' + selectedDataId).then((res) => {
            if (res.data.success) {
                var resData = res.data.data
                setObjEmployee(resData)
                setEmployeeName(resData.firstName + " " + resData.lastName)
                var employeeStatus = resData.employeeStatus.endTime == null ? "1" : "0"
                setMetrics2([
                    {
                        title: 'Status',
                        value: resData.employeeStatus ? arrayEmployeeStatus.find((status) => status.id == employeeStatus)?.value : "-",
                        isGreen: resData.employeeStatus && employeeStatus == "1" ? true : false,
                        isRed: resData.employeeStatus && employeeStatus == "0" ? true : false,
                    },
                    {
                        title: 'Current Week',
                        value: `${"startDate", startOfWeek.format('MMMM D')} - ${endOfWeek.format('D')}`,
                    },
                    {
                        title: 'Net Salary',
                        value: "$" + netSalary,
                    },
                ])
            }
            setIsLoading(false)
        }).catch(function (error) {
            console.log(error);
        });
    }

    const isFirstTab = activeTab === tabs[0].value
    const isSecondTab = activeTab === tabs[1].value
    return (
        <>
            <Stack py={2} px={2.5} width={'100%'}>
                <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'} mb={3}>
                    <Stack direction={'row'} spacing={8}>
                        <Stack>
                            <Typography fontSize={20}>Employee</Typography>
                            <Typography fontSize={35} fontWeight={700}>
                                {employeeName ? employeeName : "-"}
                            </Typography>
                        </Stack>
                    </Stack>
                    <CircleButton icon={<Close />} onClick={() => navigate(-1)} />
                </Stack>
                <Stack direction={'row'}>
                    <Stack mb={3} direction={'row'} spacing={2}>
                        <Metrics icon={<TimeIcon />} data={metrics1} />
                        <Metrics data={metrics2} />
                    </Stack>
                    <Stack mt={-1.5} ml={2}>
                        <div ref={refChart} id="chartdiv" style={{ width: "200px", height: "100px"}}></div>
                    </Stack>
                </Stack>
                <Tabs data={tabs} activeTab={activeTab} setActiveTab={handleChangeTab} />
                <Stack
                    flex={1}
                    border={'1px solid'}
                    borderColor={'grey.light'}
                    borderRadius={'5px'}
                    overflow={'hidden'}
                    bgcolor={'common.white'}
                    position={'relative'}

                >
                    {isFirstTab && <Details employeeID={selectedDataId} disabled={isEditOpened} onEditClick={onEditClick} arrayData={arrayData} objEmployee={objEmployee} getData={getData} />}
                    {isSecondTab && <History employeeID={selectedDataId} objEmployee={objEmployee} getData={getData} />}
                    {isEditOpened &&
                        <EmployeeNew
                            selectedDataID={selectedDataId}
                            closeDetails={(e) => {
                                if (selectedDataId == "") {
                                    navigate(-1)
                                } else {
                                    setIsEditOpened(false)
                                    getData()
                                }
                            }}
                        />
                    }

                </Stack>
                <Backdrop style={{ zIndex: 1 }} open={isLoading} >
                    <CircularProgress color="inherit" />
                </Backdrop>
            </Stack >
        </>
    )
}