export const tabs = [
    {
        id: 1,
        label: 'Company Detail',
        value: 'companydetail',
    },
    {
        id: 2,
        label: 'Users',
        value: 'users',
    },
    {
        id: 3,
        label: 'Site Details',
        value: 'sitedetails',
    },
    {
        id: 4,
        label: 'Shifts',
        value: 'shifts',
    },
    // {
    //     id: 4,
    //     label: 'Work Centers',
    //     value: 'workcenters',
    // },
    {
        id: 5,
        label: 'Tablet Devices',
        value: 'tabletdevices',
    },
    {
        id: 6,
        label: 'Division',
        value: 'division',
    },
];
