import React, { useEffect, useState } from 'react';
import { Box, Grid, Stack, Typography, Backdrop, CircularProgress } from '@mui/material';
import { Popup } from 'components/Popup/index.js';
import { DefaultInput } from 'ui/DefaultInput/index.js';
import { arrayTimeZone } from 'modules/Settings/tabs/Site/SitesRepository.jsx';
import { enqueueSnackbar } from 'notistack';
import { DefaultButton } from 'ui/DefaultButton';
import { DefaultSelect } from 'ui/DefaultSelect';
import { Formik } from 'formik';
import * as Yup from 'yup';
import api from 'lib/axios';


const ManageCompany = ({ companyID, setIsOpenedCompanyDetails }) => {
    const [arrayZones, setArrayZones] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [telephone, setTelephone] = useState("");
    const [addressOne, setAddressOne] = useState("");
    const [addressTwo, setAddressTwo] = useState("");
    const [city, setCity] = useState("");
    const [state, setState] = useState("");
    const [zipCode, setZipCode] = useState("");
    const [selectedTimeZone, setSelectedTimeZone] = useState(
        { label: "", value: "" });
    const [selectedZone, setSelectedZone] = useState(null);

    useEffect(() => {
        getCompanyDetailByID();
    }, []);

    function getCompanyDetailByID() {
        setIsLoading(true)
        api.post('/apis/companies/byID/', { id: localStorage.getItem("publicID") }).then((res) => {
            if (res.data.success) {
                var resData = res.data.data
                setAddressOne(resData.address1 || '');
                setAddressTwo(resData.address2 || '');
                setCity(resData.city || '');
                setState(resData.state || '');
                setName(resData.companyName || '');
                setEmail(resData.email || '');
                setTelephone(resData.telephone || '');
                setZipCode(resData.zipCode || '');
                //setSelectedZone(resData.selectedZones.find(z => z?._id == finishZoneID) || null);
                setSelectedTimeZone(arrayTimeZone.find(timeZone => timeZone.value == resData.timeZone) || null);
            }
            setIsLoading(false)
        }).catch(function (error) {
            console.log(error);
        });
    }

    async function apiCalling(values) {
        var data = {
            companyID: localStorage.getItem("publicID"),
            companyName: values.name,
            address1: values.addressOne,
            address2: values.addressTwo,
            city: values.city,
            state: values.state,
            zipCode: values.zipCode,
            email: values.email,
            telephone: values.telephone,
            managerName: values.managerName,
            timeZone: values.selectedTimeZone.value
        }
        return await api.put('/apis/companies/' + localStorage.getItem("publicID"), data)
    }
    return (
        <Popup
            isOpened
            width={700}
            bgcolor={'common.white'}
            sx={{
                bottom: 20,
                right: 20,
                border: '1px solid #DBDDE2',
                borderColor: '#DBDDE2',
                borderRadius: '10px',
            }}
        >
            <Formik
                enableReinitialize
                initialValues={{
                    submit: null,
                    name: name,
                    telephone: telephone,
                    email: email,
                    addressOne: addressOne,
                    addressTwo: addressTwo,
                    city: city,
                    state: state,
                    zipCode: zipCode,
                    selectedZone: selectedZone,
                    selectedTimeZone: selectedTimeZone,
                }}
                validationSchema={Yup
                    .object()
                    .shape({
                        name: Yup.string().required('Company name is required'),
                        telephone: Yup.string().required('Cellular is required'),
                        email: Yup.string().email('Must be a valid email').required('Email is required'),
                        addressOne: Yup.string().required('Address one is required'),
                        addressTwo: Yup.string().required('address two is required'),
                        city: Yup.string().required('City is required'),
                        state: Yup.string().required('State is required'),
                        zipCode: Yup.string().required('Zip code is required'),
                        // selectedZone: Yup.object().required('Zone is required'),
                        selectedTimeZone: Yup.object().required('Time Zone is required'),
                    })}
                onSubmit={async (values, { resetForm, setErrors, setStatus, setSubmitting }) => {
                    console.log("values::", values)
                    try {
                        setIsLoading(true)
                        setName(values.name)
                        setTelephone(values.telephone)
                        setEmail(values.email)
                        setAddressOne(values.addressOne)
                        setAddressTwo(values.addressTwo)
                        setCity(values.city)
                        setState(values.state)
                        setZipCode(values.zipCode)
                        setSelectedZone(values.selectedZone)
                        setSelectedTimeZone(values.selectedTimeZone)
                        var data = await apiCalling(values)
                        setIsLoading(false)
                        if (data !== undefined && data.data.success) {
                            setStatus({ success: true });
                            setSubmitting(false);
                            enqueueSnackbar("Company updated", {
                                anchorOrigin: {
                                    horizontal: 'right',
                                    vertical: 'top'
                                },
                                variant: 'success'
                            });
                            setIsOpenedCompanyDetails(null)
                        } else {
                            setStatus({ success: false });
                            setErrors({ submit: data.data.message });
                            setSubmitting(false);
                            enqueueSnackbar(data.data.message, {
                                anchorOrigin: {
                                    horizontal: 'right',
                                    vertical: 'top'
                                },
                                variant: 'error'
                            });
                        }
                    } catch (err) {
                        console.error(err);
                        setStatus({ success: false });
                        setErrors({ submit: err.message });
                        setSubmitting(false);
                    }
                }}
            >
                {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values, setFieldValue }) => (
                    <form
                        onSubmit={handleSubmit}
                        style={{ height: "100%" }}
                    >
                        {console.log("Company ID", localStorage.getItem("publicID"))}
                        <Stack px={3} pt={3} pb={3} gap={'32px'} height={'100%'} alignItems={'flex-start'}>
                            <Typography fontSize={20}>Company Details</Typography>
                            <Box width={'100%'}>
                                <Grid spacing={3} container>
                                    <Grid item xs={4}>
                                        <DefaultInput
                                            label={'Company Name'}
                                            placeholder={'E.g. xyz'}
                                            value={values.name}
                                            name={"name"}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            error={Boolean(touched.name && errors.name)}
                                            helperText={touched.name && errors.name}
                                        />
                                    </Grid>
                                    <Grid item xs={8}>
                                        <DefaultInput
                                            label={'Email'}
                                            placeholder={'E.g. example@gmail.com'}
                                            value={values.email}
                                            name={"email"}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            error={Boolean(touched.email && errors.email)}
                                            helperText={touched.email && errors.email}
                                        />
                                    </Grid>

                                    <Grid item xs={4}>
                                        <DefaultInput
                                            label={'Mobile'}
                                            placeholder={'E.g. 1234567890'}
                                            value={values.telephone}
                                            name={"telephone"}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            error={Boolean(touched.telephone && errors.telephone)}
                                            helperText={touched.telephone && errors.telephone}
                                        />
                                    </Grid>

                                    <Grid item xs={4}>
                                        <DefaultInput
                                            label={'Address One'}
                                            placeholder={'E.g. xyz'}
                                            value={values.addressOne}
                                            name={"addressOne"}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            error={Boolean(touched.addressOne && errors.addressOne)}
                                            helperText={touched.addressOne && errors.addressOne}
                                        />
                                    </Grid>
                                    <Grid item xs={4} />
                                    <Grid item xs={4}>
                                        <DefaultInput
                                            label={'Address Two'}
                                            placeholder={'E.g. xyz'}
                                            value={values.addressTwo}
                                            name={"addressTwo"}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            error={Boolean(touched.addressTwo && errors.addressTwo)}
                                            helperText={touched.addressTwo && errors.addressTwo}
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <DefaultInput
                                            label={'City'}
                                            placeholder={'E.g. xyz'}
                                            value={values.city}
                                            name={"city"}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            error={Boolean(touched.city && errors.city)}
                                            helperText={touched.city && errors.city}
                                        />
                                    </Grid>
                                    <Grid item xs={4} />
                                    <Grid item xs={4}>
                                        <DefaultInput
                                            label={'State'}
                                            placeholder={'E.g. xyz'}
                                            value={values.state}
                                            name={"state"}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            error={Boolean(touched.state && errors.state)}
                                            helperText={touched.state && errors.state}
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <DefaultInput
                                            label={'Zip Code'}
                                            placeholder={'E.g. xyz'}
                                            value={values.zipCode}
                                            name={"zipCode"}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            error={Boolean(touched.zipCode && errors.zipCode)}
                                            helperText={touched.zipCode && errors.zipCode}
                                        />
                                    </Grid>
                                </Grid>
                            </Box>
                            <Box height={'1px'} width={'80%'} border={'1px solid #DBDDE2'} />
                            <Box width={'100%'}>
                                <Grid spacing={3} container>
                                    {(localStorage.getItem("assetTrackingEnabled") === "1") &&
                                        <Grid item xs={4}>
                                            <DefaultSelect
                                                onBlur={handleBlur}
                                                onChange={(event, newValue) => {
                                                    values.selectedZone = newValue
                                                    console.log("st", newValue)
                                                }}
                                                helperText={touched.selectedZone && errors.selectedZone && <p>Select zone</p>}
                                                error={Boolean(touched.selectedZone && errors.selectedZone)}
                                                value={values.selectedZone}
                                                options={arrayZones}
                                                getOptionLabel={(option) => option.selectedZone}
                                                isOptionEqualToValue={(option, value) => value != null ? option._id === value._id : ""}
                                                name="selectedZone"
                                                label={"Zone"}
                                                placeholder="Select Zone" />
                                        </Grid>
                                    }
                                    <Grid item xs={8}>
                                        <DefaultSelect
                                            onBlur={handleBlur}
                                            onChange={(event, newValue) => {
                                                values.selectedTimeZone = newValue
                                                console.log("st", newValue)
                                            }}
                                            helperText={touched.selectedTimeZone && errors.selectedTimeZone && <p>Select time zone</p>}
                                            error={Boolean(touched.selectedTimeZone && errors.selectedTimeZone)}
                                            value={values.selectedTimeZone}
                                            options={arrayTimeZone}
                                            getOptionLabel={(option) => option.label}
                                            isOptionEqualToValue={(option, value) => value != null ? option._id === value._id : ""}
                                            name="selectedTimeZone"
                                            label={"Time Zone"}
                                            placeholder="Select Time Zone" />
                                    </Grid>
                                </Grid>
                            </Box>
                            <Box height={'1px'} width={'80%'} border={'1px solid #DBDDE2'} />
                            <Stack direction={'row'} spacing={1} mt={'auto'} width={'100%'}>
                                <Box flexGrow={1} />
                                <DefaultButton onClick={() => setIsOpenedCompanyDetails(null)}>Cancel</DefaultButton>
                                <DefaultButton
                                    color={'success'}
                                    disabled={isSubmitting}
                                    type="submit"
                                >
                                    Save
                                </DefaultButton>
                            </Stack>
                        </Stack>
                    </form>
                )}
            </Formik>
            <Backdrop style={{ zIndex: 1 }} open={isLoading} >
                <CircularProgress color="inherit" />
            </Backdrop>
        </Popup >
    );
};

export default React.memo(ManageCompany);
