import { Avatar, Box, Stack, Typography } from '@mui/material';
import { ArrowForwardIos } from '@mui/icons-material';
import { arrayEmployeeStatus, arrayStatus } from './ArrayData/status';
import { arrayOvertimeRate } from './ArrayData/overtimeRate';

export const columns = (handleOpenPopup) => [
    {
        field: 'employeeName',
        headerName: 'Employee Name',
        flex: 1,
        valueGetter: (params) => params.row.firstName + " " + params.row.middleName + " " + params.row.lastName,
    },
    {
        field: 'activityStatus',
        headerName: 'Current Status',
        width: 180,
        renderCell: (params) => {
            const status = arrayEmployeeStatus.find(status => status.id == params.value)?.value
            console.log("status", status);
            return (
                <Stack width={'100%'} direction={'row'} justifyContent={'space-between'} alignItems={'center'} spacing={1} pr={8}>
                    <Typography fontSize={12}>{status ? status : "-"}</Typography>
                    {status != null && <Box
                        sx={{
                            width: 14,
                            height: 13,
                            borderRadius: '15%',
                            backgroundColor: getEmployeeStatusColor(params.value),
                        }}
                    />}
                </Stack>
            )
        },
    },
    {
        field: 'salary',
        headerName: 'Salary',
        width: 150,
        valueGetter: (params) => params.row.salary ? ("$" + params.row.salary) : "-",
    },
    // {
    //     field: 'employPin',
    //     headerName: 'Employee Pin',
    //     width: 150,
    //     renderCell: (params) => (
    //         <Typography>
    //             {params.row.employPin ? params.row.employPin : "-"}
    //         </Typography>
    //     ),
    // },
    {
        field: 'shiftName',
        headerName: 'Shift',
        width: 120,
        valueGetter: (params) => params.row.shiftName ? params.row.shiftName : "-",
    },
    {
        field: 'clockInTime',
        headerName: 'Regular Hours',
        width: 160,
        valueGetter: (params) => params.row.clockInTime ? params.row.clockInTime : "-",
    },
    {
        field: 'overTime',
        headerName: 'OT Hours',
        width: 160,
        valueGetter: (params) => params.row.overTime ? params.row.overTime : "-",
    },
    {
        field: 'path',
        headerName: 'View Image',
        flex: 1,
        renderCell: ({ value }) => (
            <Box ml={2}
                onClick={(e) => {
                    e.stopPropagation();
                    handleOpenPopup(value)
                }}>
                <Avatar
                    src={value ? value : ""}
                    sx={{
                        color: "primary.main",
                        backgroundColor: '#DCDCDC',
                    }}
                >
                </Avatar>
            </Box>
        ),
    },
    {
        field: 'arrowIcon',
        headerName: '',
        width: 100,
        renderCell: () => (
            <Stack alignItems={'flex-end'} width={'100%'} pr={5}>
                <ArrowForwardIos
                    sx={{
                        width: 18,
                        height: 18,
                    }}
                />
            </Stack>
        ),
        sortable: false,
        disableColumnMenu: true,
    },
];

export const rows = [
    {
        id: 1,
        employeeName: "Bob Parker",
        status: "1",
        shift: 'Shift 1',
        regularHours: '08:30 HRS',
        otHours: '08:30 HRS',
    },
    {
        id: 2,
        employeeName: "Luis Ruiz",
        status: "1",
        shift: 'Shift 1',
        regularHours: '08:30 HRS',
        otHours: '08:30 HRS',
    },
    {
        id: 3,
        employeeName: "Andres Pinasco",
        status: "2",
        shift: 'Shift 1',
        regularHours: '08:30 HRS',
        otHours: '08:30 HRS',
    },
    {
        id: 4,
        employeeName: "Ryu Ling",
        status: "2",
        shift: 'Shift 1',
        regularHours: '08:30 HRS',
        otHours: '08:30 HRS',
    },
    {
        id: 5,
        employeeName: "Daniel Mustafa",
        status: "1",
        shift: 'Shift 1',
        regularHours: '08:30 HRS',
        otHours: '08:30 HRS',
    },
    // {
    //     id: 2,
    //     shift: "1ST",
    //     codedesc: 'Lunch break',
    //     // status: {
    //     //     text: 'Running',
    //     //     code: 1,
    //     // },
    //     code: 'PR1',
    //     notes: 'Took shorter lunch this week because.',
    //     duration: '08:56 (hrs)',
    //     time: '08:56 (hrs)',
    //     // utilization: 80, 50,
    // },
    // {
    //     id: 3,
    //     shift: "1ST",
    //     codedesc: 'Lunch break',
    //     // status: {
    //     //     text: 'Running',
    //     //     code: 1,
    //     // },
    //     code: 'PR1',
    //     notes: 'Took shorter lunch this week because.',
    //     duration: '08:56 (hrs)',
    //     time: '08:56 (hrs)',
    //     // utilization: 80, 25,
    // },
    // {
    //     id: 4,
    //     shift: "1ST",
    //     codedesc: 'Lunch break',
    //     // status: {
    //     //     text: 'Offline',
    //     //     code: 2,
    //     // },
    //     code: 'PR1',
    //     notes: 'Took shorter lunch this week because.',
    //     duration: '08:56 (hrs)',
    //     time: '08:56 (hrs)',
    //     // utilization: 80, 60,
    // },
    // {
    //     id: 5,
    //     shift: "1ST",
    //     codedesc: 'Lunch break',
    //     // status: {
    //     //     text: 'Down',
    //     //     code: 3,
    //     // },
    //     code: 'PR1',
    //     notes: 'Took shorter lunch this week because.',
    //     duration: '08:56 (hrs)',
    //     time: '08:56 (hrs)',
    //     // utilization: 80, 80,
    // },
];

// export const arrayStatus = [
//     { id: "1", value: "CLOCKED IN" },
//     { id: "2", value: "CLOCKED OUT" },
// ]

export const getEmployeeStatusColor = (code) => {
    switch (code) {
        case '1':
            return '#24D5AA';
        case '0':
            return '#ED373B';
        default:
            return 'red.main';
    }
};
