
import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Slide } from '@mui/material';

export class ConfirmAlert extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            title: this.props.title,
            body: this.props.body,
            isSuccess: this.props.isSuccess,
            delay: 2000,
            isShowing: this.props.isDeleting,
            yesButtonTitle: this.props.yesButtonTitle || "YES",
            noButtonTitle: this.props.noButtonTitle || "NO"

        }
        // To use the 'this' keyword, we need to bind it to our function
        this.onChange = this.onChange.bind(this);
    }

    handleClose = () => {
        // setState({ ...state, open: false });
        this.props.updateConfirmAlert(false)
        // this.setState({isShowing:false})
    };

    // A custom function to change the name in our state to match the user input
    onChange() {
        this.setState({ isShowing: false })
    }
    //     <Toast  show={this.state.isShowing} onClose={()=>this.props.updateShowToast(false)} delay={this.state.delay} autohide>
    //     <Toast.Body className={this.state.isSuccess?  "alertSuccessBody" :"alertErrorBody"} >{this.state.title}</Toast.Body>
    // </Toast>

    // The render function, where we actually tell the browser what it should show
    SlideTransition = () => {
        return <Slide direction="up" />
    }
    render() {
        return (
            <>
                {/* <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',

                }}
                open
                onClose={() => this.props.updateConfirmAlert(false)}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
                sx={{ minWidth: '60%' }}
            >
                <Fade in>
                    <div style={{}}>
                        <Box
                            sx={{
                                backgroundColor: 'background.default',
                                minHeight: '100%',
                                p: 0
                            }}
                        >
                            <Container maxWidth={'xxl'}>
                                <Paper elevation={12}>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            pb: 2,
                                            pt: 3,
                                            px: 3
                                        }}
                                    >
                                        <Avatar
                                            sx={{
                                                backgroundColor: (theme) => alpha(theme.palette.error.main, 0.08),
                                                color: 'error.main',
                                                mr: 2
                                            }}
                                        >
                                            <WarningIcon />
                                        </Avatar>
                                        <Box>
                                            <Typography
                                                color="textPrimary"
                                                variant="h5"
                                            >
                                                {this.state.title}

                                            </Typography>
                                            <Typography
                                                color="textSecondary"
                                                sx={{ mt: 1 }}
                                                variant="body2"
                                            >

                                                {this.state.body}
                                            </Typography>
                                        </Box>
                                    </Box>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'flex-end',
                                            px: 3,
                                            py: 1.5
                                        }}
                                    >
                                        <Button
                                            color="primary"
                                            sx={{ mr: 2 }}
                                            variant="outlined"
                                            onClick={(e) => this.props.updateConfirmAlert(e, false)}
                                        >
                                            {this.state.noButtonTitle}
                                        </Button>
                                        <Button
                                            sx={{
                                                backgroundColor: 'error.main',
                                                '&:hover': {
                                                    backgroundColor: 'error.dark'
                                                }
                                            }}
                                            variant="contained"
                                            onClick={(e) => this.props.updateConfirmAlert(e, true)}
                                        >
                                            {this.state.yesButtonTitle}
                                        </Button>
                                    </Box>
                                </Paper>
                            </Container>
                        </Box>

                    </div>
                </Fade>
            </Modal> */}
                <Dialog
                    open
                    fullWidth
                    onClose={() => this.props.updateConfirmAlert(false)}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        {this.state.title}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {this.state.body}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={(e) => this.props.updateConfirmAlert(e, false)}
                            variant="outlined"
                            color="primary">
                            {this.state.noButtonTitle}
                        </Button>
                        <Button
                            onClick={(e) => this.props.updateConfirmAlert(e, true)}
                            sx={{
                                backgroundColor: 'error.main',
                                '&:hover': {
                                    backgroundColor: 'error.dark'
                                }
                            }}
                            variant="contained"
                            color="primary" autoFocus>
                            {this.state.yesButtonTitle}
                        </Button>
                    </DialogActions>
                </Dialog>
            </>
        )
    }
}



// ReactDOM.render(<Greeting time="morning" />, document.getElementById('app'));