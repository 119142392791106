import { Stack, Checkbox, Typography } from '@mui/material';

export const columns = (arraySelected,arrayEmployee, onCheck,handleHeaderClick) => [
    {
        field: 'selected',
        headerName: 'Select All',
        width: 100,
        renderHeader: (params) => (
            <Typography onClick={handleHeaderClick}>
                {arraySelected.length == arrayEmployee.length ? "Unselect All":"Select All"}
            </Typography>
        ),
        renderCell: (params) => (
            <Stack alignItems={'flex-start'} width={'100%'} pr={5}>
                <Checkbox color="primary"
                    onChange={(value) => {
                        onCheck(params.row)
                    }}
                    checked={arraySelected.filter(obj => obj._id == params.row._id).length > 0}
                />
            </Stack>
        ),
        sortable: false,
        disableColumnMenu: true,
    },
    {
        field: 'employeeName',
        headerName: 'Employee Name',
        width: 400,
        valueGetter: (params) => params.row.firstName + " " + params.row.middleName + " " + params.row.lastName,
    },
    {
        field: 'shiftName',
        headerName: 'Shift',
        flex: 1,
        valueGetter: (params) => params.row.shiftName ? params.row.shiftName : "-",
    },
];
