import React, { useEffect, useState, useMemo } from 'react';
import { Box, ButtonBase, Grid, Stack, Typography, MenuItem } from '@mui/material';
import { Popup } from 'components/Popup/index.js';
import { getMainUserDetail, getUserDataApi, manageUserApi, updateMainUserApi } from 'modules/Settings/tabs/Users/UsersRepository'
import { useLoader } from 'contexts/LoaderContext';
import { enqueueSnackbar } from 'notistack';
import { DefaultButton } from 'ui/DefaultButton';
import { DefaultSelect } from 'ui/DefaultSelect';
import { DefaultInput } from 'ui/DefaultInput';

const ProfileUpdate = ({ setIsOpenedUserDetails }) => {
    const { startLoading, stopLoading, openAlert } = useLoader();
    const [selectedRole, setSelectedRole] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [mobileNumber, setMobileNumber] = useState("");
    const [userID, setUserID] = useState(localStorage.getItem(localStorage.getItem("role") === "SuperAdmin" ? "publicID" : "userID"));
    const [isAdmin, setIsAdmin] = useState(localStorage.getItem("role") === "SuperAdmin");



    useEffect(() => {
        getUserData();
    }, []);

    const getUserData = async () => {
        const userData = await getMainUserDetail(userID);

        const { firstName, lastName, email, mobile, role } = userData;
        setFirstName(firstName || '');
        setLastName(lastName || '');
        setEmail(email || '');
        setMobileNumber(mobile || '');
        setSelectedRole(role || '');

        stopLoading();
    };
    const validateEmail = (email) => {
        const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
        return emailRegex.test(email);
    };

    const validateFormData = () => {
        if (firstName === '') {
            return "Oops! Looks like you forgot to enter your first name. Please provide it.";
        } else if (!isAdmin && lastName === '') {
            return "Your last name is missing. Kindly fill it in.";
        } else if (mobileNumber === '') {
            return "We need your mobile number. Please enter it.";
        } else if (email === '') {
            return "An email address is necessary. Please provide one.";
        } else if (!validateEmail(email)) {
            return "Please enter a valid email address.";
        } else {
            return null;
        }
    };

    const submitForm = async () => {
        const validationData = validateFormData();
        if (validationData) {
            enqueueSnackbar(validationData, {
                anchorOrigin: {
                    horizontal: 'right',
                    vertical: 'top'
                },
                variant: 'error'
            });
            return;
        }
        startLoading();


        const formData = new FormData();
        // formData.append("multi-files", this.state.profileFile);
        if (isAdmin) {
            formData.append("contact", firstName);
            formData.append("telephone", mobileNumber);
        } else {
            formData.append("firstName", firstName);
            formData.append("lastName", lastName);
            formData.append("mobile", mobileNumber);
        }

        var user = await updateMainUserApi(formData);

        localStorage.setItem("profilePath", user?.profileInfo?.path || "")

        if (isAdmin) {
            localStorage.setItem("fullName", user.contact)
            localStorage.setItem("publicID", user._id)
        } else {
            localStorage.setItem("fullName", user.firstName + " " + user.lastName)
        }
        setIsOpenedUserDetails(false);
        stopLoading();
    };

    return (
        <Popup
            isOpened
            width={700}
            bgcolor={'common.white'}
            sx={{
                bottom: 20,
                right: 20,
                border: '1px solid #DBDDE2',
                borderColor: '#DBDDE2',
                borderRadius: '10px',
            }}
        >
            <Stack px={3} pt={3} pb={3} gap={'32px'} height={'100%'} alignItems={'flex-start'}>
                <Typography fontSize={20}>User Details</Typography>
                <Box width={'100%'}>
                    <Grid spacing={3} container>
                        <Grid item xs={4}>
                            <DefaultInput label={'First Name'} onChange={(e) => setFirstName(e.target.value)} value={firstName} />
                        </Grid>
                        {!isAdmin && <>
                            <Grid item xs={4}>
                                <DefaultInput label={'Last Name'} onChange={(e) => setLastName(e.target.value)} value={lastName} />
                            </Grid>
                            <Grid item xs={4} />
                        </>}
                        {/* <Grid item xs={4}>
                            <DefaultInput label={'Role'} onChange={(e) => setSelectedRole(e.target.value)} value={selectedRole} />
                        </Grid> */}
                        <Grid item xs={4} />
                        {!isAdmin && <Grid item xs={4} />}
                        <Grid item xs={4} />
                        <Grid item xs={4}>
                            <DefaultInput label={'Mobile'} onChange={(e) => setMobileNumber(e.target.value)} value={mobileNumber} />
                        </Grid>
                        <Grid item xs={8}>
                            <DefaultInput label={'Email'} onChange={(e) => setEmail(e.target.value)} value={email} />
                        </Grid>
                    </Grid>
                </Box>
                <Box height={'1px'} width={'80%'} border={'1px solid #DBDDE2'} />
                <Stack spacing={1}>
                    <Typography fontWeight={700}>Your Role </Typography>
                    <ButtonBase
                        sx={{
                            bgcolor: 'blue.light',
                            px: 3,
                            py: 1,
                            borderRadius: '5px',
                            fontWeight: 700,
                        }}
                    >
                        {selectedRole}
                    </ButtonBase>
                </Stack>
                <Stack direction={'row'} spacing={1} mt={'auto'} width={'100%'}>
                    <Box flexGrow={1} />

                    <DefaultButton onClick={() => setIsOpenedUserDetails(null)}>Cancel</DefaultButton>
                    <DefaultButton color={'success'} onClick={submitForm}>
                        Save
                    </DefaultButton>
                </Stack>
            </Stack>
        </Popup >
    );
};

export default React.memo(ProfileUpdate);
