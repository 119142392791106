export const arrayStatus = [
    { id: "0", value: "Down" },
    { id: "1", value: "Running" },
    { id: "2", value: "Adjusting" },
]

export const filterStatus = [
    { id: "0", value: "Down" },
    { id: "1", value: "Running" },
    { id: "2", value: "Offline" },
]

export const getSiteStatusColor = (code) => {
    switch (code) {
        case '0':
            return '#ED373B';
        case '1':
            return '#24D5AA';
        case '2':
            return '#F79951';
        default:
            return 'red.main';
    }
};