// import { NotificationsIcon } from '';
import { NotificationsIcon } from 'assets/icons/NotificationsIcon';
import { DefaultAvatar } from 'ui/DefaultAvatar';
import { Dropdown } from 'ui/Dropdown';
import { Box, Stack, Typography, Menu, MenuItem } from '@mui/material';
import React, { useState, useEffect } from 'react';
import logo from 'assets/flowserve-logo.png';
import { KeyboardArrowDown } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import useAuth from '../../hooks/useAuth';
import styleDropdown from '../../ui/Dropdown/index.module.scss';
import ProfileUpdate from './ProfileUpdate';

const momentDurationFormatSetup = require("moment-duration-format");
const momentRange = require('moment-range');
const momentTz = require("moment-timezone");
const Moment = require("moment");
momentDurationFormatSetup(Moment);
const moment = momentRange.extendMoment(Moment);

export const Header = ({setIsOpenedUserDetails}) => {
    const { logout } = useAuth();
    const navigate = useNavigate();
    const [anchorEl, setAnchorEl] = useState(null);
    // const [isOpenedUserDetails, setIsOpenedUserDetails] = useState(false);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);

    }
    const handleLogout = async () => {
        try {
            handleClose();
            await logout();
            navigate('authentication/login');
        } catch (err) {
            console.error(err);
        }
    }
    return (
        <Stack
            position={'relative'}
            direction={'row'}
            alignItems={'center'}
            component={'header'}
            justifyContent={'space-between'}
            height={75}
            pl={2.5}
            pr={6}
            flexShrink={0}
            sx={{
                borderBottom: '1px solid',
                borderColor: 'grey.light',
            }}
        >
            <Stack spacing={0.5}>
                <Typography fontSize={13}>{(localStorage.getItem("timeZone") ? moment().tz(localStorage.getItem("timeZone")) : moment()).format("dddd, MMMM DD, YYYY")}</Typography>
                <Typography fontSize={13} fontWeight={700} component={'div'}>
                    {localStorage.getItem('fullName')}{' '}
                    <Typography fontSize={13} fontWeight={400} display={'inline'}>
                        ({localStorage.getItem('role')})
                    </Typography>
                </Typography>
            </Stack>
            {/* <Box width={128} height={47} src={logo} component={'img'} /> */}
            <Stack direction={'row'} spacing={6} alignItems={'center'}>
                <Dropdown>
                    <Stack direction={'row'} alignItems={'center'} spacing={2}>
                        <Box
                            sx={{
                                position: 'relative',
                                svg: {
                                    width: 24,
                                    height: 24,
                                    position: 'static',
                                    transform: 'unset',
                                },
                            }}
                        >
                            <Box
                                sx={{
                                    width: 10,
                                    height: 10,
                                    backgroundColor: 'green.main',
                                    borderRadius: '10px',
                                    position: 'absolute',
                                    right: 0,
                                    top: 2,
                                    zIndex: 3,
                                }}
                            />
                            <NotificationsIcon />
                        </Box>
                        <Typography fontSize={13}>Notifications</Typography>
                    </Stack>
                </Dropdown>
                <Stack direction={'row'} alignItems={'center'} spacing={2}>
                    <DefaultAvatar src={localStorage.getItem("profilePath")} >{(localStorage.getItem('fullName')||"-F").charAt(0)}</DefaultAvatar>                    
                    <div>
                        <Box className={styleDropdown.button} onClick={handleClick}>
                            <Stack
                                alignItems={'center'}
                                justifyContent={'center'}
                                color={'#8F98AB'}
                                fontSize={13}
                                fontWeight={700}
                            >
                                {localStorage.getItem('fullName')}
                            </Stack>
                            <KeyboardArrowDown />
                        </Box>
                        <Menu
                            id='basic-menu'
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            MenuListProps={{
                                'aria-labelledby': 'basic-button',
                            }}
                        >
                            <MenuItem onClick={(e)=>{setIsOpenedUserDetails(true)}}>Profile</MenuItem>
                            <MenuItem onClick={handleLogout}>Logout</MenuItem>
                        </Menu>
                    </div>
                </Stack>
            </Stack>
            {/* {isOpenedUserDetails == true && <ProfileUpdate userID={isOpenedUserDetails} setIsOpenedUserDetails={setIsOpenedUserDetails}  />}             */}

        </Stack>
    );
};
