import React, { useEffect, useState } from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { DefaultDataGrid } from 'ui/DefaultDataGrid/index.js';
import { columnsShift, getSiteShiftsApi, manageShiftApi } from './ShiftRepository';

import { useLoader } from 'contexts/LoaderContext';
import useConfirmAlert from 'CustomControls/confirmAlert';
import ManageShift from './ManageShift';



export const SettingsShiftsTab = ({ isOpenedShiftDetails, setIsOpenedShiftDetails }) => {
    const { startLoading, stopLoading } = useLoader();
    const [baseArray, setBaseArray] = useState([]);
    const [rowsShifts, setRowsShifts] = useState([]);

    useEffect(() => {
        if (isOpenedShiftDetails === "") {
            getData()
            setIsOpenedShiftDetails(null)
        }

    }, [isOpenedShiftDetails]);

    useEffect(() => {
        getData()
    }, []);

    useEffect(() => {
        filterBySearch()
    }, [baseArray]);

    const getData = async () => {
        startLoading()
        var resData = await getSiteShiftsApi()
        if (resData) {
            let totalActive = resData.filter(({ isActive }) => isActive === "1").length
            let totalAdmin = resData.filter(({ role }) => role === "Admin").length
            setBaseArray(resData)

        }
        stopLoading()
    }
    const filterBySearch = (searchKeyword) => {
        const searchColumns = ['name'];
        setRowsShifts(baseArray.filter(obj =>
            searchColumns.some(column =>
                searchKeyword ? obj[column].toLowerCase().includes(searchKeyword.toLowerCase()) : true
            )
        ));
    }

    const onSuccess = async () => {
        startLoading()
        var resData = await manageShiftApi(isOpenedShiftDetails, null)
        if (resData) {
            setIsOpenedShiftDetails(null)
            getData()
        }
        stopLoading()
    };

    return (
        <Stack width={'100%'} height={'100%'}>
            <Box bgcolor={'#F6F6F7'} p={2} border={'1px solid #DBDDE2'} borderTop={0} mt={-1}>
                <Typography mb={2} fontWeight={700}>
                    Shift Management
                </Typography>
            </Box>
            <Box flex={1} border={'1px solid #DBDDE2'} mt={'-1px'}>
                <DefaultDataGrid columns={columnsShift} rows={rowsShifts} onRowClick={(e) => {
                    if (localStorage.getItem("role") !== "Standard") {
                        setIsOpenedShiftDetails(e.row._id)
                    }
                }} />
            </Box>
            {isOpenedShiftDetails != null && <ManageShift shiftID={isOpenedShiftDetails} setIsOpenedShiftDetails={setIsOpenedShiftDetails} getData={getData}/>}
            {useConfirmAlert(
                'Delete Shift?',
                'Are you sure you want to delete this Shift?',
                onSuccess,
                () => { }
            )}
        </Stack>
    );
};
