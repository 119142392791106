import React, { useContext, useState } from 'react';
import { Title } from 'ui/Title';
import { Box, Button, Stack, TextField, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import logo from 'assets/images/logo.svg';
import AuthContext from 'contexts/JWTContext';
import { useSnackbar } from 'notistack';


export const ForgotPassword = () => {
    const navigate = useNavigate();
    const authContext = useContext(AuthContext);
    const [loading, setLoading] = useState(false);
    const [email, setEmail] = useState('');
    const { enqueueSnackbar } = useSnackbar()

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            setLoading(true);
            let data = await authContext.forgotPassword(email)
            console.log("data::",data,"|:|",data !== undefined && data.success)
            setLoading(false);
            if (data !== undefined && data.success) {
                navigate("/authentication/login")
                enqueueSnackbar(data.message, {
                    anchorOrigin: {
                        horizontal: 'right',
                        vertical: 'top'
                    },
                    variant: 'success'
                });
            } else {
                enqueueSnackbar(data.message, {
                    anchorOrigin: {
                        horizontal: 'right',
                        vertical: 'top'
                    },
                    variant: 'error'
                });
            }
        } catch (err) {
            console.error(err);
            setLoading(false);
        }
    };

    return (
        <>

            <Stack alignItems={'center'} justifyContent={'center'} bgcolor={'grey.300'} py={10} px={4} width={'100%'}>
                <Stack alignItems={'center'} justifyContent={'center'} maxWidth={350} mx={'auto'}>
                    <Box width={340} height={48} src={logo} component={'img'} mb={1.5} />
                    <Typography mb={6}>Smart Time Tracking</Typography>
                    <Title mb={2}>Forgot your password?</Title>
                    <Typography mb={3}>No problem. Just let us know your email address and we&apos;ll email you a password reset link that will allow you to choose a new one.</Typography>
                    <Box component={'form'} onSubmit={handleSubmit} width={'100%'}>
                        <Stack spacing={1.5} width={'100%'}>
                            <Stack direction={'column'} width={'100%'}>
                                <Typography mb={1.5} color={'grey.blue'}>
                                    Email
                                </Typography>
                                <TextField
                                    sx={{
                                        '& .MuiInputBase-input': {
                                            padding: '17px 25px',
                                            borderRadius: '10px',
                                            background: 'white',
                                            border: '1px solid #E1E3E8',
                                        },
                                    }}
                                    value={email}
                                    onChange={(e)=>setEmail(e.target.value)}
                                    type={'email'}
                                    placeholder='example@oilservices.com'
                                />
                            </Stack>
                
                            <Button
                                sx={{
                                    fontSize: 20,
                                    py: 1.2,
                                    borderRadius: '30px',
                                    maxWidth: 300,
                                    width: '100%',
                                    alignSelf: 'center',
                                    bgcolor: 'secondary.main',
                                    textTransform: 'capitalize',
                                    '&:hover': {
                                        bgcolor: 'secondary.dark',
                                    }
                                }}
                                type={'submit'}
                            >
                                Submit
                            </Button>
                        </Stack>
                    </Box>
                    <Stack width='100%' justifyContent={'center'} alignItems={'center'} direction={'row'} spacing={1} my={4}>
                        <Box
                            sx={{
                                height: '1px',
                                width: '20%',
                                bgcolor: 'grey.light',
                            }}
                        />
                        <Typography
                            sx={{
                                whiteSpace: 'nowrap',
                            }}
                        >
                            Have an account?
                        </Typography>
                        <Box
                            sx={{
                                height: '1px',
                                width: '20%',
                                bgcolor: 'grey.light',
                            }}
                        />
                    </Stack>
                    <Link to={'/authentication/login'}>
                        <Typography
                            sx={{
                                textDecoration: 'underline',
                                fontSize: 20,
                                fontWeight: 700,
                                textTransform: 'uppercase',
                                '&:hover': {
                                    textDecoration: 'none',
                                },
                            }}
                        >
                            Log In
                        </Typography>
                    </Link>
                    <Stack spacing={1.5} mt={4}>
                        <Typography>Version 2.0.00</Typography>
                        <Typography>iKronosSync</Typography>
                    </Stack>
                </Stack>
            </Stack>
        </>
    );
};
