import React, { useEffect, useState } from 'react';
// import ClusterIcon from 'assets/icons/cluster.png';
import { ClusterIcon } from 'assets/icons/Cluster';
import { Metrics } from 'components/Metrics';
import { SearchInput } from 'components/SearchInput';
import { Filters } from 'ui/Filters';
import { Box, Stack, Typography, MenuItem } from '@mui/material';
import { PipeProfilesIcon } from 'assets/icons/PipeProfilesIcon.jsx';
import { LinesIcon } from 'assets/icons/LinesIcon.jsx';
import { DashboardItemIcon } from 'assets/icons/DashboardItemIcon.jsx';
import { arrayStatus, filterStatus, getSiteStatusColor } from './data/status.jsx';
import { useLoader } from 'contexts/LoaderContext.js';
import { getDashboardApi, getSitePerformanceData } from './Repository/DashboardRepository.jsx';
import { useNavigate } from 'react-router-dom';

export const Dashboard = () => {
    const navigate = useNavigate()
    const { startLoading, stopLoading } = useLoader();
    const [siteArrayData, setSiteArrayData] = useState([]);
    const [arrayTypes, setArrayTypes] = useState([]);
    const [filteredArrayData, setFilteredArrayData] = useState([]);
    const [query, setQuery] = useState("");
    const [selectedStatus, setSelectedStatus] = useState(-1)
    const [sitePerformance, setSitePerformance] = useState("")
    const [metrics1, setMetrics1] = useState([
        {
            title: 'Machines',
            value: "-",
        },
        {
            title: 'Types',
            value: '-',
        },
    ]);
    const [metrics2, setMetrics2] = useState([
        {
            title: 'Running',
            value: "-",
            color: '#24D5AA',
        },
        {
            title: 'Down',
            value: "-",
            color: '#ED373B',
        },
        {
            title: 'Offline',
            value: "-",
            color: '#262B40',
        },
    ]);
    const [metrics3, setMetrics3] = useState([
        {
            title: 'Utilization',
            value: '-',
        },
        {
            title: 'Last 30 Days',
            value: '+50%',
            isGreen: true,
        },
    ]);

    useEffect(() => {
 
    }, []);

    const getData = async () => {
        startLoading()
        var resData = await getDashboardApi()
        console.log("resData::", resData)
        if (resData) {
            let arrayTempSite = []

            var filterArray = applyFilters(resData, "", selectedStatus)
            setFilteredArrayData(filterArray)

            resData.forEach((machine) => {
                if (!checkIsExist(machine.type, arrayTempSite)) {
                    arrayTempSite.push(machine.type)
                }
            })
            const arrayRunning = resData.filter((m) => m.status === "1").length
            const arrayDown = resData.filter((m) => m.status == null || m.status === "0" || m.status === "3").length
            setArrayTypes(arrayTempSite)
            var matrix = [
                {
                    title: 'Machines',
                    value: resData.length,
                },
                {
                    title: 'Types',
                    value: arrayTempSite.length,
                },
            ]
            var matrix2 = [
                {
                    title: 'Running',
                    value: arrayRunning,
                    color: '#24D5AA',
                },
                {
                    title: 'Down',
                    value: arrayDown,
                    color: '#ED373B',
                },
                {
                    title: 'Offline',
                    value: 0,
                    color: '#262B40',
                },
            ]

            setMetrics1(matrix)
            setMetrics2(matrix2)
            setSiteArrayData(resData)
            getSitePerformance()
        }
        //stopLoading()
    }

    function checkIsExist(data, array) {
        return array.filter((obj) => obj === data).length > 0
    }

    const getSitePerformance = async () => {
        startLoading()
        var resData = await getSitePerformanceData()
        console.log("resData::", resData)
        if (resData) {
            setSitePerformance(resData.efficiency)
            var matrix3 = [
                {
                    title: 'Performance',
                    value: resData.efficiency + "%",
                },
                {
                    title: 'Last 30 Days',
                    value: '+50%',
                    isGreen: true,
                },
            ]
            setMetrics3(matrix3)
        }
        stopLoading()
    }

    function handleQueryChange(event) {
        setQuery(event.target.value)
        var filterArray = applyFilters(siteArrayData, event.target.value, selectedStatus)
        setFilteredArrayData(filterArray)
        console.log("Query", event.target.value)
        console.log("filterd", filteredArrayData)
    }

    function applyFilters(arrayDataAll, query, selectedStatus) {
        var finalArray = []
        console.log("data", query)
        var filteredArraySearch = arrayDataAll.filter((dataObj) => {
            let matches = true;

            const properties = ['name', 'type', 'zoneName'];
            let containsQuery = false;

            properties.forEach((property) => {
                var str = getProperty(dataObj, property) + "";
                if (str.toLowerCase().includes(query.toLowerCase())) {
                    containsQuery = true;
                }
            });
            if (!containsQuery) {
                matches = false;
            }
            return matches;
        });
        finalArray = filteredArraySearch.length > 0 ? [...filteredArraySearch] : [];
        if (selectedStatus !== null && selectedStatus !== -1) {
            var filteredArrayStats = finalArray
                .filter((dataObj) => {
                    let matches = true;
                    let containsQuery = false;
                    if (dataObj.status == selectedStatus) {
                        containsQuery = true;
                    }
                    if (!containsQuery) {
                        matches = false;
                    }
                    return matches;
                })
            finalArray = [...filteredArrayStats]
        }
        if (query === "" && selectedStatus === -1) {
            return arrayDataAll;
        }
        console.log("finalArray", finalArray)
        return finalArray;
    }

    function getProperty(obj, prop) {
        const props = prop.split('.');
        return props.reduce((o, p) => o && o[p], obj);
    }

    return (
        <Stack p={2.5} width={'100%'}>
            <Stack direction={'row'} spacing={2} mb={2}>
                <SearchInput handleSubmit={handleQueryChange} />
                <Filters
                    handleFilerChange={(event) => {
                        setSelectedStatus(event.target.value)
                        var filterArray = applyFilters(siteArrayData, query, event.target.value)
                        setFilteredArrayData(filterArray)
                    }}
                    value={selectedStatus}
                    name="selectedStatus"
                    placeholder="Select Status"
                >
                    {filterStatus.map((item, index) => (
                        <MenuItem key={index} value={item.id}> {item.value}</MenuItem>
                    ))}
                </Filters>
            </Stack>
            <Stack direction={'row'} gap={4} rowGap={2} mb={2} flexWrap={'wrap'}>
                <Metrics icon={<PipeProfilesIcon />} data={metrics1} />
                <Metrics icon={<LinesIcon />} data={metrics2} />
                <Metrics icon={<ClusterIcon />} data={metrics3} />
            </Stack>
            <Stack direction={'row'} gap={'10px'} flexWrap={'wrap'}>
                {filteredArrayData.map((item) => {
                    var status = item.status != null ? item.status : "3"
                    var machineMode = item.isInMaintenanceMode != null ? item.isInMaintenanceMode : "0"
                    //var statusText = status == "1" ? "Running" : status == "2" ? "Adjusting" : "Down"
                    // var statusColor = machineMode === "2" ? "blue.main" : machineMode === "1" ? "#FFB547" : status == "1" ? "green.main" : status == "2" ? "#FFB547" : "primary.main"
                    return (
                        <Stack key={item._id} onClick={() => navigate(`/machines/manage`, { state: { id: item._id}})} >
                            <Box
                               
                                p={1}
                                borderRadius={'10px'}
                                border={'2px dashed'}
                                borderColor={getSiteStatusColor(item.status)}
                                width={'calc(16.66666% - 10px)'}
                                maxWidth={245}
                                minWidth={200}
                            >
                                <Stack direction={'row'} mb={1} spacing={2}>
                                    <Stack
                                        alignItems={'center'}
                                        justifyContent={'center'}
                                        width={40}
                                        height={40}
                                        borderRadius={'5px'}
                                        bgcolor={'blue.light'}
                                        sx={{
                                            svg: {
                                                width: 20,
                                                height: 20,
                                            },
                                        }}
                                    >
                                        <DashboardItemIcon />
                                    </Stack>
                                    <Stack spacing={0.5} flexGrow={1}>
                                        <Typography fontSize={12} fontWeight={700}>
                                            {item.name}
                                        </Typography>
                                        <Stack direction={'row'} justifyContent={'space-between'} spacing={2}>
                                            <Typography fontSize={10} color={'#AAB0BC'}>
                                                {item.zoneName}
                                            </Typography>
                                            <Box
                                                flexShrink={0}
                                                width={15}
                                                height={15}
                                                borderRadius={'50%'}
                                                border={'2px solid'}
                                                borderColor={getSiteStatusColor(item.status)}
                                                sx={{
                                                    transform: 'translateY(3px)',
                                                }}
                                            />
                                        </Stack>
                                    </Stack>
                                </Stack>
                                <Stack p={0.5} direction={'row'} spacing={2} justifyContent={'space-between'}>
                                    <Stack>
                                        <Typography fontSize={20} fontWeight={700}>
                                        {arrayStatus.find(status => status.id == item.status) ? arrayStatus.find(status => status.id == item.status)?.value : "-"}
                                        </Typography>
                                        <Typography fontSize={10} color={'#AAB0BC'}>
                                            Status
                                        </Typography>
                                    </Stack>
                                    <Stack>
                                        <Typography fontSize={20} fontWeight={700}>
                                            {item.uptimePercent}%
                                        </Typography>
                                        <Typography fontSize={10} color={'#AAB0BC'}>
                                            Utilization
                                        </Typography>
                                    </Stack>
                                </Stack>
                                <Stack
                                    p={0.5}
                                    bgcolor={'blue.light'}
                                    borderRadius={'10px'}
                                    direction={'row'}
                                    spacing={2}
                                    justifyContent={'space-between'}
                                >
                                    <Stack>
                                        <Typography fontSize={20} fontWeight={700}>
                                            {item.totalUpTime}
                                        </Typography>
                                        <Typography fontSize={10} color={'#AAB0BC'}>
                                            Uptime
                                        </Typography>
                                    </Stack>
                                    <Stack>
                                        <Typography fontSize={20} fontWeight={700}>
                                            {item.totalDownTime}
                                        </Typography>
                                        <Typography fontSize={10} color={'#AAB0BC'}>
                                            Downtime
                                        </Typography>
                                    </Stack>
                                </Stack>
                            </Box>
                        </Stack>
                    )
                })}
            </Stack>
        </Stack>
    );
};
