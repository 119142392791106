import React, { useEffect, useState } from 'react';
import { Box, Typography, Grid, Avatar, } from '@mui/material';
import StatusListTable from './StatusListTable';
import './print.css'

export const PrintReportingDetails = () => {
    const [arrayData, setArrayData] = useState([]);

    useEffect(() => {
        const newWindowState = JSON.parse(localStorage.getItem('printData'));
        const { arrayData } = newWindowState
        setArrayData(arrayData)
        setTimeout(() => {
            window.print();
        }, 1000);
    }, [])

    return (
        <Box width='100%' className="print" m={0}>
            {arrayData.map((data, index) => {
                return (<Box width='100%' p={2} sx={{ border: '1px solid #D9D6D6', marginBottom: "30px" }} key={data._id} className={index !== 0 ? 'page-break' : ''}>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Typography fontSize={26} variant="h4" sx={{ fontWeight: 'bold' }}>Approvals Report Details</Typography>

                    </Box>

                    <Grid container spacing={2} alignItems='center' sx={{ borderBottom: '1px solid #D9D6D6' }}>
                        <Typography fontSize={20} color={'gray'} sx={{ paddingTop: '16px', marginLeft: '50px', fontWeight: 'bold' }}>
                            {`${data.firstName} ${data.middleName} ${data.lastName}`}
                        </Typography>

                        <Box sx={{ display: 'inline-flex', padding: '20px', marginTop: '26px', marginLeft: 'auto' }}>
                            <Grid sx={{ alignItems: 'flex-end' }}>
                                <Typography fontSize={18} sx={{ color: '#4CAF50', marginRight: '30px', fontWeight: 'bold' }}>
                                    {data.clockInTime}
                                </Typography>
                            </Grid>
                            <Grid>
                                <Typography fontSize={18} sx={{ color: '#810202', marginRight: '30px', fontWeight: 'bold' }}>
                                    {data.overTime}
                                </Typography>
                            </Grid>
                            <Grid>
                                <Typography fontSize={18} sx={{ color: '#000000', marginRight: '30px', fontWeight: 'bold' }}>
                                    {data.totalTime}
                                </Typography>
                            </Grid>
                        </Box>
                    </Grid>

                    <Box mt={2} sx={{ display: 'flex', alignItems: 'flex-start' }}>
                        {/* <Avatar src={data.path} sx={{ width: 200, height: 200, marginRight: 10, marginLeft: 4}} /> */}
                        <Box sx={{ marginLeft: 2, marginRight: 4, marginBottom: '60px' }}>
                            <Typography variant='h6' sx={{ fontWeight: 'bold' }}>Job Breakout</Typography>
                            <Typography>  {data.siteName}</Typography>
                            <Typography sx={{ fontWeight: 'bold' }}>MN: {data.totalTime} hrs</Typography>
                        </Box>
                        <Box sx={{ textAlign: 'left' }}>
                            <Typography variant='h6' sx={{ fontWeight: 'bold' }}>Overtime Details</Typography>
                            {
                                data.employeeStatus.map((activity) => {
                                    return (<Typography key={activity._id}>{activity.weekDates}: {activity.overTime}</Typography>)
                                })
                            }

                        </Box>
                    </Box>



                    <StatusListTable arrayData={data.employeeStatus}></StatusListTable>
                </Box>)
            })
            }
        </Box>
    );
};
