import React, { useEffect, useState } from 'react';
import { Backdrop, Box, CircularProgress, Stack, Typography } from '@mui/material';
import { DefaultDataGrid } from 'ui/DefaultDataGrid/index.js';
import { columnsTabletDevice } from './TabletDeviceRepository';
import { ManageTabletDevices } from './ManageTabletDevice';
import api from 'lib/axios';

export const SettingsTabletDevicesTab = ({ tabletDeviceID, setIsOpenedTabletDeviceDetails }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [arrayTabletDevice, setArrayTabletDevice] = useState([]);

    useEffect(() => {
        if (tabletDeviceID === "") {
            getData()
            setIsOpenedTabletDeviceDetails(null)
        }

    }, [tabletDeviceID]);

    useEffect(() => {
        getData()
    }, []);

    function getData() {
        setIsLoading(true)
        api.get('/apis/Devices/getAll/'+localStorage.getItem("siteID")).then((res) => {
            if (res.data.success) {
                var resData = res.data.data
                setArrayTabletDevice(resData)
            }
            setIsLoading(false)
        }).catch(function (error) {
            console.log(error);
        });
    }

    return (
        <Stack width={'100%'} height={'100%'}>
            <Box bgcolor={'#F6F6F7'} p={2} border={'1px solid #DBDDE2'} borderTop={0} mt={-1}>
                <Typography mb={2} fontWeight={700}>
                    Tablet Device Management
                </Typography>
            </Box>
            <Box flex={1} border={'1px solid #DBDDE2'} mt={'-1px'}>
                <DefaultDataGrid columns={columnsTabletDevice} rows={arrayTabletDevice} onRowClick={(e) => {
                    console.log("ID", e.row._id);
                    setIsOpenedTabletDeviceDetails(e.row._id)
                }} />
            </Box>
            {tabletDeviceID != null && <ManageTabletDevices tabletDeviceID={tabletDeviceID} setIsOpenedTabletDeviceDetails={setIsOpenedTabletDeviceDetails} getData={getData} />}
            <Backdrop style={{ zIndex: 1 }} open={isLoading} >
                <CircularProgress color="inherit" />
            </Backdrop>
        </Stack>
    );
};
