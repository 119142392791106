// Importing necessary components and libraries
import api from 'lib/axios.js';
import { enqueueSnackbar } from 'notistack';

export const arrayDays = [
    "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"
]
export const arrayTime = [
    { label: "12:00 am", value: "0" },
    { label: "12:30 am", value: "0.5" },
    { label: "01:00 am", value: "1" },
    { label: "01:30 am", value: "1.5" },
    { label: "02:00 am", value: "2" },
    { label: "02:30 am", value: "2.5" },
    { label: "03:00 am", value: "3" },
    { label: "03:30 am", value: "3.5" },
    { label: "04:00 am", value: "4" },
    { label: "04:30 am", value: "4.5" },
    { label: "05:00 am", value: "5" },
    { label: "05:30 am", value: "5.5" },
    { label: "06:00 am", value: "6" },
    { label: "06:30 am", value: "6.5" },
    { label: "07:00 am", value: "7" },
    { label: "07:30 am", value: "7.5" },
    { label: "08:00 am", value: "8" },
    { label: "08:30 am", value: "8.5" },
    { label: "09:00 am", value: "9" },
    { label: "09:30 am", value: "9.5" },
    { label: "10:00 am", value: "10" },
    { label: "10:30 am", value: "10.5" },
    { label: "11:00 am", value: "11" },
    { label: "11:30 am", value: "11.5" },
    { label: "12:00 pm", value: "12" },
    { label: "12:30 pm", value: "12.5" },
    { label: "01:00 pm", value: "13" },
    { label: "01:30 pm", value: "13.5" },
    { label: "02:00 pm", value: "14" },
    { label: "02:30 pm", value: "14.5" },
    { label: "03:00 pm", value: "15" },
    { label: "03:30 pm", value: "15.5" },
    { label: "04:00 pm", value: "16" },
    { label: "04:30 pm", value: "16.5" },
    { label: "05:00 pm", value: "17" },
    { label: "05:30 pm", value: "17.5" },
    { label: "06:00 pm", value: "18" },
    { label: "06:30 pm", value: "18.5" },
    { label: "07:00 pm", value: "19" },
    { label: "07:30 pm", value: "19.5" },
    { label: "08:00 pm", value: "20" },
    { label: "08:30 pm", value: "20.5" },
    { label: "09:00 pm", value: "21" },
    { label: "09:30 pm", value: "21.5" },
    { label: "10:00 pm", value: "22" },
    { label: "10:30 pm", value: "22.5" },
    { label: "11:00 pm", value: "23" },
    { label: "11:30 pm", value: "23.5" }

]

// Define column configuration for site shifts table
export const columnsShift = [
    {
        field: 'name',
        headerName: 'Name',
        width: 180,
        renderCell: (params) => {          
            return params.value ? params.value : '-';
        }
    },
    {
        field: 'shiftDays',
        headerName: 'Shift Days',
        width: 400,
        renderCell: (params) => {          
            return params.value ? params.value : '-';
        }
    },
    {
        field: 'startTime',
        headerName: 'Start Time',
        width: 150,
        renderCell: (params) => {          
            return params.value ? params.value : '-';
        }
    },
    {
        field: 'endTime',
        headerName: 'End Time',
        width: 150,
        renderCell: (params) => {          
            return params.value ? params.value : '-';
        }
    },
    {
        field: 'totalBreaks',
        headerName: 'Break Hours',
        width: 160,
        renderCell: (params) => {          
            return params.value ? params.value : '-';
        }
    },
    {
        field: 'totalShiftHrs',
        headerName: 'Shift Hours',
        flex: 1,
        renderCell: (params) => {          
            return params.value ? params.value : '-';
        }
    },
];

// Function to fetch site shifts data from API
export const getSiteShiftsApi = async () => {
    try {
        const apiResponse = await api.get(`/apis/companysites/ProductionShifts/${localStorage.getItem("siteID")}`);
        if (apiResponse.data.success) {
            // Map the response data to required format
            var resData = apiResponse.data.data;
            return resData.map((obj) => {
                return {
                    ...obj,
                    id: obj._id,
                    status: {
                        value: obj.isActive === "1" ? 'Active' : 'Disable',
                        code: obj.isActive,
                    },
                };
            });
        }
        return null;
    } catch (error) {
        console.log(error);
        return null;
    }
};

// Function to manage shift data in API (create/update/delete)
export const manageShiftApi = async (shiftID, jsonData) => {
    try {
        let url = `/apis/companysites/ProductionShifts${shiftID ? `/${shiftID}` : ''}`
        if(!jsonData){
            url = `/apis/companysites/ProductionShifts/${localStorage.getItem("siteID")}-${shiftID}`            
        }
        const apiResponse = await api(url, {
            data: jsonData,
            method: jsonData ? shiftID ? "PUT" : "POST" : "DELETE"
        });
        if (apiResponse.data.success && apiResponse.data.success == 1) {
            enqueueSnackbar('Shift ' + (jsonData ? shiftID ? "updated" : "added" : "delete"), {
                anchorOrigin: {
                    horizontal: 'right',
                    vertical: 'top'
                },
                variant: 'success'
            });
            return apiResponse.data.data;
        }else{
            enqueueSnackbar(apiResponse.data.message, {
                anchorOrigin: {
                    horizontal: 'right',
                    vertical: 'top'
                },
                variant: 'error'
            });
        }
        return null;
    } catch (error) {
        console.log(error);
        return null;
    }
};

// Function to fetch shift data by ID from API
export const getShiftDataApi = async (shiftID) => {
    try {
        const apiResponse = await api.post(`/apis/companysites/ProductionShiftsDetail/${shiftID}`, { siteID: localStorage.getItem("siteID") });
        if (apiResponse.data.success) {
            return apiResponse.data.data;
        }
        return null;
    } catch (error) {
        console.log(error);
        return null;
    }
};