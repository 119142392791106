import axios from 'axios';
import{jwtDecode} from 'jwt-decode'
// import AxiosMockAdapter from 'axios-mock-adapter';
const api = axios.create();

// api.interceptors.response.use((response) => response,
//   (error) => Promise.reject((error.response && error.response.data) || 'Something went wrong'));

// // export const mock = new AxiosMockAdapter(api, { delayResponse: 0 });

const handleTokenExpiration = () => {
  localStorage.removeItem('token');
  window.location.href = "/authentication/login";
};

const isTokenExpired = (token) => {
  try {
    const decodedToken = jwtDecode(token);
    // console.log("token data",decodedToken)
    const expirationTime = decodedToken.exp * 1000;
    const currentTime = Date.now();
    return currentTime > expirationTime;
  } catch (error) {
    console.error('Error decoding token:', error);
    return false; 
  }
};
api.interceptors.request.use(
  (config) => {
    // Get the token from storage or Redux/Context state
    const token = localStorage.getItem('accessToken');

    if (token && !isTokenExpired(token)) {
      config.headers.Authorization = `Bearer ${token}`;
    } else {
       handleTokenExpiration();
       return Promise.reject()
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    console.log("In Meddleware response",error)
    if (error.response && error.response.status === 401) {
      handleTokenExpiration();
    }

    return Promise.reject(error);
  }
);

export default api;
