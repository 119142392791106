import React from 'react';
import AuthGuard from './components/AuthGuard.js';
import DashboardLayout from './components/dashboard/DashboardLayout.js';
import GuestGuard from './components/GuestGuard.js';
import { Login } from './modules/Login/Login.jsx';
import { Dashboard } from 'modules/Dashboard/index.js';
import { Settings } from 'modules/Settings/index.js';
import { ForgotPassword } from 'modules/ForgotPassword/ForgotPassword.jsx';
import { ResetPassword } from 'modules/ResetPassword/ResetPassword.jsx';
import { Employees } from 'modules/Employees/Employees.jsx';
import { EmployeeDetail } from 'modules/EmployeeDetail/EmployeeDetail.jsx';
import { MapView } from 'modules/MapView/MapView.jsx';
import { Reporting } from 'modules/Reporting/Reporting.jsx';
import { ReportingDetails } from 'modules/Reporting/ReportingDetails.jsx';
import { PrintReportingDetails } from 'modules/Reporting/PrintReportingDetails.jsx';

const routes = [
  {
    path: 'authentication',
    children: [
      {
        path: 'login',
        element: (
          <GuestGuard>
            <Login />
          </GuestGuard>
        )
      },
      {
        path: 'forgot-password',
        element: <ForgotPassword />
      },
      {
        path: 'reset-password',
        element: <ResetPassword />
      },
    ]
  },
  {
    path: '/',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '/',
        element: <Employees />
      },
    ]
  },
  {
    path: 'dashboard',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '/dashboard',
        element: <Dashboard />
      },
    ]
  },
  {
    path: 'settings',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '/settings',
        element: <Settings />
      },
    ]
  },
  {
    path: 'mapView',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '/mapView',
        element: <MapView />
      },
    ]
  },
  {
    path: 'employees',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '/employees',
        element: <Employees />
      },
      {
        path: '/employees/EmployeeDetail',
        element: <EmployeeDetail />
      },
    ]
  },
  {
    path: 'reporting',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '/reporting',
        element: <Reporting />
      },
      {
        path: '/reporting/ReportingDetails',
        element: <ReportingDetails />
      },
      {
        path: '/reporting/print',
        element: <PrintReportingDetails />
      },
    ]
  },
];

export default routes;
