import { Backdrop, CircularProgress } from "@mui/material"
import { Stack } from "@mui/system"
import dayjs from "dayjs"
import api from "lib/axios"
import { enqueueSnackbar } from "notistack"
import { useEffect, useState } from "react"
import { Datepicker } from "ui/Datepicker"
import { DefaultDataGrid } from "ui/DefaultDataGrid"
import { columns } from './Data/EmployeeActivityData';
import { ImagePopupOpen } from "./ImagePopup"
import { useLoader } from "contexts/LoaderContext"
import StatusListTable from "./StatusListTable"
import { EmployeeActivityManage } from "./EmployeeActivityManage"


export const History = ({ getData, ...props }) => {
    const { openAlert } = useLoader()
    const [isLoading, setIsLoading] = useState(false);
    const [startDate, setStartDate] = useState(dayjs());
    const [endDate, setEndDate] = useState(dayjs().add(1, 'day'));
    const [arrayData, setArrayData] = useState([])
    const [imagePopupOpen, setImagePopupOpen] = useState(false);
    const [employeeActivityPopupOpen, setEmployeeActivityPopupOpen] = useState(false);
    const [selectedEmployeeActivity, setSelectedEmployeeActivity] = useState("");
    const [image, setImage] = useState("");

    useEffect(() => {
        if (props.objEmployee != null) {
            getEmployeeHistory(startDate, endDate)
        }
    }, [props.objEmployee]);

    const handleStartDateChange = (date) => {
        console.log(date)
        const isStartDateAfterEndDate = date.isAfter(endDate);
        const isStartDateEqualToEndDate = date.isSame(endDate, 'day');
        if (isStartDateAfterEndDate && !isStartDateEqualToEndDate) {
            console.log("startDate", startDate)
            enqueueSnackbar("Start date must be before end date", {
                anchorOrigin: {
                    horizontal: 'right',
                    vertical: 'top'
                },
                variant: 'error'
            });
        } else {
            getEmployeeHistory(date, endDate)
            setStartDate(date)
        }
    };

    const handleEndDateChange = (date) => {
        console.log(date)

        const isEndDateAfterStartDate = date.isAfter(startDate);
        const isStartDateEqualToEndDate = date.isSame(startDate, 'day');
        if (!isEndDateAfterStartDate && !isStartDateEqualToEndDate) {
            enqueueSnackbar("End date must be after start date", {
                anchorOrigin: {
                    horizontal: 'right',
                    vertical: 'top'
                },
                variant: 'error'
            });
        } else {
            getEmployeeHistory(startDate, date)
            setEndDate(date)
        }
    };

    function getEmployeeHistory(startDate, endDate) {

        setIsLoading(true)
        api.post('/apis/EmployeesStatus/getActivity/' + props.employeeID, {
            startDate: startDate.format('MM-DD-YYYY'),
            endDate: endDate.format('MM-DD-YYYY'),
            siteID: localStorage.getItem("siteID"),
        }).then((res) => {

            if (res.data.success) {
                var resData = res.data.data
                setArrayData(resData)
            }
            setIsLoading(false)
        }).catch(function (error) {
            console.log(error);
        });
    }

    function handleOpenPopup(value) {
        openAlert()
        setImagePopupOpen(true)
        setImage(value)
    }

    const onEditStatusClick = (value) => {
        setSelectedEmployeeActivity(value._id)
        setEmployeeActivityPopupOpen(true)
    }

    return (
        <>
            <Stack mb={2} direction={'row'} gap={12} alignItems={'center'}>
                <Stack direction={'row'} gap={2} mt={2} ml={2}>
                    <Datepicker
                        initialDate={startDate}
                        handleDateChange={handleStartDateChange}
                    />
                    <Datepicker
                        initialDate={endDate}
                        handleDateChange={handleEndDateChange}
                    />
                </Stack>

            </Stack>
            <StatusListTable arrayData={arrayData} employeeName={props.objEmployee.firstName + " " + props.objEmployee.lastName} siteName={props.objEmployee.siteName} onEdit={onEditStatusClick} isEdit={false} />
            {/* <DefaultDataGrid
                columns={columns(handleOpenPopup)}
                rows={arrayData}
            // onRowClick={({ id }) => {
            //     //navigate(`/employees/EmployeeDetail`, { state: { id: id } })
            //     //console.log("id", id);
            // }}
            /> */}
            {imagePopupOpen && <ImagePopupOpen image={image} />}
            {employeeActivityPopupOpen &&
                <EmployeeActivityManage
                    selectedDataId={selectedEmployeeActivity}
                    employeeID={props.objEmployee._id}
                    employeeName={props.objEmployee.firstName + " " + props.objEmployee.lastName}
                    closeDetails={(e) => {
                        setEmployeeActivityPopupOpen(false)
                        setSelectedEmployeeActivity("")
                        if (e) {
                            getData()
                            getEmployeeHistory(startDate, endDate)
                        }
                    }} />}
            <Backdrop style={{ zIndex: 1 }} open={isLoading} >
                <CircularProgress color="inherit" />
            </Backdrop>
        </>
    )
}