import { ClusterIcon } from 'assets/icons/Cluster';
import { LinesIcon } from 'assets/icons/LinesIcon.jsx';
import { Metrics } from '../../components/Metrics';
import { SearchInput } from '../../components/SearchInput';
import { CircleButton } from '../../ui/CircleButton';
import { Filters } from '../../ui/Filters';
import { Box, Stack, Backdrop, CircularProgress, MenuItem } from '@mui/material';
import { useState, useEffect } from 'react';
import api from '../../lib/axios';
import { Map } from './Map';
import { Fullscreen, FullscreenExit } from '@mui/icons-material';
import { filterStatus } from 'modules/Dashboard/data/status';
import { arrayEmployeeStatus } from 'modules/Employees/data/ArrayData/status';
import { MetricsEmployeeActivity } from 'components/Metrics/MetricsEmployeeActivity';
export const MapView = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [isFullScreen, setIsFullScreen] = useState(false);
    const [arrayData, setArrayData] = useState([]);
    const [query, setQuery] = useState("");
    const [filteredArrayData, setFilteredArrayData] = useState([]);
    const [arrayShift, setArrayShift] = useState([]);
    const [selectedStatus, setSelectedStatus] = useState(-1)
    const [selectedShift, setSelectedShift] = useState(-1)
    const [metrics1, setMetrics1] = useState([]);
    const [metrics2, setMetrics2] = useState([
        {
            title: 'Clocked In',
            value: "-",
            color: '#24D5AA',
        },
        {
            title: 'Clocked Out',
            value: "-",
            color: '#ED373B',
        },
    ]);
    const [metrics3, setMetrics3] = useState([
        {
            title: 'Manpower',
            value: '-',
        },
        {
            title: 'Average Hrs',
            value: '-',
            isGreen: true,
        },
    ]);


    useEffect(() => {
        getEmployeeMapList()
    }, []);


    function getEmployeeMapList() {
        setIsLoading(true)
        api.post('/apis/Employees/getAllMap', { siteID: localStorage.getItem("siteID") }).then((res) => {
            if (res.data.success) {
                var resData = res.data.data
                var filterArray = applyFilters(resData.arrayData, query, selectedStatus, selectedShift)
                setFilteredArrayData(filterArray)
                setArrayData(resData.arrayData)
                setArrayShift(resData.arrayShift)
                var arraySiteSummery = []
                resData.arrayShift.forEach((data) => {
                    arraySiteSummery.push(
                        {
                            title: data.name,
                            value: data.totalEmployee,
                        }
                    );
                });
                setMetrics1(arraySiteSummery)
                var arrayMatrix2 = [
                    {
                        title: 'Clocked In',
                        value: resData.totalClockIn,
                        color: '#24D5AA',
                    },
                    {
                        title: 'Clocked Out',
                        value: resData.totalClockOut,
                        color: '#ED373B',
                    },
                ]
                setMetrics2(arrayMatrix2)
                var arrayMatrix3 = [
                    {
                        title: 'Manpower',
                        value: resData.manPower + "%",
                    },
                    {
                        title: 'Average Hrs',
                        value: resData.avgTime,
                        isGreen: true,
                    },
                ]
                setMetrics3(arrayMatrix3)
            }
            setIsLoading(false)
        }).catch(function (error) {
            console.log(error);
        });
    }

    function applyFilters(arrayDataAll, query, selectedStatus, selectedShift) {
        if (query === "" && selectedStatus === -1 && selectedShift === -1) {
            return arrayDataAll;
        }
        var finalArray = []
        var filteredArraySearch = arrayDataAll.filter((dataObj) => {
            let matches = true;

            const properties = ['firstName', 'middleName', 'lastName'];
            let containsQuery = false;

            properties.forEach((property) => {
                var str = getProperty(dataObj, property) + "";
                if (str.toLowerCase().includes(query.toLowerCase())) {
                    containsQuery = true;
                }
            });
            if (!containsQuery) {
                matches = false;
            }
            return matches;
        });
        finalArray = filteredArraySearch.length > 0 ? [...filteredArraySearch] : [];
        if (selectedStatus !== null && selectedStatus !== -1) {
            var filteredArrayStats = finalArray
                .filter((dataObj) => {
                    let matches = true;
                    let containsQuery = false;
                    if (dataObj.activityStatus == selectedStatus) {
                        containsQuery = true;
                    }
                    if (!containsQuery) {
                        matches = false;
                    }
                    return matches;
                })
            finalArray = [...filteredArrayStats]
        }

        if (selectedShift !== null && selectedShift != -1) {
            var filteredArrayShift = finalArray
                .filter((dataObj) => {
                    let matches = true;
                    let containsQuery = false;

                    if (dataObj.shiftID === selectedShift) {
                        containsQuery = true;
                    }

                    if (!containsQuery) {
                        matches = false;
                    }
                    return matches;
                });
            finalArray = [...filteredArrayShift]
        }
        return finalArray;
    }

    function getProperty(obj, prop) {
        const props = prop.split('.');
        return props.reduce((o, p) => o && o[p], obj);
    }


    function handleQueryChange(event) {
        setQuery(event.target.value)
        var filterArray = applyFilters(arrayData, event.target.value, selectedStatus, selectedShift)
        setFilteredArrayData(filterArray)
    }

    return (
        <Stack pb={!isFullScreen && 2} pt={2} px={2.5} width={'100%'}>
            {!isFullScreen && (
                <Stack>
                    <Stack direction={'row'} gap={4} rowGap={2} flexWrap={'wrap'}>
                        <MetricsEmployeeActivity data={metrics1} />
                        <Metrics icon={<LinesIcon />} data={metrics2} />
                        <Metrics icon={<ClusterIcon />} data={metrics3} />
                    </Stack>
                </Stack>
            )}
            <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'} mt={isFullScreen ? 0 : 2}>
                <Stack direction={'row'} spacing={2} flex={1}>
                    <Filters
                        handleFilerChange={(event) => {
                            setSelectedStatus(event.target.value)
                            var filterArray = applyFilters(arrayData, query, event.target.value, selectedShift)
                            setFilteredArrayData(filterArray)
                        }}
                        value={selectedStatus}
                        name="selectedStatus"
                        placeholder="Select Status"
                    >
                        {arrayEmployeeStatus.map((item, index) => (
                            <MenuItem key={index} value={item.id}> {item.value}</MenuItem>
                        ))}
                    </Filters>
                    <Filters
                        handleFilerChange={(event) => {
                            setSelectedShift(event.target.value)
                            var filterArray = applyFilters(arrayData, query, selectedStatus, event.target.value)
                            setFilteredArrayData(filterArray)
                        }}
                        value={selectedShift}
                        name="selectedShift"
                        placeholder="Select Shift"
                    >
                        {arrayShift.map((item, index) => (
                            <MenuItem key={index} value={item._id}> {item.name}</MenuItem>
                        ))}
                    </Filters>
                    <SearchInput handleSubmit={handleQueryChange} />
                </Stack>
                <CircleButton
                    icon={isFullScreen ? <FullscreenExit /> : <Fullscreen />}
                    onClick={() => setIsFullScreen((prevState) => !prevState)}
                />
            </Stack>
            <Box
                mx={isFullScreen && '-20px'}
                mt={2}
                flex={1}
                border={'1px solid'}
                borderColor={'grey.light'}
                borderRadius={'5px'}
                overflow={'hidden'}
            >
                {!isFullScreen && <Map arrayInventory={filteredArrayData} />}
                {isFullScreen && <Map arrayInventory={filteredArrayData} />}
            </Box>
            <Backdrop style={{ zIndex: 1 }} open={isLoading} >
                <CircularProgress color="inherit" />
            </Backdrop>
        </Stack>

    );
};