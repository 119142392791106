export const tabs = [
    {
        title: 'Details',
        value: 'details',
    },
    {
        title: 'History',
        value: 'history',
    },
]
