import mapboxgl from 'mapbox-gl';
import { Box } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import pin from '../../assets/images/pin.png';
import { useNavigate } from 'react-router-dom';
import { getEmployeeStatusColor } from 'modules/Employees/data/employee';
mapboxgl.accessToken = 'pk.eyJ1Ijoic2lnbmFsdHJhY2tzIiwiYSI6ImNsd3Rjd3A1bTAyYnQybnBuNXZnajJ2YjEifQ.6DdrfKM2ke64TpX-Igogdg';

export const Map = ({ sx, arrayInventory }) => {
    const navigate = useNavigate();
    const mapContainer = useRef(null);
    const map = useRef(null);
    const [lng, setLng] = useState(-74.01521529520066);
    const [lat, setLat] = useState(40.73760113154407);
    const [zoom, setZoom] = useState(9);

    function createCluster(props) {
        console.log("props: ", props)
        let html = `<div class="map-cluster">
        <span></span>
        <span></span>
        ${props.point_count}
            <div />`;

        const el = document.createElement('div');
        el.innerHTML = html;
        return el.firstChild;
    }

    const initMap = () => {
        if (!map.current && mapContainer.current) {
            map.current = new mapboxgl.Map({
                container: mapContainer.current,
                style: 'mapbox://styles/signaltracks/clwun35vw012v01qsagva4ax3',
                center: [lng, lat],
                zoom: zoom,
            });
            map.current.addControl(new mapboxgl.NavigationControl());
            let arrayCluster = []
            arrayInventory.map((inventory) => {
                if (inventory.latitude != null && inventory.longitude != null && inventory.latitude != "" && inventory.longitude != "") {
                    arrayCluster.push(inventory)
                }
            })
            if (map.current && arrayCluster.length > 0) {
                map.current.setCenter([arrayCluster[0].longitude, arrayCluster[0].latitude])
            }

            const waypoints = arrayCluster.map((cluster) => ({
                type: "Feature",
                properties: cluster,
                geometry: {
                    type: "Point",
                    coordinates: [cluster.longitude, cluster.latitude, 0.0],
                },
            }));
            var objCluster = {
                "type": "FeatureCollection",
                "crs": { "type": "name", "properties": { "name": "urn:ogc:def:crs:OGC:1.3:CRS84" } },
                "features": waypoints
            }

            map.current.on('load', () => {
                map.current.loadImage(pin, function (error, image) {
                    if (error) throw error;
                    map.current.addImage('custom-pin', image);
                });
                map.current.addSource('tracks', {
                    type: 'geojson',
                    data: objCluster,
                    cluster: true,
                    clusterMaxZoom: 14,
                    clusterRadius: 50,
                });

                map.current.addLayer({
                    id: 'clusters',
                    type: 'circle',
                    source: 'tracks',
                    filter: ['has', 'point_count'],
                    paint: {
                        'circle-color': 'transparent',
                        'circle-radius': 40,
                    },
                });

                map.current.addLayer({
                    id: 'cluster-count',
                    type: 'symbol',
                    source: 'tracks',
                    filter: ['has', 'point_count'],
                });

                map.current.addLayer({
                    id: 'unclustered-point',
                    type: 'symbol',
                    source: 'tracks',
                    filter: ['!', ['has', 'point_count']],
                    layout: {
                        "icon-image": "custom-pin",
                        "icon-size": 0.4,
                    },
                });

                map.current.on('click', 'clusters', (e) => {
                    const features = map.current.queryRenderedFeatures(e.point, {
                        layers: ['clusters'],
                    });
                    const clusterId = features[0].properties.cluster_id;
                    map.current.getSource('tracks').getClusterExpansionZoom(clusterId, (err, zoom) => {
                        if (err) return;

                        map.current.easeTo({
                            center: features[0].geometry.coordinates,
                            zoom: zoom,
                        });
                    });
                });

                map.current.on('click', 'unclustered-point', (e) => {
                    const coordinates = e.features[0].geometry.coordinates.slice();
                    const employee = e.features[0].properties;
                    var popup = new mapboxgl.Popup({ anchor: 'bottom', closeOnClick: true })
                        .setLngLat(coordinates)
                        .setHTML(
                            `<div class='marker-wrapper'>
                                <div class='marker-header'>
                                    <div class="marker-header-item">
                                        <div class="marker-header-item-value">${employee != null ? (employee.firstName + " " + employee.middleName + " " + employee.lastName) : "-"}</div >
                                        <div class="marker-header-item-name">Employee Name</div>
                                    </div >
                                </div >
                                <div class='marker-header'>
                                    <div class='marker-item'>
                                        <div class="marker-item-box">
                                            <div class='marker-header-item-value'>${employee != null && employee.employPin !== "" ? employee.employPin : "-"}</div>
                                            <div class='marker-item-value marker-item-circle' style="background-color: ${employee.activityStatus != null && employee.activityStatus != "" ? getEmployeeStatusColor(employee.activityStatus + "") : "#FFFFFF"};"></div>
                                        </div>
                                        <div class='marker-header-item-name'>Status</div>
                                    </div>
                                    <button type="button" class="marker-footer-btn"></button>
                                </div>
                            </div >`
                        )
                        .addTo(map.current);

                    popup.getElement().querySelector('.marker-footer-btn').addEventListener('click', function (e) {
                        e.stopPropagation();
                        navigate(`/employees/EmployeeDetail`, { state: { id: employee._id } })
                    });
                });

                const markers = {};
                let markersOnScreen = {};

                function updateMarkers() {
                    const newMarkers = {};
                    const features = map.current.querySourceFeatures('tracks');

                    for (const feature of features) {
                        const coords = feature.geometry.coordinates;
                        const props = feature.properties;

                        if (!props.cluster) continue;
                        const id = props.cluster_id;

                        let marker = markers[id];

                        if (!marker) {
                            const el = createCluster(props);
                            marker = markers[id] = new mapboxgl.Marker({
                                element: el,
                            }).setLngLat(coords);
                        }
                        newMarkers[id] = marker;

                        if (!markersOnScreen[id]) marker.addTo(map.current);
                    }

                    for (const id in markersOnScreen) {
                        if (!newMarkers[id]) markersOnScreen[id].remove();
                    }
                    markersOnScreen = newMarkers;
                }

                map.current.on('render', () => {
                    if (!map.current.isSourceLoaded('tracks')) return;
                    updateMarkers();
                });

                map.current.on('move', () => {
                    setLng(map.current.getCenter().lng.toFixed(4));
                    setLat(map.current.getCenter().lat.toFixed(4));
                    setZoom(map.current.getZoom().toFixed(2));
                });

                map.current.on('mouseenter', 'clusters', () => {
                    map.current.getCanvas().style.cursor = 'pointer';
                });
                map.current.on('mouseleave', 'clusters', () => {
                    map.current.getCanvas().style.cursor = '';
                });
            });
        }
    };

    useEffect(() => {
        if (map.current) {
            map.current.remove();
            map.current = null

        }
        initMap()
    }, [arrayInventory]);

    return (
        <Box
            sx={{
                width: '100%',
                height: '100%',
                ...sx,
            }}
            ref={mapContainer}
        />
    );
};
