import React from 'react';
import { CircularProgress, Backdrop } from '@mui/material';
import { useLoader } from 'contexts/LoaderContext';


const Loader = () => {
    const { isLoading } = useLoader();
    return (
        <Backdrop open={isLoading} style={{ zIndex: 9999 }}>
            <CircularProgress color="inherit" />
        </Backdrop>
    );
};

export default Loader;
