import React, { useEffect, useState } from 'react';
import { Backdrop, Box, ButtonBase, CircularProgress, Grid, MenuItem, Stack, Typography } from '@mui/material';
import { Popup } from 'components/Popup';
import { DefaultSelect } from 'ui/DefaultSelect';
import { DefaultButton } from 'ui/DefaultButton';
import { enqueueSnackbar } from 'notistack';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { DefaultInput } from 'ui/DefaultInput';
import api from 'lib/axios';
import { ConfirmAlert } from 'CustomControls/confirmAlert copy';
import { useNavigate } from 'react-router';

export const ResetPin = ({ employeeID, closeDetails ,getData}) => {
    const [isLoading, setIsLoading] = useState(false);
    const [employeePin, setEmployeePin] = useState(null);

    useEffect(() => {
        if (employeeID != "") {
            getEmployeeDetailByID()
        }
    }, [employeeID]);

    function getEmployeeDetailByID() {
        setIsLoading(true)
        api.get('/apis/Employees/getById/' + employeeID).then((res) => {
            if (res.data.success) {
                var resData = res.data.data
                console.log("resData", resData);
                setEmployeePin(resData.employPin);
            }
            setIsLoading(false)
        }).catch(function (error) {
            console.log(error);
        });
    }

    const onClose = () => {
        closeDetails(false)
    };

    async function apiCalling(values) {
        var data = {
            employPin: values.employeePin,
        }
        return await api.put('/apis/Employees/updateById/' + employeeID, data)
    }

    return (
        <>
            <Formik
                enableReinitialize
                initialValues={{
                    submit: null,
                    employeePin: employeePin,
                }}
                validationSchema={Yup
                    .object()
                    .shape({
                        employeePin: Yup.string().required('Employee pin is required'),
                    })}
                onSubmit={async (values, { resetForm, setErrors, setStatus, setSubmitting }) => {
                    console.log("onsubmit")
                    try {
                        setIsLoading(true)
                        setEmployeePin(values.employeePin)
                        var data = await apiCalling(values)
                        setIsLoading(false)
                        if (data !== undefined && data.data.success) {
                            console.log("IF")
                            setStatus({ success: true });
                            setSubmitting(false);
                            enqueueSnackbar('Employee pin updated', {
                                anchorOrigin: {
                                    horizontal: 'right',
                                    vertical: 'top'
                                },
                                variant: 'success'
                            });
                            closeDetails(true)
                            getData()
                        } else {
                            console.log("ELSE")
                            setStatus({ success: false });
                            setErrors({ submit: data.data.message });
                            setSubmitting(false);
                            enqueueSnackbar(data.data.message, {
                                anchorOrigin: {
                                    horizontal: 'right',
                                    vertical: 'top'
                                },
                                variant: 'error'
                            });
                        }
                    } catch (err) {
                        console.log("error12", err);
                        setIsLoading(false)
                        setStatus({ success: false });
                        setErrors({ submit: err.message });
                        setSubmitting(false);
                    }

                }}
            >

                {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values, setFieldValue }) => (
                    <form
                        onSubmit={handleSubmit}
                        style={{ height: "100%" }}
                    >
                        <Stack
                            px={4}
                            pt={3}
                            pb={2}
                            gap={4}
                            overflowY={'auto'}
                            minWidth={'600px'}
                            height={'750px'}
                            maxHeight={'calc(100vh - 100px)'}
                            maxWidth={'600px'}
                            position={'fixed'}
                            zIndex={10}
                            sx={{
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                overflowY: 'auto',
                                borderRadius: '10px',
                                bgcolor: 'common.white',
                                border: '1px solid #D5D5D5',
                            }}
                        >
                            <Typography fontSize={18}>Device Details</Typography>
                            <Box flex={1}>
                                <Stack gap={3}>
                                    <Stack direction={'row'} gap={3} maxWidth={500}>
                                        <DefaultInput
                                            label='Employee Pin'
                                            name={"employeePin"}
                                            value={values.employeePin}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            error={Boolean(touched.employeePin && errors.employeePin)}
                                            helperText={touched.employeePin && errors.employeePin} />
                                    </Stack>
                                </Stack>

                            </Box>
                            <Stack direction={'row'} gap={3} justifyContent={'space-between'}>
                                <Box></Box>
                                <Stack direction={'row'} gap={2}>
                                    <ButtonBase
                                        sx={{
                                            py: 1,
                                            px: 3,
                                            bgcolor: 'primary.main',
                                            borderRadius: '5px',
                                        }}
                                        onClick={onClose}
                                    >
                                        <Typography fontSize={14} fontWeight={700} color={'common.white'}>
                                            Cancel
                                        </Typography>
                                    </ButtonBase>
                                    <ButtonBase
                                        sx={{
                                            py: 1,
                                            px: 3,
                                            bgcolor: '#EFEFEF',
                                            borderRadius: '5px',
                                        }}
                                        disabled={isSubmitting}
                                        type="submit"
                                    >
                                        <Typography fontSize={14} fontWeight={700}>
                                            Save
                                        </Typography>
                                    </ButtonBase>
                                </Stack>
                            </Stack>
                        </Stack>
                    </form>
                )}
            </Formik>
            <Backdrop style={{ zIndex: 11 }} open={isLoading} >
                <CircularProgress color="inherit" />
            </Backdrop>
        </>
    )
}