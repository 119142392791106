import React from 'react';

import { ClusterIcon } from 'assets/icons/Cluster';
import { MapViewIcon } from 'assets/icons/MapViewIcon';
import { SettingsIcon } from 'assets/icons/SettingsIcon';
import { PipeProfilesIcon } from 'assets/icons/PipeProfilesIcon.jsx';
import { DowntimeCodesIcon } from 'assets/icons/DowntimeCodesIcon.jsx';


export const mainMenu = [
    // {
    //     title: 'Dashboard',
    //     link: '/dashboard',
    //     icon: <ClusterIcon />,
    // },
    {
        title: "Employee’s",
        link: '/employees',
        icon: <PipeProfilesIcon />,
    },
    {
        title: 'Map View',
        link: '/mapView',
        icon: <MapViewIcon />,
    },
    {
        title: 'Reporting',
        link: '/reporting',
        icon: <DowntimeCodesIcon />,
    },
];

export const systemMenu = [
    {
        title: 'Settings',
        link: '/settings',
        icon: <SettingsIcon />,
    },
];
