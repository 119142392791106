import React, { useEffect, useRef, useState } from 'react';
import { DefaultDataGrid } from 'ui/DefaultDataGrid';
import { Box, ButtonBase, Grid, Stack, Typography, } from '@mui/material';
import { columns } from './Data/EmployeeActivityData';
import { useLoader } from 'contexts/LoaderContext';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import * as am5 from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';
import moment from 'moment';
import { ImagePopupOpen } from './ImagePopup';
import { arrayStatus } from 'modules/Employees/data/ArrayData/status';
import { ResetPin } from './ResetPin';
import { arrayOvertimeRate } from 'modules/Employees/data/ArrayData/overtimeRate';
import StatusListTable from './StatusListTable';
import { EmployeeActivityManage } from './EmployeeActivityManage';

export const Details = ({ employeeID, disabled, onEditClick, arrayData, objEmployee, getData }) => {
    const { openAlert } = useLoader()
    let refChart = useRef(null)
    const [imagePopupOpen, setImagePopupOpen] = useState(false);
    const [image, setImage] = useState("");
    const [employeePinPopupOpen, setEmployeePinPopupOpen] = useState(false);
    const [employeeActivityPopupOpen, setEmployeeActivityPopupOpen] = useState(false);
    const [selectedEmployeeActivity, setSelectedEmployeeActivity] = useState("");

    useEffect(() => {
        if (objEmployee != null) {
            getChartData(arrayData)
        }
    }, [objEmployee]);

    function handleOpenPopup(value) {
        openAlert()
        setImagePopupOpen(true)
        setImage(value)
    }

    function getChartData(chartData) {
        am5.addLicense("AM5C349425111");
        am5.array.each(am5.registry.rootElements, function (root) {
            if (root && root.dom && root.dom.id == "chartActivity") {
                root.dispose();
            }
        });
        let root = am5.Root.new("chartActivity");
        root.utc = true;
        root.dateFormatter.setAll({
            dateFormat: "MM/dd/YY hh:mm a",
            dateFields: ["valueX", "openValueX"]
        });
        root.setThemes([
            am5themes_Animated.new(root)
        ]);
        var chart = root.container.children.push(am5xy.XYChart.new(root, {
            panX: false,
            panY: false,
            wheelX: "panX",
            wheelY: "zoomX",
            layout: root.verticalLayout
        }));
        var legend = chart.children.push(am5.Legend.new(root, {
            centerX: am5.p50,
            x: am5.p50
        }))
        var colors = chart.get("colors");
        var data = []
        chartData.map(obj => {
            console.log("object", obj)
            var objShift = {}
            objShift.end = moment(obj.endTime).utc(true).valueOf()
            objShift.category = ""
            objShift.start = moment(obj.startTime).utc(true).valueOf()
            objShift.columnSettings = {
                fill: obj.status === "1" ? "#76C278" : "#F06566"
            }
            data.push(objShift)
        })
        console.log("data", data)
        var yAxis = chart.yAxes.push(
            am5xy.CategoryAxis.new(root, {
                categoryField: "category",
                renderer: am5xy.AxisRendererY.new(root, {}),
                tooltip: am5.Tooltip.new(root, {})
            })
        );
        yAxis.data.setAll([
            { category: "" }
        ]);
        var xAxis = chart.xAxes.push(
            am5xy.DateAxis.new(root, {
                baseInterval: { timeUnit: "minute", count: 1 },
                renderer: am5xy.AxisRendererX.new(root, {})
            })
        );
        xAxis.get("dateFormats")["minute"] = "hh:mm a";
        xAxis.get("dateFormats")["hour"] = "hh:mm a";

        var series = chart.series.push(am5xy.ColumnSeries.new(root, {
            xAxis: xAxis,
            yAxis: yAxis,
            openValueXField: "start",
            valueXField: "end",
            categoryYField: "category",
            sequencedInterpolation: true
        }));

        series.columns.template.setAll({
            templateField: "columnSettings",
            strokeOpacity: 0,
            tooltipText: "[bold]{openValueX}[/] - [bold]{valueX}[/]"
        });

        series.data.setAll(data);
        chart.set("scrollbarX", am5.Scrollbar.new(root, { orientation: "horizontal" }));

        series.appear();
        chart.appear(1000, 100);
        refChart = root;
    }

    const onEditStatusClick = (value) => {
        setSelectedEmployeeActivity(value._id)
        setEmployeeActivityPopupOpen(true)
    }

    return (
        <>
            <Stack
                sx={{
                    pointerEvents: disabled && 'none',
                }}
                height={'100%'}
            >
                <Box p={2}>
                    <Grid container spacing={4}>
                        <Grid item xs={1.5}>
                            <Stack spacing={1}>
                                <Typography color={'#A8A8A7'}>First Name</Typography>
                                <Typography fontWeight={700}>{objEmployee.firstName}</Typography>
                            </Stack>
                        </Grid>
                        <Grid item xs={1.5}>
                            <Stack spacing={1}>
                                <Typography color={'#A8A8A7'}>Middle Name</Typography>
                                <Typography fontWeight={700}>{objEmployee.middleName}</Typography>
                            </Stack>
                        </Grid>
                        <Grid item xs={1.5}>
                            <Stack spacing={1}>
                                <Typography color={'#A8A8A7'}>Last Name</Typography>
                                <Typography fontWeight={700}>{objEmployee.lastName}</Typography>
                            </Stack>
                        </Grid>
                        <Grid item xs={1.5}>
                            <Stack spacing={1}>
                                <Typography color={'#A8A8A7'}>Pin</Typography>
                                <Typography fontWeight={700}>{objEmployee.employPin}</Typography>
                            </Stack>
                        </Grid>
                        <Grid item xs={4}>
                            <Stack spacing={1}>
                                <Typography color={'#A8A8A7'}>Status</Typography>
                                <Typography fontWeight={700}>{arrayStatus.find(obj => obj.id == objEmployee.status)?.value}</Typography>
                            </Stack>
                        </Grid>
                        <Grid item xs={2}>
                            <Stack alignItems={'flex-end'}>
                                <Stack direction={'row'} spacing={1} justifyContent={'flex-end'} >
                                    <ButtonBase
                                        type={'button'}
                                        bgcolor={'blue.light'}
                                        onClick={() => {
                                            //setSelectedDataId("")
                                            setEmployeePinPopupOpen(true)
                                        }}
                                        sx={{
                                            minWidth: 100,
                                            textTransform: 'none',
                                            borderRadius: '5px',
                                            height: '40px',
                                            border: '0px solid black',
                                            backgroundColor: 'primary.main',
                                            fontSize: "13px",
                                            fontWeight: "normal",
                                            color: 'common.white',
                                            '&:hover': {
                                                bgcolor: 'primary.main',
                                            },
                                        }}
                                    >
                                        Reset Pin
                                    </ButtonBase>
                                    <ButtonBase
                                        type={'button'}
                                        bgcolor={'blue.light'}
                                        onClick={(e) => {
                                            onEditClick()
                                        }}
                                        sx={{
                                            minWidth: 100,
                                            textTransform: 'none',
                                            borderRadius: '5px',
                                            height: '40px',
                                            border: '0px solid black',
                                            backgroundColor: '#F5F7FB',
                                            fontSize: "13px",
                                            fontWeight: "normal",
                                            color: '#262B40',
                                            '&:hover': {
                                                bgcolor: '#F5F7FB',
                                            },
                                        }}
                                    >
                                        Edit
                                    </ButtonBase>
                                </Stack>
                            </Stack>
                        </Grid>

                        <Grid item xs={1.5}>
                            <Typography color={'#A8A8A7'}>Hourly Rate</Typography>
                            <Typography fontWeight={700}>{objEmployee.hourlyPayRate}</Typography>
                        </Grid>
                        <Grid item xs={1.5}>
                            <Typography color={'#A8A8A7'}>OT Rate</Typography>
                            <Typography fontWeight={700}>{arrayOvertimeRate.find(obj => obj.id == objEmployee.overTimeRate)?.value}</Typography>
                        </Grid>
                        <Grid item xs={1.5}>
                            <Typography color={'#A8A8A7'}>Shift</Typography>
                            <Typography fontWeight={700}>{objEmployee.shiftName ? objEmployee.shiftName : "-"}</Typography>
                        </Grid>
                        <Grid item xs={1.5}>
                            <Typography color={'#A8A8A7'}>Site Location</Typography>
                            <Typography fontWeight={700}>{objEmployee.siteName ? objEmployee.siteName : "-"}</Typography>
                        </Grid>
                        <Grid item xs={1.5}>
                            <Typography color={'#A8A8A7'}>Email</Typography>
                            <Typography fontWeight={700}>{objEmployee.email}</Typography>
                        </Grid>
                    </Grid>
                </Box>
                <Box height={200} mb={3}>
                    <div ref={refChart} id="chartActivity" style={{ width: "100%", height: "200px" }}></div>
                </Box>

                {/* <DefaultDataGrid
                    columns={columns(handleOpenPopup)}
                    rows={arrayData}
                    onRowClick={({ id }) => {
                        //navigate(`/employees/EmployeeDetail`, { state: { id: id } })
                        //console.log("id", id);
                    }}
                /> */}
                <Stack direction={'row'} spacing={1} mb={1} pr={2} justifyContent={'flex-end'} >
                    <ButtonBase
                        type={'button'}
                        bgcolor={'blue.light'}
                        onClick={(e) => {
                            setSelectedEmployeeActivity("")
                            setEmployeeActivityPopupOpen(true)
                        }}
                        sx={{
                            minWidth: 100,
                            textTransform: 'none',
                            borderRadius: '5px',
                            height: '40px',
                            border: '0px solid black',
                            backgroundColor: '#F5F7FB',
                            fontSize: "13px",
                            fontWeight: "normal",
                            color: '#262B40',
                            '&:hover': {
                                bgcolor: '#F5F7FB',
                            },
                        }}
                    >
                        Add
                    </ButtonBase>
                </Stack>
                <StatusListTable arrayData={arrayData} employeeName={objEmployee.firstName + " " + objEmployee.lastName} siteName={objEmployee.siteName} onEdit={onEditStatusClick} isEdit={true} />
                {employeePinPopupOpen && <ResetPin
                    employeeID={employeeID}
                    closeDetails={(e) => {
                        if (employeeID == "") {
                            navigate(-1)
                        } else {
                            setEmployeePinPopupOpen(false)
                        }
                    }}
                    getData={getData}
                />}
                {imagePopupOpen && <ImagePopupOpen image={image} />}
                {employeeActivityPopupOpen &&
                    <EmployeeActivityManage
                        selectedDataId={selectedEmployeeActivity}
                        employeeID={objEmployee._id}
                        employeeName={objEmployee.firstName + " " + objEmployee.lastName}
                        closeDetails={(e) => {
                            setEmployeeActivityPopupOpen(false)
                            setSelectedEmployeeActivity("")
                            if (e) {
                                getData()
                            }
                        }} />}
            </Stack>
        </>
    )
}