import React, { useEffect, useState } from 'react';
import { Backdrop, Box, CircularProgress, Grid, MenuItem, Stack, Typography } from '@mui/material';
import { Popup } from 'components/Popup';
import { DefaultSelect } from 'ui/DefaultSelect';
import { DefaultButton } from 'ui/DefaultButton';
import { arrayStatus } from './data/ArrayData/status';
import { arrayOvertimeRate } from './data/ArrayData/overtimeRate';
import { enqueueSnackbar } from 'notistack';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { DefaultInput } from 'ui/DefaultInput';
import api from 'lib/axios';
import { ConfirmAlert } from 'CustomControls/confirmAlert copy';
import { useNavigate } from 'react-router';

export const EmployeeNew = ({ selectedDataID, closeDetails,getData }) => {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [email, setEmail] = useState(null);
    const [middleName, setMiddleName] = useState(null);
    const [firstName, setFirstName] = useState(null);
    const [hourlyPayRate, setHourlyPayRate] = useState(null);
    const [lastName, setLastName] = useState(null);
    const [selectedOvertimeRate, setSelectedOverTimeRate] = useState(null);
    const [selectedEmployeeStatus, setSelectedEmployeeStatus] = useState(null);
    const [selectedShift, setSelectedShift] = useState(null);
    const [deleteConfirmationShouldOpen, setDeleteConfirmationShouldOpen] = useState(false);
    const [arrayShift, setArrayShift] = useState([]);


    useEffect(() => {
        getShiftList()
    }, [selectedDataID]);

    const onClose = () => {
        closeDetails(false)
    };

    function getEmployeeDetailByID(arrayShift) {
        setIsLoading(true)
        api.get('/apis/Employees/getById/' + selectedDataID).then((res) => {
            if (res.data.success) {
                var resData = res.data.data
                console.log("resData", resData);
                var selectedStatus = arrayStatus.filter(status => status.id == resData.status)
                if (selectedStatus.length > 0) {
                    selectedStatus = selectedStatus[0]
                } else {
                    selectedStatus = null
                }

                var selectedOverTimeRate = arrayOvertimeRate.filter(status => status.id == resData.overTimeRate)
                if (selectedOverTimeRate.length > 0) {
                    selectedOverTimeRate = selectedOverTimeRate[0]
                } else {
                    selectedOverTimeRate = null
                }
                console.log("selectedShift", arrayShift)
                var selectedShift = arrayShift.filter(shift => shift._id == resData.shiftID)
                if (selectedShift.length > 0) {
                    selectedShift = selectedShift[0]
                } else {
                    selectedShift = null
                }
                setFirstName(resData.firstName);
                setMiddleName(resData.middleName);
                setLastName(resData.lastName);
                setEmail(resData.email);
                setHourlyPayRate(resData.hourlyPayRate);
                setSelectedOverTimeRate(selectedOverTimeRate);
                setSelectedEmployeeStatus(selectedStatus);
                setSelectedShift(selectedShift);
            }
            setIsLoading(false)
        }).catch(function (error) {
            console.log(error);
        });
    }

    async function apiCalling(values) {
        var data = {
            firstName: values.firstName,
            middleName: values.middleName,
            lastName: values.lastName,
            email: values.email,
            hourlyPayRate: values.hourlyPayRate,
            overTimeRate: values.selectedOvertimeRate.id,
            status: values.selectedEmployeeStatus.id,
            siteID: localStorage.getItem("siteID"),
            shiftID: values.selectedShift._id
        }
        console.log("selectedID", selectedDataID);
        if (selectedDataID != "") {
            return await api.put('/apis/Employees/updateById/' + selectedDataID, data)
        } else {
            return await api.post('/apis/Employees/add', data)
        }
    }

    function getShiftList() {
        setIsLoading(true)
        api.get(`/apis/companysites/ProductionShifts/${localStorage.getItem("siteID")}`).then((res) => {
            if (res.data.success) {
                var resData = res.data.data
                console.log("resdata", resData);
                setArrayShift(resData)
            }
            setIsLoading(false)
            if (selectedDataID != "") {
                getEmployeeDetailByID(res.data.data)
            }
        }).catch(function (error) {
            console.log(error);
        });
    }

    function deleteEmployee() {
        setIsLoading(true)
        api.delete('/apis/Employees/deleteById/' + selectedDataID).then((res) => {
            if (res.data.success) {
                enqueueSnackbar("Employee deleted", {
                    anchorOrigin: {
                        horizontal: 'right',
                        vertical: 'top'
                    },
                    variant: 'success'
                });
                // setEmployeePopupOpen(false)
                //getData()
                navigate(-1);
            } else {
                setDeleteConfirmationShouldOpen(false)
                enqueueSnackbar(res.data.message, {
                    anchorOrigin: {
                        horizontal: 'right',
                        vertical: 'top'
                    },
                    variant: 'error'
                });
            }
            setIsLoading(false)
        }).catch(function (error) {
            console.log(error);
        });
    }

    const updateConfirmAlert = (value) => {
        if (value) {
            deleteEmployee()
        } else {
            setDeleteConfirmationShouldOpen(false)
        }
    }

    return (
        <>
            <Popup
                sx={{
                    '.MuiSelect-select': {
                        pointerEvents: 'auto', // Makes sure pointer events are received
                        '&:hover': {
                            backgroundColor: 'action.hover', // Adds a background color on hover for better UI indication
                        }
                    },

                    '.MuiInputBase-input': {
                        pointerEvents: 'auto', // Ensures pointer events are handled correctly
                        '&:hover': {
                            backgroundColor: 'action.hover', // Adds a background color on hover for better UI indication
                        }
                    },

                    '.MuiSwitch-switchBase': {
                        pointerEvents: 'auto', // Ensures pointer events are handled correctly
                        '&:hover': {
                            backgroundColor: 'action.hover', // Adds a background color on hover for better UI indication
                        }
                    },


                    '.MuiButtonBase-root': {
                        pointerEvents: 'auto', // Ensures pointer events are handled correctly
                    },
                }}
                isOpened={true}
                width={700}
                bgcolor={'common.white'}
            >
                <Formik
                    enableReinitialize
                    initialValues={{
                        submit: null,
                        firstName: firstName,
                        middleName: middleName,
                        lastName: lastName,
                        email: email,
                        selectedOvertimeRate: selectedOvertimeRate,
                        hourlyPayRate: hourlyPayRate,
                        selectedEmployeeStatus: selectedEmployeeStatus,
                        selectedShift: selectedShift,
                    }}
                    validationSchema={Yup
                        .object()
                        .shape({
                            firstName: Yup.string().required('First name is required'),
                            middleName: Yup.string().required('Middle name is required'),
                            lastName: Yup.string().required('Last name is required'),
                            email: Yup.string().required('Email is required').email('Must be a valid email'),
                            selectedEmployeeStatus: Yup.object().required('Status is required'),
                            selectedOvertimeRate: Yup.object().required('Overtime rate is required'),
                            selectedShift: Yup.object().required('Shift is required'),
                            hourlyPayRate: Yup.string().required('Hourly pay rate is required'),
                        })}
                    onSubmit={async (values, { resetForm, setErrors, setStatus, setSubmitting }) => {
                        console.log("onsubmit")
                        try {
                            setIsLoading(true)
                            setFirstName(values.firstName)
                            setMiddleName(values.middleName)
                            setLastName(values.lastName)
                            setEmail(values.email)
                            setSelectedEmployeeStatus(values.selectedEmployeeStatus)
                            setSelectedOverTimeRate(values.selectedOvertimeRate)
                            setSelectedShift(values.selectedShift)
                            setHourlyPayRate(values.hourlyPayRate)
                            var data = await apiCalling(values)
                            setIsLoading(false)
                            if (data !== undefined && data.data.success) {
                                console.log("IF")
                                resetForm();
                                setStatus({ success: true });
                                setSubmitting(false);
                                enqueueSnackbar('Employee ' + (selectedDataID == "" ? "added" : "updated"), {
                                    anchorOrigin: {
                                        horizontal: 'right',
                                        vertical: 'top'
                                    },
                                    variant: 'success'
                                });
                                // setEmployeePopupOpen(false)
                                // getData()
                                closeDetails(true)
                            } else {
                                console.log("ELSE")
                                setStatus({ success: false });
                                setErrors({ submit: data.data.message });
                                setSubmitting(false);
                                enqueueSnackbar(data.data.message, {
                                    anchorOrigin: {
                                        horizontal: 'right',
                                        vertical: 'top'
                                    },
                                    variant: 'error'
                                });
                            }
                        } catch (err) {
                            console.log("error12", err);
                            setIsLoading(false)
                            setStatus({ success: false });
                            setErrors({ submit: err.message });
                            setSubmitting(false);
                        }

                    }}
                >
                    {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values, setFieldValue }) => (
                        <form
                            onSubmit={handleSubmit}
                            style={{ height: "100%" }}
                        >
                            < Stack px={3} pt={3} pb={2} spacing={2} height={'100%'}>
                                <Typography fontSize={20}>Employee</Typography>
                                <Box flexGrow={1}>
                                    <Grid container spacing={3}>
                                        <Grid item xs={4}>
                                            <DefaultInput
                                                label={'First Name'}
                                                placeholder={'E.g. xyz'}
                                                value={values.firstName}
                                                name={"firstName"}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                error={Boolean(touched.firstName && errors.firstName)}
                                                helperText={touched.firstName && errors.firstName}
                                            />
                                        </Grid>
                                        <Grid item xs={4}>
                                            <DefaultInput
                                                label={'Middle Name'}
                                                placeholder={'E.g. xyz'}
                                                value={values.middleName}
                                                name={"middleName"}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                error={Boolean(touched.middleName && errors.middleName)}
                                                helperText={touched.middleName && errors.middleName}
                                            />
                                        </Grid>
                                        <Grid item xs={4}>
                                            <DefaultInput
                                                label={'Last Name'}
                                                placeholder={'E.g. xyz'}
                                                value={values.lastName}
                                                name={"lastName"}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                error={Boolean(touched.lastName && errors.lastName)}
                                                helperText={touched.lastName && errors.lastName}
                                            />
                                        </Grid>
                                        <Grid item xs={4}>
                                            <DefaultInput
                                                label={'Email'}
                                                placeholder={'E.g. xyz'}
                                                value={values.email}
                                                name={"email"}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                error={Boolean(touched.email && errors.email)}
                                                helperText={touched.email && errors.email}
                                            />
                                        </Grid>
                                        <Grid item xs={4}>
                                            <DefaultInput
                                                label={'Hourly Pay Rate'}
                                                placeholder={'E.g. xyz'}
                                                value={values.hourlyPayRate}
                                                name={"hourlyPayRate"}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                error={Boolean(touched.hourlyPayRate && errors.hourlyPayRate)}
                                                helperText={touched.hourlyPayRate && errors.hourlyPayRate}
                                            />
                                        </Grid>
                                        <Grid item xs={4}>
                                            <DefaultSelect
                                                onBlur={handleBlur}
                                                onChange={(event, newValue) => {
                                                    values.selectedOvertimeRate = newValue
                                                    console.log("st", newValue)
                                                }}
                                                helperText={touched.selectedOvertimeRate && errors.selectedOvertimeRate && <p>Select overtime rate</p>}
                                                error={Boolean(touched.selectedOvertimeRate && errors.selectedOvertimeRate)}
                                                value={values.selectedOvertimeRate}
                                                options={arrayOvertimeRate}
                                                getOptionLabel={(option) => option.value}
                                                isOptionEqualToValue={(option, value) => value != null ? option._id === value._id : ""}
                                                name="selectedOvertimeRate"
                                                label={"Overtime Rate"}
                                                placeholder="Select Overtime Rate" />
                                        </Grid>
                                        <Grid item xs={4}>
                                            <DefaultSelect
                                                onBlur={handleBlur}
                                                onChange={(event, newValue) => {
                                                    values.selectedEmployeeStatus = newValue
                                                    console.log("st", newValue)
                                                }}
                                                helperText={touched.selectedEmployeeStatus && errors.selectedEmployeeStatus && <p>Select status</p>}
                                                error={Boolean(touched.selectedEmployeeStatus && errors.selectedEmployeeStatus)}
                                                value={values.selectedEmployeeStatus}
                                                options={arrayStatus}
                                                getOptionLabel={(option) => option.value}
                                                isOptionEqualToValue={(option, value) => value != null ? option._id === value._id : ""}
                                                label='Status'
                                                name="selectedEmployeeStatus"
                                                placeholder="Select Status" />
                                        </Grid>
                                        <Grid item xs={4}>
                                            {console.log("arrayshift", arrayShift)}
                                            <DefaultSelect
                                                onBlur={handleBlur}
                                                onChange={(event, newValue) => {
                                                    values.selectedShift = newValue
                                                    console.log("st", newValue)
                                                }}
                                                helperText={touched.selectedShift && errors.selectedShift && <p>Select shift</p>}
                                                error={Boolean(touched.selectedShift && errors.selectedShift)}
                                                value={values.selectedShift}
                                                options={arrayShift}
                                                getOptionLabel={(option) => option.name}
                                                isOptionEqualToValue={(option, value) => value != null ? option._id === value._id : ""}
                                                label='Shift'
                                                name="selectedShift"
                                                placeholder="Select Shift" />
                                        </Grid>
                                    </Grid>
                                </Box>
                                <Stack direction={'row'} spacing={1} alignItems={'flex-end'}>
                                    <Box flexGrow={1} />
                                    {selectedDataID != "" && localStorage.getItem("role") !== "Standard" &&
                                        <DefaultButton
                                            onClick={() => {
                                                setDeleteConfirmationShouldOpen(true)
                                            }}
                                            color={'error'}
                                        >
                                            Delete
                                        </DefaultButton>
                                    }
                                    <DefaultButton onClick={onClose}>Cancel</DefaultButton>
                                    <DefaultButton
                                        color={'success'}
                                        disabled={isSubmitting}
                                        type="submit">
                                        Save
                                    </DefaultButton>
                                </Stack>
                            </Stack>
                        </form>
                    )}
                </Formik>
                {
                    deleteConfirmationShouldOpen && (<ConfirmAlert title={"Confirmation"} body={"Are you sure you want to delete?"}
                        updateConfirmAlert={(e, value) => {
                            console.log(e, value)
                            setDeleteConfirmationShouldOpen(false)
                            updateConfirmAlert(value)
                        }} />)
                }
                <Backdrop style={{ zIndex: 1 }} open={isLoading} >
                    <CircularProgress color="inherit" />
                </Backdrop>
            </Popup >
        </>
    )
}