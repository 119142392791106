import React, { useEffect, useState } from 'react';
import { Backdrop, Box, ButtonBase, CircularProgress, Stack, Typography, FormHelperText, Checkbox } from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import { Formik } from 'formik';
import * as Yup from 'yup';
import api from 'lib/axios';
import { Datetimepicker } from 'ui/DateTimePicker';
import dayjs from "dayjs"

export const EmployeeActivityManage = ({ selectedDataId, employeeID, employeeName, closeDetails }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [ysnWorking, setYsnWorking] = useState(false);
    const [isCurrentWorking, setIsCurrentWorking] = useState(false);

    useEffect(() => {
        if (selectedDataId != "") {
            getData()
        }
    }, [selectedDataId]);

    function getData() {
        setIsLoading(true)
        api.get('/apis/EmployeesStatus/getById/' + selectedDataId).then((res) => {
            if (res.data.success) {
                var resData = res.data.data
                var startDate = resData.startTime ? dayjs(resData.startTime) : null
                var endDate = resData.endTime ? dayjs(resData.endTime) : null
                setStartDate(startDate)
                setEndDate(endDate)
                setYsnWorking(resData.endTime == null)
                setIsCurrentWorking(resData.endTime == null)
            }
            setIsLoading(false)
        }).catch(function (error) {
            console.log(error);
        });
    }

    const onClose = () => {
        closeDetails(false)
    };

    async function apiCalling(values) {
        var data = {
            startTime: values.startDate,
            endTime: values.endDate,
            employeeID: employeeID
        }
        if (selectedDataId != "") {
            return await api.put('/apis/EmployeesStatus/updateById/' + selectedDataId, data)
        } else {
            return await api.post('/apis/EmployeesStatus/add', data)
        }

    }

    function formateTotalDuration(startTime, endTime) {
        const start = new Date(startTime);
        const end = new Date(endTime);

        // Calculate the duration in milliseconds
        const durationMs = end - start;

        // Convert durationMs to hours and minutes
        const hours = Math.floor(durationMs / (1000 * 60 * 60));
        var minutes = Math.floor((durationMs / (1000 * 60)) % 60);

        minutes = minutes.toString().padStart(2, '0')
        return ((hours < 10 && hours >= 0) ? '0' : '') + hours + ":" + minutes
    }
    { console.log("******", employeeID) }
    return (
        <>
            <Formik
                enableReinitialize
                initialValues={{
                    submit: null,
                    startDate: startDate,
                    endDate: endDate,
                    ysnWorking: ysnWorking,
                }}
                validationSchema={Yup
                    .object()
                    .shape({
                        startDate: Yup.date().required('Time in is required').test('is-less-than-endDate', 'Time in must be before time out', function (value) {
                            const { ysnWorking, endDate } = this.parent;
                            if (ysnWorking) {
                                return true
                            }
                            return endDate > value;
                        }),
                        endDate: isCurrentWorking == false ? Yup.date().required('Time out is required') : '',
                    })}
                onSubmit={async (values, { resetForm, setErrors, setStatus, setSubmitting }) => {
                    console.log("onsubmit")
                    try {
                        setIsLoading(true)
                        setStartDate(values.startDate)
                        setEndDate(values.endDate)
                        setYsnWorking(values.ysnWorking)
                        var data = await apiCalling(values)
                        setIsLoading(false)
                        if (data !== undefined && data.data.success) {
                            setStatus({ success: true });
                            setSubmitting(false);
                            enqueueSnackbar('Activity ' + (selectedDataId == "" ? 'added' : 'updated'), {
                                anchorOrigin: {
                                    horizontal: 'right',
                                    vertical: 'top'
                                },
                                variant: 'success'
                            });
                            closeDetails(true)
                        } else {
                            setStatus({ success: false });
                            setErrors({ submit: data.data.message });
                            setSubmitting(false);
                            enqueueSnackbar(data.data.message, {
                                anchorOrigin: {
                                    horizontal: 'right',
                                    vertical: 'top'
                                },
                                variant: 'error'
                            });
                        }
                    } catch (err) {
                        console.log("error12", err);
                        setIsLoading(false)
                        setStatus({ success: false });
                        setErrors({ submit: err.message });
                        setSubmitting(false);
                    }

                }}
            >

                {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values, setFieldValue }) => (
                    <form
                        onSubmit={handleSubmit}
                        style={{ height: "100%" }}
                    >
                        <Stack
                            px={4}
                            pt={3}
                            pb={2}
                            gap={4}
                            overflowY={'auto'}
                            minWidth={'600px'}
                            height={'750px'}
                            maxHeight={'calc(100vh - 100px)'}
                            maxWidth={'600px'}
                            position={'fixed'}
                            zIndex={10}
                            sx={{
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                overflowY: 'auto',
                                borderRadius: '10px',
                                bgcolor: 'common.white',
                                border: '1px solid #D5D5D5',
                            }}
                        >
                            <Typography fontSize={18}>Activity Details</Typography>
                            <Box flex={1}>
                                <Stack gap={3}>
                                    <Stack direction={'row'} alignItems={"center"} maxWidth={500}>
                                        <Typography fontSize={13} fontWeight={'bold'} width="120px">Team Member</Typography>
                                        <Typography fontSize={13} fontWeight={'bold'}>{employeeName}</Typography>
                                    </Stack>
                                    <Stack direction={'row'} alignItems={"center"} maxWidth={500}>
                                        <Typography fontSize={13} fontWeight={'bold'} width="120px">Status</Typography>
                                        <Checkbox
                                            color="primary"
                                            onChange={(event) => {
                                                setFieldValue('ysnWorking', event.target.checked)
                                                if (event.target.checked) {
                                                    setFieldValue('endDate', null)
                                                    setIsCurrentWorking(true);
                                                } else {
                                                    setIsCurrentWorking(false);
                                                }
                                            }}
                                            checked={values.ysnWorking}
                                            sx={{ padding: "0px", margin: "0px" }}
                                        />
                                        <Typography fontSize={13} paddingLeft={"5px"}>Currently working</Typography>
                                    </Stack>
                                    <Stack direction={'row'} alignItems={"center"} gap={3} maxWidth={500}>
                                        <Typography fontSize={13} fontWeight={'bold'} width="120px">Time In</Typography>
                                        <Datetimepicker
                                            name="startDate"
                                            initialDate={values.startDate}
                                            handleDateChange={(e) => {
                                                setFieldValue('startDate', e)
                                            }}
                                            error={Boolean(touched.startDate && errors.startDate)}
                                            helperText={touched.startDate && errors.startDate}
                                        />
                                    </Stack>
                                    {touched.startDate && errors.startDate && (
                                        <FormHelperText style={{ color: 'red' }} sx={{ mt: -1, mb: 1, pl: "120px" }}>
                                            {errors.startDate}
                                        </FormHelperText>
                                    )}
                                    {!values.ysnWorking &&
                                        <Stack direction={'row'} alignItems={"center"} gap={3} maxWidth={500}>
                                            <Typography fontSize={13} fontWeight={'bold'} width="120px">Time Out</Typography>
                                            <Datetimepicker
                                                name="endDate"
                                                initialDate={values.endDate}
                                                handleDateChange={(e) => {
                                                    setFieldValue('endDate', e)
                                                }}
                                                error={Boolean(touched.endDate && errors.endDate)}
                                                helperText={touched.endDate && errors.endDate}
                                            />
                                        </Stack>}
                                    {touched.endDate && errors.endDate && (
                                        <FormHelperText style={{ color: 'red' }} sx={{ mt: -1, mb: 1, pl: "120px" }}>
                                            {errors.endDate}
                                        </FormHelperText>
                                    )}
                                    {values.endDate != null && <Stack direction={'row'} alignItems={"center"} maxWidth={500}>
                                        <Typography fontSize={13} fontWeight={'bold'} width="120px">Total Hours</Typography>
                                        <Typography fontSize={13} fontWeight={'bold'}>{formateTotalDuration(values.startDate, values.endDate)}</Typography>
                                    </Stack>}
                                </Stack>

                            </Box>
                            <Stack direction={'row'} gap={3} justifyContent={'space-between'}>
                                <Box></Box>
                                <Stack direction={'row'} gap={2}>
                                    <ButtonBase
                                        sx={{
                                            py: 1,
                                            px: 3,
                                            bgcolor: 'primary.main',
                                            borderRadius: '5px',
                                        }}
                                        onClick={onClose}
                                    >
                                        <Typography fontSize={14} fontWeight={700} color={'common.white'}>
                                            Cancel
                                        </Typography>
                                    </ButtonBase>
                                    <ButtonBase
                                        sx={{
                                            py: 1,
                                            px: 3,
                                            bgcolor: '#EFEFEF',
                                            borderRadius: '5px',
                                        }}
                                        disabled={isSubmitting}
                                        type="submit"
                                    >
                                        <Typography fontSize={14} fontWeight={700}>
                                            Save
                                        </Typography>
                                    </ButtonBase>
                                </Stack>
                            </Stack>
                        </Stack>
                    </form>
                )}
            </Formik>
            <Backdrop style={{ zIndex: 11 }} open={isLoading} >
                <CircularProgress color="inherit" />
            </Backdrop>
        </>
    )
}