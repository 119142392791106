import { Box, Stack, Typography } from '@mui/material';
import { ColorStatus } from 'ui/ColorStatus/index.js';
import { ArrowForwardIos } from '@mui/icons-material';

export const columns = [
    {
        field: 'name',
        headerName: 'Division Name',
        width: 200,
    },
    {
        field: 'contact',
        headerName: 'Division Contact',
        width: 200,
    },
    {
        field: 'region',
        headerName: 'Region',
        width: 200,
    },
    {
        field: 'country',
        headerName: 'Country',
        flex: 1,
    },
];

export const rows = [
    {
        id: 1,
        divisionName: "Test Division",
        divisionContact: '1235467890',
        region: 'Test Region',
        country: 'Test Country',
       
    },
    {
        id: 2,
        divisionName: "Test Division",
        divisionContact: '1235467890',
        region: 'Test Region',
        country: 'Test Country',
    },
    {
        id: 3,
        divisionName: "Test Division",
        divisionContact: '1235467890',
        region: 'Test Region',
        country: 'Test Country',
    },
    {
        id: 4,
        divisionName: "Test Division",
        divisionContact: '1235467890',
        region: 'Test Region',
        country: 'Test Country',
    },
    {
        id: 5,
        divisionName: "Test Division",
        divisionContact: '1235467890',
        region: 'Test Region',
        country: 'Test Country',
    },
];
