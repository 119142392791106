import React, { useEffect, useState, useMemo } from 'react';
import { Box, ButtonGroup, Button, ButtonBase, Grid, Stack, Typography, MenuItem, Backdrop, CircularProgress } from '@mui/material';
import { Popup } from 'components/Popup/index.js';
import { DefaultInput } from 'ui/DefaultInput/index.js';
import { useLoader } from 'contexts/LoaderContext';
import { enqueueSnackbar } from 'notistack';
import { DefaultButton } from 'ui/DefaultButton';
import { DefaultSelect } from 'ui/DefaultSelect';
import { getGatewayDataApi, manageGatewayApi, arrayType } from './TabletDeviceRepository';
import moment from 'moment';
import api from 'lib/axios';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { ConfirmAlert } from 'CustomControls/confirmAlert copy';

export const ManageTabletDevices = ({ tabletDeviceID, setIsOpenedTabletDeviceDetails, getData }) => {
    const { startLoading, stopLoading, openAlert } = useLoader();
    const [isLoading, setIsLoading] = useState(false);
    const [name, setName] = useState("");
    const [deviceID, setDeviceID] = useState("");
    const [model, setModel] = useState("");
    const [deleteConfirmationShouldOpen, setDeleteConfirmationShouldOpen] = useState(false);

    useEffect(() => {
        getTabletDeviceData();
    }, [tabletDeviceID]);

    function getTabletDeviceData() {
        setIsLoading(true)
        api.get('/apis/Devices/getById/' + tabletDeviceID).then((res) => {
            if (res.data.success) {
                var resData = res.data.data
                setName(resData.name)
                setDeviceID(resData.udid)
                setModel(resData.model)
            }
            setIsLoading(false)
        }).catch(function (error) {
            console.log(error);
        });
    }

    async function apiCalling(values) {
        var data = {
            name: values.name,
        }
        console.log("tabletDeviceID", tabletDeviceID);
        return await api.put('/apis/Devices/updateById/' + tabletDeviceID, data)
    }

    function deleteTabletDevice() {
        setIsLoading(true)
        api.delete('/apis/Devices/deleteById/' + tabletDeviceID).then((res) => {
            if (res.data.success) {
                enqueueSnackbar("TabletDevice deleted", {
                    anchorOrigin: {
                        horizontal: 'right',
                        vertical: 'top'
                    },
                    variant: 'success'
                });
                setIsOpenedTabletDeviceDetails(null)
                getData()
            } else {
                setDeleteConfirmationShouldOpen(false)
                enqueueSnackbar(res.data.message, {
                    anchorOrigin: {
                        horizontal: 'right',
                        vertical: 'top'
                    },
                    variant: 'error'
                });
            }
            setIsLoading(false)
        }).catch(function (error) {
            console.log(error);
        });
    }

    const updateConfirmAlert = (value) => {
        if (value) {
            deleteTabletDevice()
        } else {
            setDeleteConfirmationShouldOpen(false)
        }
    }
    return (
        <Popup
            isOpened
            width={700}
            bgcolor={'common.white'}
            sx={{
                bottom: 20,
                right: 20,
                border: '1px solid #DBDDE2',
                borderColor: '#DBDDE2',
                borderRadius: '10px',
            }}
        >
            <Formik
                enableReinitialize
                initialValues={{
                    submit: null,
                    name: name,
                    deviceID: deviceID,
                    model: model
                }}
                validationSchema={Yup
                    .object()
                    .shape({
                        name: Yup.string().required('Name is required'),
                    })}
                onSubmit={async (values, { resetForm, setErrors, setStatus, setSubmitting }) => {
                    console.log("values::", values)
                    try {
                        setIsLoading(true)
                        setName(values.name)
                        setDeviceID(values.deviceID)
                        setModel(values.model)
                        var data = await apiCalling(values)
                        setIsLoading(false)
                        if (data !== undefined && data.data.success) {
                            setStatus({ success: true });
                            setSubmitting(false);
                            enqueueSnackbar('TabletDevice updated', {
                                anchorOrigin: {
                                    horizontal: 'right',
                                    vertical: 'top'
                                },
                                variant: 'success'
                            });
                            setIsOpenedTabletDeviceDetails(null)
                            getData()
                        } else {
                            setStatus({ success: false });
                            setErrors({ submit: data.data.message });
                            setSubmitting(false);
                            enqueueSnackbar(data.data.message, {
                                anchorOrigin: {
                                    horizontal: 'right',
                                    vertical: 'top'
                                },
                                variant: 'error'
                            });
                        }
                    } catch (err) {
                        console.error(err);
                        setStatus({ success: false });
                        setErrors({ submit: err.message });
                        setSubmitting(false);
                    }
                }}
            >
                {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values, setFieldValue }) => (
                    <form
                        onSubmit={handleSubmit}
                        style={{ height: "100%" }}
                    >
                        <Stack px={3} pt={3} pb={3} gap={'32px'} height={'100%'} alignItems={'flex-start'}>
                            <Typography fontSize={20}>Tablet Device Details</Typography>
                            <Box width={'100%'}>
                                <Grid spacing={3} container>
                                    <Grid item xs={4}>
                                        <DefaultInput
                                            label={'Name'}
                                            placeholder={'E.g. xyz'}
                                            value={values.name}
                                            name={"name"}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            error={Boolean(touched.name && errors.name)}
                                            helperText={touched.name && errors.name}
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <DefaultInput
                                            readOnly={true}
                                            label={'Device ID'}
                                            placeholder={'E.g. 123456'}
                                            value={values.deviceID}
                                            name={"deviceID"}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            error={Boolean(touched.deviceID && errors.deviceID)}
                                            helperText={touched.deviceID && errors.deviceID}
                                        />
                                    </Grid>
                                    <Grid item xs={4} />
                                    <Grid item xs={4} >
                                        <DefaultInput
                                            readOnly={true}
                                            label={'Model'}
                                            placeholder={'E.g. 123456'}
                                            value={values.model}
                                            name={"model"}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            error={Boolean(touched.model && errors.model)}
                                            helperText={touched.model && errors.model}
                                        />
                                    </Grid>
                                    {/* <Grid item xs={8} >
                                        <DefaultSelect
                                            onBlur={handleBlur}
                                            onChange={(event, newValue) => {
                                                values.selectedType = newValue
                                            }}
                                            helperText={touched.selectedType && errors.selectedType && <p>Select Type</p>}
                                            error={Boolean(touched.selectedType && errors.selectedType)}
                                            value={values.selectedType}
                                            options={arrayType}
                                            getOptionLabel={(option) => option}
                                            isOptionEqualToValue={(option, value) => value != null ? option._id === value._id : ""}
                                            name="selectedType"
                                            label={"Type"}
                                            placeholder="Select Type" />
                                    </Grid>
                                    <Grid item xs={4} />
                                    <Grid item xs={8}>
                                        <DefaultInput
                                            label={'Location'}
                                            placeholder={'E.g. 123456'}
                                            value={values.location}
                                            name={"location"}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            error={Boolean(touched.location && errors.location)}
                                            helperText={touched.location && errors.location}
                                        />
                                    </Grid> */}
                                </Grid>
                            </Box>
                            <Box height={'1px'} width={'80%'} border={'1px solid #DBDDE2'} />
                            {/* <Box width={'100%'}>
                                <Grid spacing={3} container>
                                    <Grid item xs={4} >
                                        <DefaultInput
                                            label={'Data Topics'}
                                            placeholder={'E.g. 123456'}
                                            value={values.dataTopic}
                                            name={"dataTopic"}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            error={Boolean(touched.dataTopic && errors.dataTopic)}
                                            helperText={touched.dataTopic && errors.dataTopic}
                                        />
                                    </Grid>
                                    <Grid item xs={4} >
                                        <DefaultInput
                                            label={'Heartbeat Topic'}
                                            placeholder={'E.g. 123456'}
                                            value={values.heartBeatTopic}
                                            name={"heartBeatTopic"}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            error={Boolean(touched.heartBeatTopic && errors.heartBeatTopic)}
                                            helperText={touched.heartBeatTopic && errors.heartBeatTopic}
                                        />
                                    </Grid>
                                </Grid>
                            </Box> */}
                            <Stack direction={'row'} spacing={1} mt={'auto'} width={'100%'}>
                                <Box flexGrow={1}>
                                    <DefaultButton 
                                        onClick={() => {
                                            setDeleteConfirmationShouldOpen(true)
                                        }}
                                        color={'error'}
                                    >
                                        Delete
                                    </DefaultButton>
                                </Box>
                                <DefaultButton onClick={() => setIsOpenedTabletDeviceDetails(null)}>Cancel</DefaultButton>
                                <DefaultButton
                                    color={'success'}
                                    disabled={isSubmitting}
                                    type="submit"
                                >
                                    Save
                                </DefaultButton>
                            </Stack>
                        </Stack>
                    </form>
                )}
            </Formik>
            {
                deleteConfirmationShouldOpen && (<ConfirmAlert title={"Confirmation"} body={"Are you sure you want to delete?"}
                    updateConfirmAlert={(e, value) => {
                        console.log(e, value)
                        setDeleteConfirmationShouldOpen(false)
                        updateConfirmAlert(value)
                    }} />)
            }
            <Backdrop style={{ zIndex: 1 }} open={isLoading} >
                <CircularProgress color="inherit" />
            </Backdrop>
        </Popup>
    );
};