import React, { createContext, useContext, useState } from 'react';
import Loader from 'ui/Loader.jsx';

const LoaderContext = createContext();

export const useLoader = () => useContext(LoaderContext);

export const LoaderProvider = ({ children }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [isAlertOpen, setIsAlertOpen] = useState(false);

    const startLoading = () => {
        // console.log("Loading:START")
        setIsLoading(true);
    };

    const stopLoading = () => {
        // console.log("Loading:STOP")
        setIsLoading(false);
    };
    const openAlert = () => {
        // console.log("Loading:START")
        setIsAlertOpen(true);
    };

    const closeAlert = () => {
        // console.log("Loading:STOP")
        setIsAlertOpen(false);
    };

    return (
        <LoaderContext.Provider value={{ isLoading, startLoading, stopLoading,isAlertOpen, openAlert,closeAlert }}>
            {children}
            <Loader />
        </LoaderContext.Provider>
    );
};
