import React from 'react';
import { Box } from '@mui/material';
import { getSiteStatusColor } from 'modules/Dashboard/data/status';

export const ColorStatus = ({ code, size = 10 }) => {
    return (
        <Box
            sx={{
                width: size,
                height: size,
                borderRadius: '50%',
                backgroundColor: getSiteStatusColor(code),
            }}
        />
    );
};
