import React, { useEffect, useState, useMemo } from 'react';
import { Box, ButtonGroup, Button, ButtonBase, Grid, Stack, Typography, MenuItem, Backdrop, CircularProgress, } from '@mui/material';
import { Popup } from 'components/Popup/index.js';
import { DefaultInput } from 'ui/DefaultInput/index.js';
import { useLoader } from 'contexts/LoaderContext';
import { enqueueSnackbar } from 'notistack';
import { DefaultButton } from 'ui/DefaultButton';
import { DefaultSelect } from 'ui/DefaultSelect';
import moment from 'moment';
import { Formik } from 'formik';
import * as Yup from 'yup';
import api from 'lib/axios';
import { ConfirmAlert } from 'CustomControls/confirmAlert copy';

export const ManageDivision = ({ divisionID, setIsOpenedDivisionDetails, getData }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [divisionName, setDivisionName] = useState("");
    const [region, setRegion] = useState("");
    const [divisionContact, setDivisionContact] = useState("");
    const [country, setCountry] = useState("");
    const [deleteConfirmationShouldOpen, setDeleteConfirmationShouldOpen] = useState(false);

    useEffect(() => {
        if (divisionID != "0") {
            getDivisionData()
        }
    }, [divisionID]);

    function getDivisionData() {
        setIsLoading(true)
        api.get('/apis/Divisions/getById/' + divisionID).then((res) => {
            if (res.data.success) {
                var resData = res.data.data
                setDivisionName(resData.name)
                setDivisionContact(resData.contact)
                setRegion(resData.region)
                setCountry(resData.country)
            }
            setIsLoading(false)
        }).catch(function (error) {
            console.log(error);
        });
    }

    async function apiCalling(values) {
        var data = {
            name: values.divisionName,
            contact: values.divisionContact,
            region: values.region,
            country: values.country,
        }
        console.log("divisionID", divisionID);
        if (divisionID != "0") {
            return await api.put('/apis/Divisions/updateById/' + divisionID, data)
        } else {
            return await api.post('/apis/Divisions/add', data)
        }
    }

    function deleteDivision() {
        setIsLoading(true)
        api.delete('/apis/Divisions/deleteById/' + divisionID).then((res) => {
            if (res.data.success) {
                enqueueSnackbar("Division deleted", {
                    anchorOrigin: {
                        horizontal: 'right',
                        vertical: 'top'
                    },
                    variant: 'success'
                });
                setIsOpenedDivisionDetails(null)
                getData()
            } else {
                setDeleteConfirmationShouldOpen(false)
                enqueueSnackbar(res.data.message, {
                    anchorOrigin: {
                        horizontal: 'right',
                        vertical: 'top'
                    },
                    variant: 'error'
                });
            }
            setIsLoading(false)
        }).catch(function (error) {
            console.log(error);
        });
    }

    const updateConfirmAlert = (value) => {
        if (value) {
            deleteDivision()
        } else {
            setDeleteConfirmationShouldOpen(false)
        }
    }
    return (
        <Popup
            isOpened
            width={700}
            bgcolor={'common.white'}
            sx={{
                bottom: 20,
                right: 20,
                border: '1px solid #DBDDE2',
                borderColor: '#DBDDE2',
                borderRadius: '10px',
            }}
        >
            <Formik
                enableReinitialize
                initialValues={{
                    submit: null,
                    divisionName: divisionName,
                    region: region,
                    divisionContact: divisionContact,
                    country: country,
                }}
                validationSchema={Yup
                    .object()
                    .shape({
                        divisionName: Yup.string().required('Division name is required'),
                        region: Yup.string().required('Region is required'),
                        divisionContact: Yup.string().required('Division contact is required'),
                        country: Yup.string().required('Country is required'),
                    })}
                onSubmit={async (values, { resetForm, setErrors, setStatus, setSubmitting }) => {
                    console.log("values::", values)
                    try {
                        setIsLoading(true)
                        setDivisionName(values.divisionName)
                        setRegion(values.region)
                        setCountry(values.country)
                        setDivisionContact(values.divisionContact)
                        var data = await apiCalling(values)
                        setIsLoading(false)
                        if (data !== undefined && data.data.success) {
                            setStatus({ success: true });
                            setSubmitting(false);
                            enqueueSnackbar('Division ' + (divisionID == "0" ? "added" : "updated"), {
                                anchorOrigin: {
                                    horizontal: 'right',
                                    vertical: 'top'
                                },
                                variant: 'success'
                            });
                            setIsOpenedDivisionDetails(null)
                            getData()
                        } else {
                            setStatus({ success: false });
                            setErrors({ submit: data.data.message });
                            setSubmitting(false);
                            enqueueSnackbar(data.data.message, {
                                anchorOrigin: {
                                    horizontal: 'right',
                                    vertical: 'top'
                                },
                                variant: 'error'
                            });
                        }
                    } catch (err) {
                        console.error(err);
                        setStatus({ success: false });
                        setErrors({ submit: err.message });
                        setSubmitting(false);
                    }
                }}
            >
                {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values, setFieldValue }) => (
                    <form
                        onSubmit={handleSubmit}
                        style={{ height: "100%" }}
                    >
                        <Stack px={3} pt={3} pb={3} gap={'32px'} height={'100%'} alignItems={'flex-start'}>
                            <Typography fontSize={20}>Tablet Device Details</Typography>
                            <Box width={'100%'}>
                                <Grid spacing={3} container>
                                    <Grid item xs={4}>
                                        <DefaultInput
                                            label={'Division Name'}
                                            placeholder={'E.g. xyz'}
                                            value={values.divisionName}
                                            name={"divisionName"}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            error={Boolean(touched.divisionName && errors.divisionName)}
                                            helperText={touched.divisionName && errors.divisionName}
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <DefaultInput
                                            label={'Division Contact'}
                                            placeholder={'E.g. xyz'}
                                            value={values.divisionContact}
                                            name={"divisionContact"}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            error={Boolean(touched.divisionContact && errors.divisionContact)}
                                            helperText={touched.divisionContact && errors.divisionContact}
                                        />
                                    </Grid>
                                    <Grid item xs={4} />
                                    <Grid item xs={4} >
                                        <DefaultInput
                                            label={'Region'}
                                            placeholder={'E.g. xyz'}
                                            value={values.region}
                                            name={"region"}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            error={Boolean(touched.region && errors.region)}
                                            helperText={touched.region && errors.region}
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <DefaultInput
                                            label={'Country'}
                                            placeholder={'E.g. xyz'}
                                            value={values.country}
                                            name={"country"}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            error={Boolean(touched.country && errors.country)}
                                            helperText={touched.country && errors.country}
                                        />
                                    </Grid>
                                </Grid>
                            </Box>
                            <Stack direction={'row'} spacing={1} mt={'auto'} width={'100%'}>
                                {/* <Box flexGrow={1}>
                                    {localStorage.getItem("role") !== "Standard" && divisionID.id != "0" && (<DefaultButton onClick={() => {
                                        openAlert()
                                    }} color={'error'}>
                                        Delete
                                    </DefaultButton>)}
                                </Box> */}
                                <Box flexGrow={1}>
                                    {divisionID != "0" && (
                                        <DefaultButton
                                            onClick={() => {
                                                setDeleteConfirmationShouldOpen(true)
                                            }}
                                            color={'error'}
                                        >
                                            Delete
                                        </DefaultButton>)}
                                </Box>
                                <DefaultButton onClick={() => setIsOpenedDivisionDetails(null)}>Cancel</DefaultButton>
                                <DefaultButton
                                    color={'success'}
                                    disabled={isSubmitting}
                                    type="submit"
                                >
                                    Save
                                </DefaultButton>
                            </Stack>
                        </Stack>
                    </form>
                )}
            </Formik>
            {
                deleteConfirmationShouldOpen && (<ConfirmAlert title={"Confirmation"} body={"Are you sure you want to delete?"}
                    updateConfirmAlert={(e, value) => {
                        console.log(e, value)
                        setDeleteConfirmationShouldOpen(false)
                        updateConfirmAlert(value)
                    }} />)
            }
            <Backdrop style={{ zIndex: 1 }} open={isLoading} >
                <CircularProgress color="inherit" />
            </Backdrop>
        </Popup>
    );
}