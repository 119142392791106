import React, { useState } from 'react';
import { Header } from 'components/Header';
import { Sidebar } from 'components/Sidebar';
import { SnackbarProvider } from 'notistack';
import routes from './routes';
import useAuth from './hooks/useAuth';
import SplashScreen from './components/SplashScreen';
import { useRoutes, useLocation } from 'react-router-dom';
import { LoaderProvider } from 'contexts/LoaderContext';
import ProfileUpdate from 'components/Header/ProfileUpdate';

export const App = () => {
    const location = useLocation();
    const [isOpenedUserDetails, setIsOpenedUserDetails] = useState(false);

    const content = useRoutes(routes);
    const auth = useAuth();
    return (
        <LoaderProvider>
            <div className={'app'}>
                {!location.pathname.includes('authentication') && !location.pathname.includes('print') && <Sidebar />}
                <div className={'app-inner'}>
                    {!location.pathname.includes('authentication') && !location.pathname.includes('print') && <Header setIsOpenedUserDetails={setIsOpenedUserDetails} />}
                    <main className={'main'}>
                        <SnackbarProvider
                            dense
                            maxSnack={3}
                        >
                            {auth.isInitialized ? content : <SplashScreen />}
                        </SnackbarProvider>
                        {isOpenedUserDetails == true && <ProfileUpdate userID={isOpenedUserDetails} setIsOpenedUserDetails={setIsOpenedUserDetails} />}
                    </main>
                </div>
            </div>
        </LoaderProvider>
    );
};
