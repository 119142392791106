// Importing necessary components and libraries
import { Stack, Typography } from '@mui/material';
import { ColorStatus } from 'ui/ColorStatus/index.js';
import api from 'lib/axios.js';
import { enqueueSnackbar } from 'notistack';
import { arrayRole } from 'modules/Settings/data/role';

// Define column configuration for users table
export const columnsUsers = [
    {
        field: 'firstName',
        headerName: 'First Name',
        width: 200,
        renderCell: (params) => {
            return params.value ? params.value : '-';
        }
    },
    {
        field: 'lastName',
        headerName: 'Last Name',
        width: 200,
        renderCell: (params) => {
            return params.value ? params.value : '-';
        }
    },
    {
        field: 'status',
        headerName: 'Status',
        width: 150,
        // Custom cell renderer for status column
        renderCell: ({ value: { value, code } }) => (
            <Stack width={'100%'} direction={'row'} justifyContent={'space-between'} alignItems={'center'} spacing={1} pr={4}>
                <Typography>{value}</Typography>
                <ColorStatus code={code} />
            </Stack>
        ),
    },
    {
        field: 'mobile',
        headerName: 'Mobile',
        width: 200,
        renderCell: (params) => {
            return params.value ? params.value : '-';
        }
    },
    {
        field: 'email',
        headerName: 'Email',
        width: 200,
        renderCell: (params) => {
            return params.value ? params.value : '-';
        }
    },
    {
        field: 'role',
        headerName: 'Role',
        flex: 1,
        renderCell: ({ value }) => (
            <Stack width={'100%'} direction={'row'} justifyContent={'space-between'} alignItems={'center'} spacing={1} pr={2}>
                <Typography fontSize={13}>{arrayRole.find(role => role.id == value)?.value}</Typography>
            </Stack>
        ),
    },
];

// Function to fetch users data from API
export const getUsersApi = async () => {
    try {
        const apiResponse = await api.post('/apis/companyUsers/bySite', { siteID: localStorage.getItem("siteID"), companyID: localStorage.getItem("publicID") });
        if (apiResponse.data.success) {
            // Map the response data to required format
            var resData = apiResponse.data.data;
            return resData.map((obj) => {
                return {
                    ...obj,
                    id: obj._id,
                    status: {
                        value: obj.isActive === "1" ? 'Active' : 'Disable',
                        code: obj.isActive,
                    },
                };
            });
        }
        return null;
    } catch (error) {
        console.log(error);
        return null;
    }
};

// Function to fetch user data by ID from API
export const getUserDataApi = async (userID) => {
    try {
        const apiResponse = await api.post('/apis/companyUsers/byID', { id: userID });
        if (apiResponse.data.success) {
            return apiResponse.data.data;
        }
        return null;
    } catch (error) {
        console.log(error);
        return null;
    }
};

// Function to manage user data in API (create/update/delete)
export const manageUserApi = async (userID, jsonData) => {
    try {
        const apiResponse = await api(`/apis/companyUsers${userID ? `/${userID}` : ''}`, {
            data: jsonData,
            method: jsonData ? userID ? "PUT" : "POST" : "DELETE"
        });
        if (apiResponse.data.success && apiResponse.data.success == 1) {
            enqueueSnackbar('User ' + (jsonData ? userID ? "updated" : "added" : "delete"), {
                anchorOrigin: {
                    horizontal: 'right',
                    vertical: 'top'
                },
                variant: 'success'
            });
            return apiResponse.data.data;
        } else {
            enqueueSnackbar(apiResponse.data.message, {
                anchorOrigin: {
                    horizontal: 'right',
                    vertical: 'top'
                },
                variant: 'error'
            });
        }
        return null;
    } catch (error) {
        console.log(error);
        return null;
    }
};

// Function to fetch user data by ID from API
export const getMainUserDetail = async (userID) => {
    try {
        var isAdmin = localStorage.getItem("role") === "SuperAdmin"
        var url = `/apis/${isAdmin ? 'companies' : 'companyUsers'}/byID`
        const apiResponse = await api.post(url, { id: userID });
        if (apiResponse.data.success) {
            var user = apiResponse.data.data;
            return {
                ...(isAdmin && {
                    firstName: user.contact,
                    mobile: user.telephone,
                }),


                role: user.role,
                email: user.email,
                profileURL: user.profileInfo ? user.profileInfo.path : "",
                ...(!isAdmin && {
                    firstName: user.firstName,
                    lastName: user.lastName,
                    mobile: user.mobile,

                }),

            }
        }
        return null;
    } catch (error) {
        console.log(error);
        return null;
    }
};

export const updateMainUserApi = async (formData) => {
    try {
        let url = localStorage.getItem("role") === "SuperAdmin" ? '/apis/companies/updateProfile?companyID=' + localStorage.getItem("publicID") : '/apis/companyUsers/updateProfile?companyID=' + localStorage.getItem("publicID") + '&userID=' + localStorage.getItem("userID")

        const apiResponse = await api.post(url, formData);
        if (apiResponse.data.success) {
            return apiResponse.data.data;
        }
        return null;
    } catch (error) {
        console.log(error);
        return null;
    }
};

export const getSiteDetailByIDApi = async () => {
    try {
        const apiResponse = await api.post('/apis/companysites/byID', { id: localStorage.getItem("siteID") });
        if (apiResponse.data.success) {
            return apiResponse.data.data;
        }
    } catch (error) {
        console.log(error);
        return null;
    }
};
