import React, { useCallback, useState, useEffect } from 'react';
import { ArrowIcon } from 'assets/icons/ArrowIcon';
import { SupportIcon } from 'assets/icons/SupportIcon';
import logo from 'assets/images/logo.svg';

import { SidebarItem } from 'components/Sidebar/SidebarItem';
import { theme } from 'core/theme/theme';
import { Box, FormControl, MenuItem, Select, Stack, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { mainMenu, systemMenu } from './data/menu';
import api from 'lib/axios';

export const Sidebar = () => {
    const [arraySites, setArraySites] = useState([]);
    const handleChange = (event) => {
        localStorage.setItem("siteID", event.target.value)
        let siteSelect = arraySites.find((s) => s._id + "" === event.target.value)
        localStorage.setItem("timeZone", siteSelect.timeZone)
        localStorage.setItem("assetTrackingEnabled", siteSelect.assetTrackingEnabled)
        window.location.href = "/"
    };

    const getSites = useCallback(async () => {
        try {
            const res = await api.get('/apis/companysites/byCompany/')

            if (res.data.success) {
                var data = res.data.data
                if (localStorage.getItem("role") === "Standard") {
                    data = data.filter((s => s._id === localStorage.getItem("siteID")))
                }
                if (!localStorage.getItem("timeZone") || localStorage.getItem("timeZone") === undefined || localStorage.getItem("timeZone") === "") {
                    localStorage.setItem("timeZone", data[0].timeZone)
                }

                if (!localStorage.getItem("siteID") || localStorage.getItem("siteID") === undefined || localStorage.getItem("siteID") === "") {
                    localStorage.setItem("siteID", data[0]._id)
                    window.location.href = "/"
                }
                setArraySites(data);
            }
        } catch (err) {
            console.log(err);
        }
    }, []);

    useEffect(() => {
        console.log("ROLE:", localStorage.getItem("role"))
        getSites();

    }, []);

    return (
        <Stack
            component={'aside'}
            width={220}
            flexShrink={0}
            bgcolor={'grey.300'}
            boxShadow={theme.shadows[1]}
            position={'relative'}
            zIndex={11}
            sx={{
                overflowY: 'auto',
                '&::-webkit-scrollbar': {
                    width: '4px',
                },
                '&::-webkit-scrollbar-thumb': {
                    width: '4px',
                    bgcolor: 'grey.400',
                },
            }}
        >
            {localStorage.getItem("role") !== "Standard" && <Box p={3}>
                <Box
                    mb={1.5}
                    sx={{
                        img: {
                            width: '100%',
                            objectFit: 'contain',
                        },
                    }}
                >
                    <Link to={'/'}>
                        <img src={logo} alt='logo' />
                    </Link>
                </Box>
                <Typography mb={4} fontSize={13} whiteSpace={'nowrap'}>
                    Smart Time Tracking
                </Typography>
                <FormControl
                    variant='filled'
                    fullWidth
                    hiddenLabel
                    sx={{
                        mb: 1,
                        '.MuiSelect-filled': {
                            bgcolor: 'primary.main',
                            borderRadius: '5px',
                            py: '10px',
                            pl: '15px',
                            color: 'common.white',
                            fontWeight: 700,
                            '&:focus': {
                                bgcolor: 'primary.main',
                                borderRadius: '5px',
                            },
                        },
                        '.MuiFilledInput-root::before, .MuiFilledInput-root::after': {
                            display: 'none',
                        },
                    }}
                >
                    <Select
                        sx={{
                            '& .MuiSelect-icon': {
                                top: 'calc(50% - 4px)',
                                right: '12px',
                                stroke: theme.palette.common.white,
                            },
                        }}
                        value={localStorage.getItem("siteID")}
                        onChange={handleChange}
                        IconComponent={ArrowIcon}
                        inputProps={{ readOnly: localStorage.getItem("role") == "2" ? true : false }}
                    >
                        {
                            arraySites.map((site) => (<MenuItem key={site._id} value={site._id + ""}>{site.name}</MenuItem>))
                        }
                    </Select>
                </FormControl>
                <Typography mb={1} fontSize={13} color={'grey.blue'}>
                    Select Site
                </Typography>
            </Box>}
            <Box mb={3}>
                {mainMenu.map((item) => (
                    <SidebarItem key={item.title} data={item} />
                ))}
            </Box>
            <Stack spacing={3}>
                <Box px={3}>
                    <Typography fontSize={13} textTransform={'uppercase'} fontWeight={700}>
                        SYSTEM
                    </Typography>
                </Box>
                <Box>
                    {systemMenu.map((item) => (
                        <SidebarItem key={item.title} data={item} />
                    ))}
                </Box>
            </Stack>
            <Box px={3} py={2} mt={'auto'}>
                <Box mb={2} bgcolor={'grey.light'} height={'1px'} />
                <Typography fontSize={13} textTransform={'uppercase'} fontWeight={700}>
                    HELP & SUPPORT
                </Typography>
            </Box>
            <SidebarItem
                data={{
                    title: 'Support',
                    link: '/support',
                    icon: <SupportIcon />,
                }}
            />
        </Stack>
    );
};
