import React, { useEffect, useState } from 'react';
import { Grid, Box, Stack, Typography, Backdrop, CircularProgress } from '@mui/material';
import { useLoader } from 'contexts/LoaderContext';
import { ConfigurationIcon } from 'assets/icons/ConfigurationIcon';
import ManageSite from './ManageSite';
import { getSiteDetailByIDApi } from '../Users/UsersRepository';
import api from 'lib/axios';



export const SettingsSiteDetailsTab = ({ isOpenedSiteDetails, setIsOpenedSiteDetails }) => {
    const { startLoading, stopLoading } = useLoader();
    const [arrayDivision, setArrayDivision] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [siteData, setSiteData] = useState({
        addressOne: "",
        addressTwo: "",
        city: "",
        companyID: "",
        country: "",
        timeZone: "",
        email: "",
        isActive: "",
        managerName: "",
        name: "",
        regionName: "",
        state: "",
        telephone: "",
        zipCode: "",
        _id: "",
        zones: ""
    });
    useEffect(() => {
        if (isOpenedSiteDetails === "") {
            getData()
            setIsOpenedSiteDetails(null)
        }

    }, [isOpenedSiteDetails]);

    useEffect(() => {
        getData()
    }, []);

    function getData() {
        setIsLoading(true)
        api.post('/apis/companysites/byID', { id: localStorage.getItem("siteID") }).then((res) => {
            if (res.data.success) {
                var resData = res.data.data
                setSiteData(resData.companySiteData)
                getDivision()
            }
        }).catch(function (error) {
            console.log(error);
        });
    }

    function getDivision() {
        api.get('/apis/Divisions/getAll').then((res) => {
            if (res.data.success) {
                var arrayDivision = res.data.data
                setArrayDivision(arrayDivision)
            }
            setIsLoading(false)
        }).catch(function (error) {
            console.log(error);
        });
    }

    return (
        <Stack width={'100%'} height={'100%'}>
            <Box bgcolor={'#F6F6F7'} p={2} border={'1px solid #DBDDE2'} borderTop={0} mt={-1}>
                <Box flexGrow={1}>
                    <Stack my={1} direction={'row'} alignItems={'center'} spacing={2}>
                        <Typography fontWeight={700}>Company Site Details</Typography>
                        <ConfigurationIcon />
                    </Stack>
                    <Grid container spacing={2}>
                        <Grid item xs={1.5}>
                            <Stack spacing={1}>
                                <Typography color={'#A8A8A7'}>Manager Name</Typography>
                                <Typography>{siteData.managerName ? siteData.managerName : "-"}</Typography>
                            </Stack>
                        </Grid>
                        <Grid item xs={1.5}>
                            <Stack spacing={1}>
                                <Typography color={'#A8A8A7'}>Phone</Typography>
                                <Typography>{siteData.telephone ? siteData.telephone : "-"}</Typography>
                            </Stack>
                        </Grid>
                        {/* <Grid item xs={1.5}>
                            <Stack spacing={1}>
                                <Typography color={'#A8A8A7'}>Region</Typography>
                                <Typography>{siteData.regionName? siteData.regionName: "-"}</Typography>
                            </Stack>
                        </Grid> */}
                        <Grid item xs={1.5}>
                            <Stack spacing={1}>
                                <Typography color={'#A8A8A7'}>Timezone</Typography>
                                <Typography>{siteData.timeZone ? siteData.timeZone : "-"}</Typography>
                            </Stack>
                        </Grid>
                        <Grid item >
                            <Stack spacing={1}>
                                <Typography color={'#A8A8A7'}>Division</Typography>
                                <Typography>{arrayDivision.find(division => division._id == siteData.devisionID) ? arrayDivision.find(division => division._id == siteData.devisionID)?.name : "-"}</Typography>
                            </Stack>
                        </Grid>
                        <Grid item xs={1.5}>
                            <Stack spacing={1}>
                                <Typography color={'#A8A8A7'}>Email</Typography>
                                <Typography>{siteData.email ? siteData.email : "-"}</Typography>
                            </Stack>
                        </Grid>
                    </Grid>
                </Box>
                <Box flexGrow={1}>
                    <Stack mt={2} mb={1} direction={'row'} alignItems={'center'} spacing={2}>
                        <Typography fontWeight={700}>Address Details</Typography>
                    </Stack>
                    <Grid container spacing={2}>
                        <Grid item xs={1.5}>
                            <Stack spacing={1}>
                                <Typography color={'#A8A8A7'}>Address One</Typography>
                                <Typography>{siteData.addressOne ? siteData.addressOne : "-"}</Typography>
                            </Stack>
                        </Grid>
                        <Grid item xs={1.5}>
                            <Stack spacing={1}>
                                <Typography color={'#A8A8A7'}>Address Two</Typography>
                                <Typography>{siteData.addressTwo ? siteData.addressTwo : "-"}</Typography>
                            </Stack>
                        </Grid>
                        <Grid item xs={1.5}>
                            <Stack spacing={1}>

                                <Typography color={'#A8A8A7'}>City</Typography>
                                <Typography>{siteData.city ? siteData.city : "-"}</Typography>
                            </Stack>
                        </Grid>
                        <Grid item xs={1.5}>
                            <Stack spacing={1}>
                                <Typography color={'#A8A8A7'}>State</Typography>
                                <Typography>{siteData.state ? siteData.state : "-"}</Typography>
                            </Stack>
                        </Grid>
                        <Grid item>
                            <Stack spacing={1}>
                                <Typography color={'#A8A8A7'}>Zip Code</Typography>
                                <Typography>{siteData.zipCode ? siteData.zipCode : "-"}</Typography>
                            </Stack>
                        </Grid>
                    </Grid>
                </Box>

            </Box>

            {isOpenedSiteDetails != null && <ManageSite siteID={isOpenedSiteDetails} setIsOpenedSiteDetails={setIsOpenedSiteDetails} arrayDivision={arrayDivision} />}
            <Backdrop style={{ zIndex: 1 }} open={isLoading} >
                <CircularProgress color="inherit" />
            </Backdrop>
        </Stack>
    );
};
