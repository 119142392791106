import api from 'lib/axios.js';
import { Box, Stack, Typography } from '@mui/material';
import { ColorStatus } from 'ui/ColorStatus/index.js';
import { ArrowForwardIos } from '@mui/icons-material';
import { arrayStatus } from 'modules/Dashboard/data/status';

export const getEmployeesList = async () => {
    try {
        const apiResponse = await api.post('/apis/Employees/getAll',{ siteID: localStorage.getItem("siteID") })
        if (apiResponse.data.success) {
            return apiResponse.data.data
        }
        return null
    } catch (error) {
        console.log(error);
        return null
    }
}

export const getSiteDetailByIDApi = async () => {
    try {
        const apiResponse = await api.post('/apis/Employees/getById/', { id: localStorage.getItem("publicID") });
        if (apiResponse.data.success) {
            return apiResponse.data.data;
        }
    } catch (error) {
        console.log(error);
        return null;
    }
};