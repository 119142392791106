import { useState } from 'react';
import { Grid, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Avatar } from '@mui/material';
import { DefaultButton } from 'ui/DefaultButton';
import { useLoader } from 'contexts/LoaderContext';
import { Box, Stack } from '@mui/system';
import { Close } from '@mui/icons-material';

export const ImagePopupOpen = ({ image }) => {
    const { isAlertOpen, closeAlert } = useLoader();

    const handleClose = () => {
        closeAlert();
    };

    return (
        <Dialog open={isAlertOpen} onClose={() => handleClose(false)}>
            <DialogContent sx={{ display: 'grid', placeItems: 'center' }}>
                <Stack width={'100%'} direction={'row'} justifyContent={'space-between'} alignItems={'center'} mt={-1}>
                    <Box />
                    <Close
                    sx={{
                        width: 30,
                        height: 30,
                    }}
                        onClick={() => {
                            handleClose(false)
                        }}
                    />
                </Stack>
                <Stack width={450} >
                    <img
                        src={image ? image : ""}
                        style={{
                            width: '450px',
                            height: '450px',
                            color: "primary.main",
                            backgroundColor: '#DCDCDC',
                        }}
                    >
                    </img>
                </Stack>
            </DialogContent>
        </Dialog >

    );
};