import React, { useEffect, useState } from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { SearchInput } from 'components/SearchInput/index.js';
import { DefaultDataGrid } from 'ui/DefaultDataGrid/index.js';
import { columnsUsers, getUsersApi, manageUserApi } from './UsersRepository';
import { useLoader } from 'contexts/LoaderContext';
import ManageUser from './ManageUser';
import useConfirmAlert from 'CustomControls/confirmAlert';



export const SettingsUsersTab = ({ isOpenedUserDetails, setIsOpenedUserDetails }) => {
    const { startLoading, stopLoading } = useLoader();
    const [baseArray, setBaseArray] = useState([]);
    const [rowsUsers, setRowsUsers] = useState([]);
    const [headerData, setHeaderData] = useState({
        totalEnrolledDevices: 0,
        activeUsers: 0,
        disabled: 0,
        adminUsers: 0,
        standardUsers: 0,
    });
    useEffect(() => {
        if (isOpenedUserDetails === "") {
            getData()
            setIsOpenedUserDetails(null)
        }

    }, [isOpenedUserDetails]);

    useEffect(() => {
        getData()
    }, []);

    useEffect(() => {
        filterBySearch()
    }, [baseArray]);

    const getData = async () => {
        startLoading()
        var resData = await getUsersApi()
        if (resData) {
            let totalActive = resData.filter(({ isActive }) => isActive === "1").length
            let totalAdmin = resData.filter(({ role }) => role === "1").length
            setBaseArray(resData)
            setHeaderData({
                totalEnrolledDevices: resData.length,
                activeUsers: totalActive,
                disabled: resData.length - totalActive,
                adminUsers: totalAdmin,
                standardUsers: resData.length - totalAdmin,
            })
        }
        stopLoading()
    }
    const filterBySearch = (searchKeyword) => {
        const searchColumns = ['firstName', 'lastName','mobile','email'];
        setRowsUsers(baseArray.filter(obj =>
            searchColumns.some(column =>
                searchKeyword ? obj[column].toLowerCase().includes(searchKeyword.toLowerCase()) : true
            )
        ));
    }

    const onSuccess = async () => {
        startLoading()
        var resData = await manageUserApi(isOpenedUserDetails, null)
        if (resData) {
            setIsOpenedUserDetails(null)
            getData()
        }
        stopLoading()
    };

    return (
        <Stack width={'100%'} height={'100%'}>
            <Box bgcolor={'#F6F6F7'} p={2} border={'1px solid #DBDDE2'} borderTop={0} mt={-1}>
                <Typography mb={2} fontWeight={700}>
                    User Manager
                </Typography>
                <Stack direction={'row'} spacing={3} alignItems={'flex-end'} justifyContent={'space-between'}>
                    <Stack direction={'row'} spacing={6}>
                        <Stack spacing={2}>
                            <Typography color={'#A0A3A6'}>Total Enrolled</Typography>
                            <Stack direction={'row'} spacing={2} alignItems={'baseline'}>
                                <Typography fontSize={30} fontWeight={700}>
                                    {headerData.totalEnrolledDevices}
                                </Typography>
                                <Typography>Devices</Typography>
                            </Stack>
                        </Stack>
                        <Stack spacing={2}>
                            <Typography color={'#A0A3A6'}>Active Users</Typography>
                            <Typography fontSize={30} fontWeight={700}>
                                {headerData.activeUsers}
                            </Typography>
                        </Stack>
                        <Stack spacing={2}>
                            <Typography color={'#A0A3A6'}>Disabled</Typography>
                            <Typography fontSize={30} fontWeight={700}>
                                {headerData.disabled}
                            </Typography>
                        </Stack>
                        <Box width={'1px'} bgcolor={'#DBDDE2'} />
                        <Stack spacing={2}>
                            <Typography color={'#A0A3A6'}>Super Admin</Typography>
                            <Typography fontSize={30} fontWeight={700}>
                                {headerData.adminUsers}
                            </Typography>
                        </Stack>
                        <Stack spacing={2}>
                            <Typography color={'#A0A3A6'}>Site Manager</Typography>
                            <Typography fontSize={30} fontWeight={700}>
                                {headerData.standardUsers}
                            </Typography>
                        </Stack>
                    </Stack>
                    <SearchInput
                        handleSubmit={(e) => filterBySearch(e.target.value)}
                        sx={{
                            border: '1px solid #DBDDE2',
                        }}
                    />
                </Stack>
            </Box>
            <Box flex={1} border={'1px solid #DBDDE2'} mt={'-1px'}>
                <DefaultDataGrid columns={columnsUsers} rows={rowsUsers} onRowClick={(e) => {
                    if (localStorage.getItem("role") !== "Standard") {
                        setIsOpenedUserDetails(e.row._id)
                    }
                }} />
            </Box>
            {isOpenedUserDetails != null && <ManageUser userID={isOpenedUserDetails} setIsOpenedUserDetails={setIsOpenedUserDetails} getData={getData}/>}
            {useConfirmAlert(
                'Delete user?',
                'Are you sure you want to delete this user?',
                onSuccess,
                () => { }
            )}
        </Stack>
    );
};
