import React, { useEffect, useState } from 'react';
import { DefaultDataGrid } from 'ui/DefaultDataGrid';
import { Backdrop, Box, ButtonBase, CircularProgress, Stack } from '@mui/material';
import { columns } from './data/employee';
import { enqueueSnackbar } from "notistack"
import { Datepicker } from 'ui/Datepicker';
import api from 'lib/axios';
import dayjs from "dayjs";
import { useNavigate } from 'react-router-dom';

export const Reporting = () => {
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(false);
    const [arrayEmployee, setArrayEmployee] = useState([]);
    const [arraySelectedEmployee, setArraySelectedEmployee] = useState([]);
    const [startDate, setStartDate] = useState(dayjs());
    const [endDate, setEndDate] = useState(dayjs().add(1, 'day'));
    const [arrayReportData, setArrayReportData] = useState([]);

    useEffect(() => {
        getData()
    }, []);

    function getData() {
        setIsLoading(true)
        api.post('/apis/Employees/all', {
            siteID: localStorage.getItem("siteID"),
        }).then((res) => {
            if (res.data.success) {
                var resData = res.data.data
                setArrayEmployee(resData)
            }
            setIsLoading(false)
        }).catch(function (error) {
            console.log(error);
        });
    }

    const handleStartDateChange = (date) => {
        const isStartDateAfterEndDate = date.isAfter(endDate);
        const isStartDateEqualToEndDate = date.isSame(endDate, 'day');
        if (isStartDateAfterEndDate && !isStartDateEqualToEndDate) {
            enqueueSnackbar("Start date must be before end date", {
                anchorOrigin: {
                    horizontal: 'right',
                    vertical: 'top'
                },
                variant: 'error'
            });
        }
        // else {
        setStartDate(date)
        // }
    };

    const handleEndDateChange = (date) => {
        const isEndDateAfterStartDate = date.isAfter(startDate);
        const isStartDateEqualToEndDate = date.isSame(startDate, 'day');
        if (!isEndDateAfterStartDate && !isStartDateEqualToEndDate) {
            enqueueSnackbar("End date must be after start date", {
                anchorOrigin: {
                    horizontal: 'right',
                    vertical: 'top'
                },
                variant: 'error'
            });
        }
        // else {
        setEndDate(date)
        // }
    };

    function getReportData() {
        setIsLoading(true)
        var array = arraySelectedEmployee.map(obj => obj._id)
        var data = {
            startDate: startDate.format('MM-DD-YYYY'),
            endDate: endDate.format('MM-DD-YYYY'),
            arrayEmployee: array
        }
        api.post('/apis/Employees/getReport', data).then((res) => {
            if (res.data.success) {
                var resData = res.data.data
                setArrayReportData(resData)
                navigate('/reporting/ReportingDetails', { state: { arrayData: resData } });
            } else {
                enqueueSnackbar(res.data.message, {
                    anchorOrigin: {
                        horizontal: 'right',
                        vertical: 'top'
                    },
                    variant: 'error'
                });
            }
            setIsLoading(false)
        }).catch(function (error) {
            console.log(error);
        });
    }

    const onGenerateReport = () => {
        const isStartDateAfterEndDate = startDate.isAfter(endDate);

        if (isStartDateAfterEndDate) {
            enqueueSnackbar("Start date must be before end date", {
                anchorOrigin: {
                    horizontal: 'right',
                    vertical: 'top'
                },
                variant: 'error'
            });
        } else if (arraySelectedEmployee.length == 0) {
            enqueueSnackbar("Please select employee", {
                anchorOrigin: {
                    horizontal: 'right',
                    vertical: 'top'
                },
                variant: 'error'
            });
        } else {
            getReportData()
        }
    };

    function onCheck(data) {
        if (arraySelectedEmployee.some(obj => obj._id == data._id)) {
            setArraySelectedEmployee(arraySelectedEmployee.filter(obj => obj._id != data._id))
        } else {
            setArraySelectedEmployee((prevState) => {
                const newArr = [...prevState];
                newArr.push(data);
                return newArr;
            });
        }
    }

    const handleHeaderClick = () => {
        if (arraySelectedEmployee.length === arrayEmployee.length) {
            setArraySelectedEmployee([]);
        } else {
            setArraySelectedEmployee(arrayEmployee);
        }
    };
    
    return (
        <Stack p={2.5} width={'100%'}>
            <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'} >
                <Stack direction={'row'} gap={2}>
                    <Datepicker
                        initialDate={startDate}
                        handleDateChange={handleStartDateChange}
                    />
                    <Datepicker
                        initialDate={endDate}
                        handleDateChange={handleEndDateChange}
                    />
                </Stack>
                <ButtonBase
                    type={'button'}
                    bgcolor={'blue.light'}
                    onClick={() => {
                        onGenerateReport()
                    }}
                    sx={{
                        minWidth: 100,
                        textTransform: 'none',
                        borderRadius: '5px',
                        height: '40px',
                        border: '0px solid black',
                        backgroundColor: 'primary.main',
                        fontSize: "13px",
                        fontWeight: "normal",
                        color: 'common.white',
                        '&:hover': {
                            bgcolor: 'primary.main',
                        },
                    }}
                >
                    Report
                </ButtonBase>
            </Stack>

            <Box mt={2} flex={1} border={'1px solid'} borderColor={'grey.light'} borderRadius={'5px'} overflow={'hidden'}>
                <DefaultDataGrid
                    columns={columns(arraySelectedEmployee,arrayEmployee, onCheck, handleHeaderClick)}
                    rows={arrayEmployee}
                    onRowClick={({ id }) => {
                        console.log("id", id);
                    }}
                />
            </Box>

            <Backdrop style={{ zIndex: 1 }} open={isLoading} >
                <CircularProgress color="inherit" />
            </Backdrop>
        </Stack>
    )
}