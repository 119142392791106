import React, { useContext, useEffect, useState } from 'react';
import { Title } from 'ui/Title';
import { Box, Button, Stack, TextField, Typography } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import showPasswordIcon from 'assets/icons/show-password.svg';
import hidePasswordIcon from 'assets/icons/hide-password.svg';
import { Link } from 'react-router-dom';
import logo from 'assets/images/logo.svg';
import AuthContext from 'contexts/JWTContext';
import queryString from 'query-string';
import { useSnackbar } from 'notistack';

export const ResetPassword = () => {
    const navigate = useNavigate();
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const authContext = useContext(AuthContext);
    const [loading, setLoading] = useState(false);
    const [confirmPassword, setConfirmPassword] = useState('');
    const [password, setPassword] = useState('');
    const { enqueueSnackbar } = useSnackbar()
    const location = useLocation();
    const queryParams = queryString.parse(location.search);
    useEffect(() => {
        console.log("queryParams", queryParams.token)
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const validationData = validateFormData();
            if (validationData) {
                enqueueSnackbar(validationData, {
                    anchorOrigin: {
                        horizontal: 'right',
                        vertical: 'top'
                    },
                    variant: 'error'
                });
                return;
            }
            setLoading(true);
            let data = await authContext.forgotResetPassword(queryParams.token, confirmPassword)
            console.log("data::", data, "|:|", data !== undefined && data.success)
            setLoading(false);
            if (data !== undefined && data.success) {
                navigate("/authentication/login")
                enqueueSnackbar(data.message, {
                    anchorOrigin: {
                        horizontal: 'right',
                        vertical: 'top'
                    },
                    variant: 'success'
                });
            } else {
                enqueueSnackbar(data.message, {
                    anchorOrigin: {
                        horizontal: 'right',
                        vertical: 'top'
                    },
                    variant: 'error'
                });
            }
        } catch (err) {
            console.error(err);
            setLoading(false);
        }
    };

    const validateFormData = () => {
        if (password !== confirmPassword) {
            return "Uh-oh! The password and confirm password do not match. Please double-check.";
        } else {
            return null;
        }
    };

    return (
        <>

            <Stack alignItems={'center'} justifyContent={'center'} bgcolor={'grey.300'} py={10} px={4} width={'100%'}>
                <Stack alignItems={'center'} justifyContent={'center'} maxWidth={350} mx={'auto'}>
                    <Box width={340} height={48} src={logo} component={'img'} mb={1.5} />
                    <Typography mb={6}>Smart Time Tracking</Typography>
                    <Title mb={2}>Reset Your Password?</Title>
                    <Typography mb={3}>Hi! Welcome back, lets get to work.</Typography>
                    <Box component={'form'} onSubmit={handleSubmit} width={'100%'}>
                        <Stack spacing={1.5} width={'100%'}>

                            <Stack direction={'column'} width={'100%'}>
                                <Typography mb={1.5} color={'grey.blue'}>
                                    Password
                                </Typography>
                                <Box position={'relative'}>
                                    <Box
                                        sx={{
                                            position: 'absolute',
                                            top: '50%',
                                            right: '30px',
                                            transform: 'translateY(-50%)',
                                            cursor: 'pointer',
                                            zIndex: 1,
                                            width: '24px',
                                            height: '24px',
                                            objectFit: 'contain',
                                        }}
                                        required
                                        component={'img'}
                                        src={showPassword ? hidePasswordIcon : showPasswordIcon}
                                        onClick={() => setShowPassword((prevState) => !prevState)}
                                    />
                                    <TextField
                                        sx={{
                                            '& .MuiInputBase-input': {
                                                lineHeight: '5',
                                                padding: '17px 60px 17px 25px',
                                                borderRadius: '10px',
                                                background: 'white',
                                                border: '1px solid #E1E3E8',
                                                '&::placeholder': {
                                                    position: 'absolute',
                                                    top: '50%',
                                                    transform: 'translateY(calc(-50% + 2px))',
                                                },
                                            },
                                        }}
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                        type={showPassword ? 'text' : 'password'}
                                        placeholder='*************************'
                                    />
                                </Box>
                            </Stack>
                            <Stack direction={'column'} width={'100%'}>
                                <Typography mb={1.5} color={'grey.blue'}>
                                    Confirm Password
                                </Typography>
                                <Box position={'relative'}>
                                    <Box
                                        sx={{
                                            position: 'absolute',
                                            top: '50%',
                                            right: '30px',
                                            transform: 'translateY(-50%)',
                                            cursor: 'pointer',
                                            zIndex: 1,
                                            width: '24px',
                                            height: '24px',
                                            objectFit: 'contain',
                                        }}
                                        component={'img'}

                                        src={showConfirmPassword ? hidePasswordIcon : showPasswordIcon}
                                        onClick={() => setShowConfirmPassword((prevState) => !prevState)}
                                    />
                                    <TextField
                                        sx={{
                                            '& .MuiInputBase-input': {
                                                lineHeight: '5',
                                                padding: '17px 60px 17px 25px',
                                                borderRadius: '10px',
                                                background: 'white',
                                                border: '1px solid #E1E3E8',
                                                '&::placeholder': {
                                                    position: 'absolute',
                                                    top: '50%',
                                                    transform: 'translateY(calc(-50% + 2px))',
                                                },
                                            },
                                        }}
                                        value={confirmPassword}
                                        required
                                        onChange={(e) => setConfirmPassword(e.target.value)}
                                        type={showConfirmPassword ? 'text' : 'password'}

                                        placeholder='*************************'
                                    />
                                </Box>
                            </Stack>
                            <Button
                                sx={{
                                    fontSize: 20,
                                    py: 1.2,
                                    borderRadius: '30px',
                                    maxWidth: 300,
                                    width: '100%',
                                    alignSelf: 'center',
                                    bgcolor: 'secondary.main',
                                    textTransform: 'capitalize',
                                    '&:hover': {
                                        bgcolor: 'secondary.dark',
                                    }
                                }}
                                type={'submit'}
                            >
                                Submit
                            </Button>
                        </Stack>
                    </Box>
                    <Stack width='100%' justifyContent={'center'} alignItems={'center'} direction={'row'} spacing={1} my={4}>
                        <Box
                            sx={{
                                height: '1px',
                                width: '20%',
                                bgcolor: 'grey.light',
                            }}
                        />
                        <Typography
                            sx={{
                                whiteSpace: 'nowrap',
                            }}
                        >
                            Have an account?
                        </Typography>
                        <Box
                            sx={{
                                height: '1px',
                                width: '20%',
                                bgcolor: 'grey.light',
                            }}
                        />
                    </Stack>
                    <Link to={'/authentication/login'}>
                        <Typography
                            sx={{
                                textDecoration: 'underline',
                                fontSize: 20,
                                fontWeight: 700,
                                textTransform: 'uppercase',
                                '&:hover': {
                                    textDecoration: 'none',
                                },
                            }}
                        >
                            Log In
                        </Typography>
                    </Link>
                    <Stack spacing={1.5} mt={4}>
                        <Typography>Version 2.0.00</Typography>
                        <Typography>iKronosSync</Typography>
                    </Stack>
                </Stack>
            </Stack>
        </>
    );
};
