import { createContext, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';

import React from 'react';
import moment from 'moment';
import { useCookies } from 'react-cookie';
import { createBrowserHistory } from 'history';
import axios from 'axios';




const initialState = {
  formType: "LOGIN",
  isAuthenticated: false, //localStorage.getItem("isLogin") != "" && localStorage.getItem("isLogin") == "true",
  isInitialized: false,
  isAuthorized: false,
  isExpired: false,
  regex: { regex: "", validationInfo: "" },
  user: null //localStorage.getItem("isLogin") != "" && localStorage.getItem("isLogin") == "true" ? {} : null
};
const setSession = (accessToken) => {
  // console.log("Set Token", accessToken)
  if (accessToken) {
    localStorage.setItem('accessToken', accessToken);
    console.log("get Token", localStorage.getItem('accessToken'))
  } else {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('isLogin')
    localStorage.clear()
  }
};

const handlers = {
  INITIALIZE: (state, action) => {
    const { formType, isAuthenticated, isAuthorized, isExpired, regex, user } = action.payload;
    console.log("Dispatch INITIALIZE:", state, action)
    return {
      ...state,
      formType: formType,
      isAuthenticated,
      isAuthorized,
      isExpired: isExpired,
      regex: regex,
      isInitialized: true,
      user
    };
  },
  LOGIN: (state, action) => {
    const { formType, user } = action.payload;
    console.log("Dispatch LOGIN:", state, action)
    return {
      ...state,
      formType: formType,
      isAuthenticated: true,
      isAuthorized: false,
      isExpired: false,
      user
    };
  },
  FORGOT: (state, action) => {
    const { formType, isAuthenticated, isAuthorized, isExpired, regex, user } = action.payload;
    console.log("Dispatch FORGOT:", state, action)
    return {
      ...state,
      formType: formType,
      isAuthenticated,
      isAuthorized,
      isExpired: isExpired,
      regex: regex,
      isInitialized: true,
      user
    };
  },
  AUTH: (state, action) => {
    const { formType, user } = action.payload;
    console.log("Dispatch AUTH:", state, action)
    return {
      ...state,
      formType: formType,
      isAuthenticated: true,
      isAuthorized: true,
      isExpired: false,
      user
    };
  },
  LOGOUT: (state) => ({
    ...state,
    formType: "LOGIN",
    isAuthenticated: false,
    isAuthorized: false,
    isExpired: false,
    user: null
  }),
  REGISTER: (state, action) => {
    const { formType, user } = action.payload;

    return {
      ...state,
      formType: formType,
      isAuthenticated: true,
      isAuthorized: false,
      user
    };
  }
};

const reducer = (state, action) => (handlers[action.type]
  ? handlers[action.type](state, action)
  : state);

const AuthContext = createContext({
  ...initialState,
  platform: 'JWT',
  login: () => Promise.resolve(),
  guestLogin: () => Promise.resolve(),
  auth: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  register: () => Promise.resolve(),
  resetPassword: () => Promise.resolve(),
  checkEmail: () => Promise.resolve(),
  openForgot: () => Promise.resolve(),
  changePassword: () => Promise.resolve(),
  forgotResetPassword: () => Promise.resolve(),
  forgotPassword: () => Promise.resolve(),
});

export const AuthProvider = (props) => {
  const { children } = props;
  const [state, dispatch] = useReducer(reducer, initialState);
  const [cookies, setCookie] = useCookies(['verifiedDevices']);

  useEffect(() => {
    const initialize = async () => {
      const loginUrl = ["/authentication/login"]
      let myhistory = createBrowserHistory();
      console.log("path", myhistory.location.pathname)
      if (!loginUrl.includes(myhistory.location.pathname)) {
        try {
          const accessToken = localStorage.getItem('accessToken');

          if (accessToken != null && accessToken != undefined && accessToken != "") {
            console.log("INITIAL AUTH PROVIDER", accessToken)
            dispatch({
              type: 'INITIALIZE',
              payload: {
                formType: "",//valid user
                isAuthenticated: true,
                isAuthorized: true,
                isExpired: false,
                regex: "",
                user: null
              }
            });
          } else {
            dispatch({
              type: 'INITIALIZE',
              payload: {
                formType: "LOGIN",
                isAuthenticated: false,
                isAuthorized: false,
                user: null
              }
            });
          }
        } catch (err) {
          // console.log("fail catch", err.response.status)
          console.error(err);

          dispatch({
            type: 'INITIALIZE',
            payload: {
              formType: "LOGIN",
              isAuthenticated: false,
              isExpired: true,
              user: null
            }
          });

        }
      } else {
        dispatch({
          type: 'INITIALIZE',
          payload: {
            formType: "LOGIN",
            isAuthenticated: false,
            isAuthorized: false,
            isExpired: true,
            user: null
          }
        });
      }
    };

    initialize();
  }, []);

  const login = async (email, password) => {

    const res = await axios.post('/apis/CompanyLogin', {
      "email": email,
      "password": password
    });
    var data = res.data
    if (data != undefined && data.success) {
      var userLoginData = data.data
      if (userLoginData.user) {
        var user = userLoginData.user
        // console.log("Token After Login", userLoginData.token)
        setSession(userLoginData.token);

        localStorage.setItem("authID", userLoginData.authID)
        localStorage.setItem("authType", userLoginData.authType)
        localStorage.setItem("role", user.role)
        localStorage.setItem("email", user.email)
        localStorage.setItem("companyName", user.companyName)
        if (user.profileInfo) {
          localStorage.setItem("profilePath", user.profileInfo.path)
        } else {
          localStorage.setItem("profilePath", "")
        }
        localStorage.setItem("isLogin", "true")
        if (user.role == "SuperAdmin") {
          localStorage.setItem("fullName", user.contact)
          localStorage.setItem("publicID", user._id)
        } else {
          localStorage.setItem("fullName", user.firstName + " " + user.lastName)
          localStorage.setItem("publicID", user.companyID)
          localStorage.setItem("siteID", user.siteID)
          localStorage.setItem("customerID", user.customerID)
          localStorage.setItem("userID", user._id)
        }
        dispatch({
          type: 'INITIALIZE',
          payload: {
            formType: "",
            isAuthenticated: true,
            isAuthorized: true,
            isExpired: false,
            user
          }
        });
      }
      return data;
    } else {
      console.log("auth fail")
      return data;

    }
  };

  const guestLogin = async (purchaseOrder, accessCode) => {

    const res = await axios.post('/apis/CompanyLogin/GuestAccess', {
      "purchaseOrder": purchaseOrder,
      "accessCode": accessCode
    });
    var data = res.data
    if (data != undefined && data.success) {
      var userLoginData = data.data
      setSession(userLoginData.token);
      localStorage.setItem("fullName", userLoginData.purchaseOrder)
      localStorage.setItem("isLogin", "true")
      localStorage.setItem("isGuestLogin", "1")

      dispatch({
        type: 'INITIALIZE',
        payload: {
          formType: "",
          isAuthenticated: true,
          isAuthorized: true,
          isExpired: false,
          userLoginData
        }
      });

      return data;
    } else {
      console.log("auth fail")
      return data;

    }
  };


  const openForgot = async () => {
    console.log("openForgot CONTEXT:")
    dispatch({
      type: 'LOGIN',
      payload: {
        formType: "FORGOT",
        auth
      }
    });
  }
  const forgotPassword = async (email) => {

    const res = await axios.post('/apis/CompanyLogin/forgotPassword', {
      "email": email
    });
    var data = res.data
    console.log("forgotEmailCheck data", data)
    if (data != undefined && data.success) {
      var auth = data.data

      // localStorage.setItem("authID", auth._id)
      // localStorage.setItem("authType", auth.type)
      localStorage.setItem("email", email)
      // localStorage.setItem("otpExpiresIn", auth.otpExpiresIn)
      console.log("forgotEmailCheck CONTEXT:", auth)
      dispatch({
        type: 'FORGOT',
        payload: {
          formType: "AUTH",
          auth: data
        }
      });
      return data;
    } else {
      console.log("forgotEmailCheck fail", data)
      return data;
    }
  };
  const checkEmail = async (email) => {

    const res = await axios.post('/apis/CompanyLogin/forgotEmailCheck', {
      "email": email
    });
    var data = res.data
    console.log("forgotEmailCheck data", data)
    if (data != undefined && data.success) {
      var auth = data.data

      localStorage.setItem("authID", auth._id)
      localStorage.setItem("authType", auth.type)
      localStorage.setItem("email", email)
      localStorage.setItem("otpExpiresIn", auth.otpExpiresIn)
      console.log("forgotEmailCheck CONTEXT:", auth)
      dispatch({
        type: 'FORGOT',
        payload: {
          formType: "AUTH",
          auth
        }
      });
      return auth;
    } else {
      console.log("forgotEmailCheck fail", data)
      return data;
    }
  };
  const changePassword = async () => {

    const res = await axios.post('/apis/CompanyLogin/changePassAuth', {
      "email": localStorage.getItem("email")
    });
    var data = res.data
    console.log("changePassword data", data)
    if (data != undefined && data.success) {
      var auth = data.data

      localStorage.setItem("authID", auth._id)
      localStorage.setItem("authType", auth.type)
      localStorage.setItem("otpExpiresIn", auth.otpExpiresIn)
      console.log("changePassword CONTEXT:", auth)
      dispatch({
        type: 'FORGOT',
        payload: {
          formType: "AUTH",
          auth
        }
      });
      return auth;
    } else {
      console.log("forgotEmailCheck fail", data)
      return data;
    }
  };
  const auth = async (verificationCode, isForResend) => {
    var data = {}
    if (isForResend) {
      const res = await axios.post('/apis/CompanyLogin/resendOTP', {
        "authID": localStorage.getItem("authID"),
        "email": localStorage.getItem("email"),
        "password": localStorage.getItem("password"),
      });
      data = res.data
      console.log("isForResend data", data)
      if (data != undefined && data.success) {
        console.log("RESENT OTP", data.data)
        localStorage.setItem("otpExpiresIn", data.data.otpExpiresIn)
        return data;
      }
    }
    const res = await axios.post('/apis/CompanyLogin/verifyOtp', {
      "authID": localStorage.getItem("authID"),
      "otp": verificationCode
    });
    data = res.data
    console.log("auth data", data, "TYPE", data.data.type)
    //Vefiry OTP
    if (data != undefined && data.success) {
      switch (data.data.type) {
        case "FORGOT":
          console.log("Forgot data", data.data.regex)
          dispatch({
            type: 'FORGOT',
            payload: {
              formType: "FORGOTRESET",
              isAuthenticated: false,
              isAuthorized: true,
              isExpired: true,
              regex: data.data,
              user: null
            }
          });
          break;
        case "CHANGEPASSWORD":
          console.log("CHANGEPASSWORD data", data.data.regex)
          dispatch({
            type: 'FORGOT',
            payload: {
              formType: "CHANGEPASSWORD",
              isAuthenticated: false,
              isAuthorized: true,
              isExpired: true,
              regex: data.data,
              user: null
            }
          });
          break;
        case "RESET":
          console.log("RESET data", data.data.regex)
          dispatch({
            type: 'INITIALIZE',
            payload: {
              formType: "RESET",
              isAuthenticated: false,
              isAuthorized: false,
              isExpired: true,
              regex: data.data,
              user: null
            }
          });
          break;
        case "LOGIN":
          var user = data.data.user
          setSession(data.data.token);
          localStorage.setItem("role", user.role)
          localStorage.setItem("email", user.email)
          if (user.profileInfo) {
            localStorage.setItem("profilePath", user.profileInfo.path)
          } else {
            localStorage.setItem("profilePath", "")
          }
          localStorage.setItem("isLogin", "true")
          if (user.role == "SuperAdmin") {
            localStorage.setItem("fullName", user.contact)
            localStorage.setItem("publicID", user._id)
          } else {
            localStorage.setItem("fullName", user.firstName + " " + user.lastName)
            localStorage.setItem("publicID", user.companyID)
            localStorage.setItem("userID", user._id)
            localStorage.setItem("customerID", user.customerID)
          }
          setCookie(user.email, user._id, {
            path: '/',
            maxAge: (60 * 60 * 24 * 31),
            expiresIn: moment().add(1, 'month').toDate()
          })
          console.log("cookies:", cookies.verifiedDevices)
          dispatch({
            type: 'AUTH',
            payload: {
              formType: "",
              user
            }
          });
          break;
        default:
          break;
      }

    } else {
      console.log("auth fail", data)
      return data;
    }



  };
  const forgotResetPassword = async (token, newPassword) => {
    const res = await axios.post('/apis/CompanyLogin/changePassword/', {
      newPassword: newPassword,
      token: token
    });
    var data = res.data
    console.log("forgotResetPassword data", data)
    if (data != undefined && data.success) {
      setSession(null);
      dispatch({ type: 'LOGOUT' });
      return data;
    } else {
      console.log("forgotResetPassword fail")
      return data;
    }
  };

  const resetPassword = async (oldPassword, newPassword) => {
    const res = await axios.post('/apis/CompanyLogin/resetPassword', {
      "authID": localStorage.getItem("authID"),
      "oldPassword": oldPassword,
      "newPassword": newPassword
    });
    var data = res.data
    console.log("resetPassword data", data)
    if (data != undefined && data.success) {
      setSession(null);
      dispatch({ type: 'LOGOUT' });
    } else {
      console.log("resetPassword fail")
      return data;
    }
  };

  const logout = async () => {
    setSession(null);
    dispatch({ type: 'LOGOUT' });
  };

  const register = async (email, name, password) => {
    const response = await axios.post('/api/authentication/register', {
      email,
      name,
      password
    });
    const { user } = response.data;

    // window.localStorage.setItem('accessToken', accessToken);
    dispatch({
      type: 'REGISTER',
      payload: {
        user
      }
    });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        platform: 'JWT',
        login,
        guestLogin,
        auth,
        logout,
        register,
        resetPassword,
        forgotResetPassword,
        changePassword,
        openForgot,
        checkEmail,
        forgotPassword
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default AuthContext;
