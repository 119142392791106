import React, { useEffect, useState, useMemo } from 'react';
import { Box, ButtonGroup, Button, ButtonBase, Grid, Stack, Typography, MenuItem, Backdrop, CircularProgress } from '@mui/material';
import { Popup } from 'components/Popup/index.js';
import { DefaultInput } from 'ui/DefaultInput/index.js';
import { useLoader } from 'contexts/LoaderContext';
import { enqueueSnackbar } from 'notistack';
import { DefaultButton } from 'ui/DefaultButton';
import { DefaultSelect } from 'ui/DefaultSelect';
import { getShiftDataApi, manageShiftApi, arrayDays, arrayTime } from './ShiftRepository';
import moment from 'moment';
import { Formik } from 'formik';
import * as Yup from 'yup';
import api from 'lib/axios';
import { ConfirmAlert } from 'CustomControls/confirmAlert copy';

const ManageShift = ({ shiftID, setIsOpenedShiftDetails, getData }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [name, setName] = useState("");
    const [selectedStartTime, setSelectedStartTime] = useState(arrayTime[0]);
    const [selectedEndTime, setSelectedEndTime] = useState(arrayTime[8]);
    const [totalShiftHrs, setTotalShiftHrs] = useState("4");
    const [shiftDays, setShiftDays] = useState("");
    const [totalBreaks, setTotalBreaks] = useState(0.0);
    const [deleteConfirmationShouldOpen, setDeleteConfirmationShouldOpen] = useState(false);

    useEffect(() => {
        if (shiftID !== "" && shiftID !== "0") {
            getShiftData();
        }
    }, [shiftID, name]);

    const getShiftData = async () => {
        setIsLoading(true);
        const shiftData = await getShiftDataApi(shiftID);
        if (shiftData) {
            const { name, startTime, endTime, totalShiftHrs, totalBreaks, shiftDays } = shiftData;
            setName(name || '');
            setShiftDays(shiftDays || '');
            setSelectedStartTime(arrayTime.filter((stTime) => startTime == stTime.label)[0] || null);
            setSelectedEndTime(arrayTime.filter((etTime) => endTime == etTime.label)[0] || null);
            setTotalShiftHrs(totalShiftHrs || '');
            setTotalBreaks(totalBreaks || 0);
        }
        setIsLoading(false);
    };

    const validateFormData = () => {
        if (!name.trim()) {
            return "Name is required.";
        } else if (!selectedStartTime) {
            return "Start time is required.";
        } else if (!selectedEndTime) {
            return "End time is required.";
        } else if (!shiftDays) {
            return "Shift days are required.";
        } else if (!totalShiftHrs) {
            return "Total shift hours are required.";
        } else if (totalShiftHrs < 0) {
            return "Shift hours must be 1 Hour or more";
        } else {
            return null; // Data is valid
        }
    };

    const submitForm = async () => {
        const validationData = validateFormData();
        if (validationData) {
            enqueueSnackbar(validationData, {
                anchorOrigin: {
                    horizontal: 'right',
                    vertical: 'top'
                },
                variant: 'error'
            });
            return;
        }
        setIsLoading(true);
        var resData = await manageShiftApi(shiftID == "0" ? "" : shiftID, {

            siteID: localStorage.getItem("siteID"),
            name,
            selectedStartTime: selectedStartTime.label,
            selectedEndTime: selectedEndTime.label,
            shiftDays,
            totalShiftHrs,
            totalBreaks,
            companyId: localStorage.getItem("publicID"),
        });
        if (resData) {
            setIsOpenedShiftDetails("");
        }
        setIsLoading(false);
    };

    async function apiCalling(values) {
        var data = {
            siteID: localStorage.getItem("siteID"),
            name: values.name,
            startTime: values.selectedStartTime.label,
            endTime: values.selectedEndTime.label,
            shiftDays: values.shiftDays,
            totalShiftHrs: values.totalShiftHrs,
            totalBreaks: values.totalBreaks,
            companyId: localStorage.getItem("publicID"),
        }
        console.log("selectedID", shiftID);
        if (shiftID != 0) {
            return await api.put('/apis/companysites/ProductionShifts/' + shiftID, data)
        } else {
            return await api.post('/apis/companysites/ProductionShifts/', data)
        }
    }


    function deleteShift() {
        setIsLoading(true)
        api.delete(`/apis/companysites/ProductionShifts/${localStorage.getItem("siteID")}-${shiftID}`).then((res) => {
            if (res.data.success) {
                enqueueSnackbar("Shift deleted", {
                    anchorOrigin: {
                        horizontal: 'right',
                        vertical: 'top'
                    },
                    variant: 'success'
                });
                setIsOpenedShiftDetails(null)
                getData()
            } else {
                setDeleteConfirmationShouldOpen(false)
                enqueueSnackbar(res.data.message, {
                    anchorOrigin: {
                        horizontal: 'right',
                        vertical: 'top'
                    },
                    variant: 'error'
                });
            }
            setIsLoading(false)
        }).catch(function (error) {
            console.log(error);
        });
    }

    const updateConfirmAlert = (value) => {
        if (value) {
            deleteShift()
        } else {
            setDeleteConfirmationShouldOpen(false)
        }
    }

    return (
        <Popup
            isOpened
            width={700}
            bgcolor={'common.white'}
            sx={{
                bottom: 20,
                right: 20,
                border: '1px solid #DBDDE2',
                borderColor: '#DBDDE2',
                borderRadius: '10px',
            }}
        >
            <Formik
                enableReinitialize
                initialValues={{
                    submit: null,
                    name: name,
                    selectedStartTime: selectedStartTime,
                    selectedEndTime: selectedEndTime,
                    shiftDays: shiftDays,
                    totalShiftHrs: totalShiftHrs,
                    totalBreaks: totalBreaks,
                }}
                validationSchema={Yup
                    .object()
                    .shape({
                        name: Yup.string().required('Name is required'),
                        selectedStartTime: Yup.object().required('Start time is required'),
                        selectedEndTime: Yup.object().required('End time is required'),
                        //shiftDays: Yup.string().required('Shift days is required'),
                        totalShiftHrs: Yup.string().required('Shift hrs is required'),
                        totalBreaks: Yup.string().required('Breaks is required'),
                    })}
                onSubmit={async (values, { resetForm, setErrors, setStatus, setSubmitting }) => {
                    console.log("onsubmit")
                    try {
                        setName(values.name)
                        setSelectedStartTime(values.selectedStartTime)
                        setSelectedEndTime(values.selectedEndTime)
                        setShiftDays(values.shiftDays)
                        setTotalShiftHrs(values.totalShiftHrs)
                        setTotalBreaks(values.totalBreaks)
                        console.log("values.shiftDays", values.shiftDays);
                        if (values.shiftDays == "") {
                            enqueueSnackbar("Shift days is required", {
                                anchorOrigin: {
                                    horizontal: 'right',
                                    vertical: 'top'
                                },
                                variant: 'error'
                            });
                        } else {
                            setIsLoading(true)
                            var data = await apiCalling(values)
                            setIsLoading(false)
                            if (data !== undefined && data.data.success) {
                                console.log("IF")
                                resetForm();
                                setStatus({ success: true });
                                setSubmitting(false);
                                enqueueSnackbar('Shift ' + (shiftID == 0 ? "added" : "updated"), {
                                    anchorOrigin: {
                                        horizontal: 'right',
                                        vertical: 'top'
                                    },
                                    variant: 'success'
                                });
                                setIsOpenedShiftDetails(null)
                                getData()
                            } else {
                                console.log("ELSE")
                                setStatus({ success: false });
                                setErrors({ submit: data.data.message });
                                setSubmitting(false);
                                enqueueSnackbar(data.data.message, {
                                    anchorOrigin: {
                                        horizontal: 'right',
                                        vertical: 'top'
                                    },
                                    variant: 'error'
                                });
                            }
                        }

                    } catch (err) {
                        console.log("error12", err);
                        setIsLoading(false)
                        setStatus({ success: false });
                        setErrors({ submit: err.message });
                        setSubmitting(false);
                    }
                }}
            >
                {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values, setFieldValue }) => (
                    <form
                        onSubmit={handleSubmit}
                        style={{ height: "100%" }}
                    >
                        <Stack px={3} pt={3} pb={3} gap={'32px'} height={'100%'} alignItems={'flex-start'}>
                            <Typography fontSize={20}>Shift Details</Typography>
                            <Box width={'100%'}>
                                <Grid spacing={3} container>
                                    <Grid item xs={8}>
                                        <DefaultInput
                                            label={'Name'}
                                            placeholder={'E.g. xyz'}
                                            value={values.name}
                                            name={"name"}
                                            onBlur={handleBlur}
                                            //onChange={handleChange}
                                            onChange={(e) => {
                                                setName(e.target.value)
                                            }}
                                            error={Boolean(touched.name && errors.name)}
                                            helperText={touched.name && errors.name}
                                        />
                                    </Grid>
                                    <Grid item xs={4} />
                                    <Grid item xs={4}>
                                        <DefaultSelect
                                            onBlur={handleBlur}
                                            onChange={(event, newValue) => {
                                                values.selectedStartTime = newValue
                                                console.log("st", newValue)
                                                console.log("selectedEndTime", selectedEndTime)
                                                if (newValue != null) {

                                                    console.log("StartSel::", moment(selectedEndTime.label, "hh:mm a").utc().format(), ":Start:", moment(newValue.label, "hh:mm a").utc(true).format())
                                                    let timeDiff = moment.duration((moment(selectedEndTime.label, "hh:mm a").utc(true)).diff(moment(newValue.label, "hh:mm a").utc(true))).asHours()
                                                    console.log("Ev:", "Before:", timeDiff)
                                                    if (timeDiff < 0) {
                                                        timeDiff = 24 + timeDiff
                                                    }
                                                    let totalHours = timeDiff == 0 ? 24 : timeDiff

                                                    totalHours -= parseFloat(totalBreaks)
                                                    setTotalShiftHrs(totalHours)
                                                    setSelectedStartTime(newValue)

                                                    console.log("st:", totalHours)

                                                } else {
                                                    console.log("ELSE st:")
                                                }
                                            }}
                                            helperText={touched.selectedStartTime && errors.selectedStartTime && <p>Select Start Time</p>}
                                            error={Boolean(touched.selectedStartTime && errors.selectedStartTime)}
                                            value={values.selectedStartTime}
                                            options={arrayTime}
                                            getOptionLabel={(option) => option.label}
                                            isOptionEqualToValue={(option, value) => value != null ? option._id === value._id : ""}
                                            name="selectedStartTime"
                                            label={"Start Time"}
                                            placeholder="Select Start Time" >

                                        </DefaultSelect>
                                    </Grid>
                                    <Grid item xs={4} >
                                        <DefaultSelect
                                            onBlur={handleBlur}
                                            onChange={(event, newValue) => {
                                                values.selectedEndTime = newValue
                                                if (newValue != null) {
                                                    console.log("EndSel::", moment(newValue.label, "hh:mm a").utc(true).format(), ":Start:", moment(selectedStartTime.label, "hh:mm a").utc(true).format())
                                                    let timeDiff = moment.duration(moment(newValue.label, "HH:mm a").utc(true).diff(moment(selectedStartTime.label, "HH:mm a").utc(true))).asHours()
                                                    if (timeDiff < 0) {
                                                        timeDiff = 24 + timeDiff
                                                    }
                                                    let totalHours = timeDiff == 0 ? 24 : timeDiff

                                                    totalHours -= parseFloat(totalBreaks)
                                                    if (totalHours <= 0) {
                                                        console.log("IF", totalHours);
                                                        enqueueSnackbar("Shift hours must be 1 Hours or more", {
                                                            anchorOrigin: {
                                                                horizontal: 'right',
                                                                vertical: 'top'
                                                            },
                                                            variant: 'error'
                                                        });
                                                    } else {
                                                        console.log("ELSE", totalHours);
                                                        setSelectedEndTime(newValue)
                                                        setTotalShiftHrs(totalHours)
                                                    }
                                                } else {
                                                    setSelectedEndTime(newValue)
                                                }
                                            }}
                                            helperText={touched.selectedEndTime && errors.selectedEndTime && <p>Select End Time</p>}
                                            error={Boolean(touched.selectedEndTime && errors.selectedEndTime)}
                                            value={values.selectedEndTime}
                                            options={arrayTime}
                                            getOptionLabel={(option) => option.label}
                                            isOptionEqualToValue={(option, value) => value != null ? option._id === value._id : ""}
                                            name="selectedEndTime"
                                            label={"End Time"}
                                            placeholder="Select End Time" >

                                        </DefaultSelect>
                                    </Grid>
                                    <Grid item xs={4} />
                                    <Grid item xs={8}>
                                        <Typography mb={1} variant="subtitle1">
                                            Days
                                        </Typography>
                                        <ButtonGroup variant="outlined" color="primary" aria-label="outlined primary button group">
                                            {
                                                arrayDays.map((obj, inx) => {
                                                    var isFound = false
                                                    if (shiftDays != "") {
                                                        isFound = shiftDays.split(",").filter((day) => day == obj).length > 0
                                                    }
                                                    return (<DefaultButton
                                                        type="button"
                                                        id="shiftDays"
                                                        name="shiftDays"
                                                        color={isFound ? 'primary' : 'success'}
                                                        // variant={isFound ? "contained" : "outlined"}
                                                        onClick={(e) => {
                                                            e.preventDefault()
                                                            if (isFound) {
                                                                let days = shiftDays.split(",").filter((day) => day != obj).join(",")
                                                                // shiftDays = days
                                                                setShiftDays(days)
                                                            }
                                                            else {
                                                                let days = ""
                                                                let tempDays = shiftDays + "," + obj

                                                                if (shiftDays != "") {
                                                                    arrayDays.map((subObj) => {
                                                                        tempDays.split(",").map((subDay) => {
                                                                            if (subObj == subDay) {
                                                                                days += subDay + ","
                                                                            }
                                                                        })
                                                                    })
                                                                } else {
                                                                    days = obj + ","
                                                                }
                                                                // values.shiftDays = days.substr(0, days.length - 1)
                                                                setShiftDays(days.substr(0, days.length - 1))

                                                            }

                                                        }}
                                                        key={inx}
                                                    >{obj}</DefaultButton>)
                                                })
                                            }
                                        </ButtonGroup>
                                    </Grid>
                                </Grid>
                            </Box>
                            <Box height={'1px'} width={'80%'} border={'1px solid #DBDDE2'} />
                            <Box width={'100%'}>
                                <Grid spacing={3} container>
                                    <Grid item xs={6} >
                                        <Typography >Total Breaks</Typography>
                                        <Stack direction={'row'} spacing={1} mt={1} width={'100%'}>
                                            <Box flexGrow={1}>
                                                <DefaultButton onClick={(e) => {
                                                    if (totalBreaks != 0) {
                                                        var totalHours = parseFloat(totalShiftHrs) + 0.5
                                                        setTotalShiftHrs(totalHours.toFixed(1))
                                                        setTotalBreaks(parseFloat(totalBreaks) - 0.5)
                                                    }
                                                }} color={'error'}>
                                                    -
                                                </DefaultButton>
                                            </Box>
                                            <Box flexGrow={1}>
                                                <Typography mt={1} sx={{ width: 70, textAlign: "center" }} variant="body1">
                                                    {parseFloat(totalBreaks || 0.0).toFixed(1)} Hrs
                                                </Typography>
                                            </Box>

                                            <DefaultButton color={'success'}
                                                onClick={(e) => {
                                                    if (totalShiftHrs > 0) {
                                                        var totalHours = parseFloat(totalShiftHrs) - 0.5
                                                        setTotalShiftHrs(totalHours.toFixed(1))
                                                        setTotalBreaks(parseFloat(totalBreaks) + 0.5)
                                                    }
                                                }}
                                            >
                                                +
                                            </DefaultButton>
                                        </Stack>
                                    </Grid>

                                    <Grid item xs={12} mt={-2}>
                                        <Typography >(Enter total time for all breaks including lunch Hour and general personel breaks)</Typography>
                                    </Grid>
                                </Grid>
                            </Box>
                            <Box height={'1px'} width={'80%'} border={'1px solid #DBDDE2'} />
                            <Stack spacing={1}>
                                <Typography fontWeight={700}>Total Shift Hours</Typography>
                                <ButtonBase
                                    sx={{
                                        bgcolor: 'blue.light',
                                        px: 3,
                                        py: 1,
                                        borderRadius: '5px',
                                        fontWeight: 700,
                                    }}
                                >
                                    {totalShiftHrs}
                                </ButtonBase>
                            </Stack>
                            <Stack direction={'row'} spacing={1} mt={'auto'} width={'100%'}>
                                <Box flexGrow={1}>
                                    {localStorage.getItem("role") !== "Standard" && shiftID != "0" && (<DefaultButton onClick={() => {
                                        //openAlert()
                                        setDeleteConfirmationShouldOpen(true)
                                    }} color={'error'}>
                                        Delete
                                    </DefaultButton>)}
                                </Box>
                                <DefaultButton onClick={() => setIsOpenedShiftDetails(null)}>Cancel</DefaultButton>
                                <DefaultButton
                                    color={'success'}
                                    disabled={isSubmitting}
                                    type="submit"
                                    onClick={() => {
                                        // openAlert()
                                        console.log("submit", errors);
                                    }}
                                >
                                    Save
                                </DefaultButton>
                            </Stack>
                        </Stack>
                    </form>
                )}
            </Formik>
            {
                deleteConfirmationShouldOpen && (<ConfirmAlert title={"Confirmation"} body={"Are you sure you want to delete?"}
                    updateConfirmAlert={(e, value) => {
                        console.log(e, value)
                        setDeleteConfirmationShouldOpen(false)
                        updateConfirmAlert(value)
                    }} />)
            }
            <Backdrop style={{ zIndex: 1 }} open={isLoading} >
                <CircularProgress color="inherit" />
            </Backdrop>
        </Popup>
    );
};

export default React.memo(ManageShift);
