import React, { useEffect, useState } from 'react';
import { ClusterIcon } from 'assets/icons/Cluster';
import { Metrics } from 'components/Metrics';
import { SearchInput } from 'components/SearchInput';
import { CircleButton } from 'ui/CircleButton';
import { DefaultDataGrid } from 'ui/DefaultDataGrid';
import { Filters } from 'ui/Filters';
import { Backdrop, Box, CircularProgress, MenuItem, Stack } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { PipeProfilesIcon } from 'assets/icons/PipeProfilesIcon.jsx';
import { LinesIcon } from 'assets/icons/LinesIcon.jsx';
import { filterStatus } from 'modules/Dashboard/data/status';
import { getEmployeesList } from './Repository/EmployeeRepository';
import { getSitePerformanceData } from 'modules/Dashboard/Repository/DashboardRepository';
import { columns } from './data/employee';
import { EmployeeNew } from './EmployeeNew';
import { arrayEmployeeStatus, arrayStatus } from './data/ArrayData/status';
import api from 'lib/axios';
import { MetricsEmployeeActivity } from 'components/Metrics/MetricsEmployeeActivity';
import { useLoader } from 'contexts/LoaderContext';
import { ImagePopupOpen } from 'modules/EmployeeDetail/ImagePopup';

export const Employees = () => {
    const { openAlert } = useLoader()
    const [imagePopupOpen, setImagePopupOpen] = useState(false);
    const [image, setImage] = useState("");
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [arrayEmployee, setArrayEmployee] = useState([]);
    const [arrayShift, setArrayShift] = useState([]);
    const [selectedStatus, setSelectedStatus] = useState(-1)
    const [selectedShift, setSelectedShift] = useState(-1)
    const [query, setQuery] = useState("");
    const [filteredArrayData, setFilteredArrayData] = useState([]);
    const [selectedDataID, setSelectedDataID] = useState(null);
    const [employeePopupOpen, setEmployeePopupOpen] = useState(false);
    const [metrics1, setMetrics1] = useState([]);
    const [metrics2, setMetrics2] = useState([
        {
            title: 'Clocked In',
            value: "-",
            color: '#24D5AA',
        },
        {
            title: 'Clocked Out',
            value: "-",
            color: '#ED373B',
        },
    ]);
    const [metrics3, setMetrics3] = useState([
        {
            title: 'Manpower',
            value: '-',
        },
        {
            title: 'Average Hrs',
            value: '-',
            isGreen: true,
        },
        {
            title: 'Total Billable',
            value: '-',
            isGreen: true,
        },
    ]);

    useEffect(() => {
        getData()
    }, []);

    const getData = async () => {
        setIsLoading(true)
        var resData = await getEmployeesList()
        if (resData) {
            // let arrayTempMachine = []
            var filterArray = applyFilters(resData.arrayData, "", selectedStatus, selectedShift)
            setFilteredArrayData(filterArray)
            setArrayEmployee(resData.arrayData)
            setArrayShift(resData.arrayShift)
            var arraySiteSummery = []
            resData.arrayShift.forEach((data) => {
                arraySiteSummery.push(
                    {
                        title: data.name,
                        value: data.totalEmployee ? data.totalEmployee : "-",
                    }
                );
            });
            setMetrics1(arraySiteSummery)
            var arrayMatrix2 = [
                {
                    title: 'Clocked In',
                    value: resData.totalClockIn,
                    color: '#24D5AA',
                },
                {
                    title: 'Clocked Out',
                    value: resData.totalClockOut,
                    color: '#ED373B',
                },
            ]
            setMetrics2(arrayMatrix2)
            var arrayMatrix3 = [
                {
                    title: 'Manpower',
                    value: resData.manPower + "%",
                },
                {
                    title: 'Average Hrs',
                    value: resData.avgTime,
                    isGreen: true,
                },
                {
                    title: 'Total Billable',
                    value: "$"+resData.totalBillable,
                    isGreen: true,
                },
            ]
            setMetrics3(arrayMatrix3)
        }
        setIsLoading(false)
    }


    function handleQueryChange(event) {
        setQuery(event.target.value)
        var filterArray = applyFilters(arrayEmployee, event.target.value, selectedStatus, selectedShift)
        setFilteredArrayData(filterArray)
    }

    function applyFilters(arrayDataAll, query, selectedStatus, selectedShift) {
        var finalArray = []
        console.log("data", localStorage.getItem("siteID"))
        var filteredArraySearch = arrayDataAll.filter((dataObj) => {
            let matches = true;

            const properties = ['firstName', 'middleName', 'lastName'];
            let containsQuery = false;

            properties.forEach((property) => {
                var str = getProperty(dataObj, property) + "";
                if (str.toLowerCase().includes(query.toLowerCase())) {
                    containsQuery = true;
                }
            });
            if (!containsQuery) {
                matches = false;
            }
            return matches;
        });
        finalArray = filteredArraySearch.length > 0 ? [...filteredArraySearch] : [];
        if (selectedStatus !== null && selectedStatus !== -1) {
            var filteredArrayStats = finalArray
                .filter((dataObj) => {
                    let matches = true;
                    let containsQuery = false;
                    if (dataObj.activityStatus == selectedStatus) {
                        containsQuery = true;
                    }
                    if (!containsQuery) {
                        matches = false;
                    }
                    return matches;
                })
            finalArray = [...filteredArrayStats]
        }

        if (selectedShift !== null && selectedShift != -1) {
            var filteredArrayShift = finalArray
                .filter((dataObj) => {
                    let matches = true;
                    let containsQuery = false;

                    if (dataObj.shiftID === selectedShift) {
                        containsQuery = true;
                    }

                    if (!containsQuery) {
                        matches = false;
                    }
                    return matches;
                });
            finalArray = [...filteredArrayShift]
        }

        if (query === "" && selectedStatus === -1 && selectedShift === -1) {
            return arrayDataAll;
        }
        console.log("finalArray", finalArray)
        return finalArray;
    }

    function getProperty(obj, prop) {
        const props = prop.split('.');
        return props.reduce((o, p) => o && o[p], obj);
    }

    function handleOpenPopup(value) {
        openAlert()
        setImagePopupOpen(true)
        setImage(value)
    }

    return (
        <Stack p={2.5} width={'100%'}>
            <Stack direction={'row'} spacing={2}>
                <Filters
                    handleFilerChange={(event) => {
                        setSelectedStatus(event.target.value)
                        var filterArray = applyFilters(arrayEmployee, query, event.target.value, selectedShift)
                        setFilteredArrayData(filterArray)
                    }}
                    value={selectedStatus}
                    name="selectedStatus"
                    placeholder="Select Status"
                >
                    {arrayEmployeeStatus.map((item, index) => (
                        <MenuItem key={index} value={item.id}> {item.value}</MenuItem>
                    ))}
                </Filters>
                <Filters
                    handleFilerChange={(event) => {
                        setSelectedShift(event.target.value)
                        var filterArray = applyFilters(arrayEmployee, query, selectedStatus, event.target.value)
                        setFilteredArrayData(filterArray)
                    }}
                    value={selectedShift}
                    name="selectedShift"
                    placeholder="Select Shift"
                >
                    {arrayShift.map((item, index) => (
                        <MenuItem key={index} value={item._id}> {item.name}</MenuItem>
                    ))}
                </Filters>
                <SearchInput handleSubmit={handleQueryChange} />
            </Stack>
            <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'} mt={2}>
                <Stack direction={'row'} gap={2} rowGap={2} flexWrap={'wrap'}>
                    <MetricsEmployeeActivity data={metrics1} />
                    <Metrics icon={<LinesIcon />} data={metrics2} />
                    <Metrics icon={<ClusterIcon />} data={metrics3} />
                </Stack>
                <CircleButton
                    // onClick={() => {
                    //     setEmployeePopupOpen(true)
                    //     setSelectedDataID(null)
                    // }}
                    onClick={() => {
                        navigate(`/employees/EmployeeDetail`, { state: { id: "" } })
                    }}
                />
            </Stack>
            <Box mt={2} flex={1} border={'1px solid'} borderColor={'grey.light'} borderRadius={'5px'} overflow={'hidden'}>
                <DefaultDataGrid columns={columns(handleOpenPopup)} rows={filteredArrayData}
                    onRowClick={({ id }) => {
                        navigate(`/employees/EmployeeDetail`, { state: { id: id } })
                    }}
                />
            </Box>
            {imagePopupOpen && <ImagePopupOpen image={image} />}
            <Backdrop style={{ zIndex: 1 }} open={isLoading} >
                <CircularProgress color="inherit" />
            </Backdrop>
        </Stack>
    );
};
