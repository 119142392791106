import React, { useEffect, useState } from 'react';
import { Backdrop, Box, CircularProgress, Stack, Typography } from '@mui/material';
import { DefaultDataGrid } from 'ui/DefaultDataGrid/index.js';

import useConfirmAlert from 'CustomControls/confirmAlert';
import { columns, rows } from './DivisionRepository';
import { ManageDivision } from './ManageDivision';
import api from 'lib/axios';

export const SettingDivisionTab = ({ divisionID, setIsOpenedDivisionDetails }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [arrayData, setArrayData] = useState([]);

    useEffect(() => {
        getData()
    }, []);

    function getData() {
        setIsLoading(true)
        api.get('/apis/Divisions/getAll').then((res) => {
            if (res.data.success) {
                var resData = res.data.data
                setArrayData(resData)
            }
            setIsLoading(false)
        }).catch(function (error) {
            console.log(error);
        });
    }

    return (
        <Stack width={'100%'} height={'100%'}>
            <Box bgcolor={'#F6F6F7'} p={2} border={'1px solid #DBDDE2'} borderTop={0} mt={-1}>
                <Typography mb={2} fontWeight={700}>
                    Division Management
                </Typography>
            </Box>
            <Box flex={1} border={'1px solid #DBDDE2'} mt={'-1px'}>
                <DefaultDataGrid columns={columns} rows={arrayData}
                    onRowClick={(e) => {
                        if (localStorage.getItem("role") !== "2") {
                            setIsOpenedDivisionDetails(e.row._id)
                        }
                    }} />
            </Box>
            {divisionID != null && <ManageDivision divisionID={divisionID} setIsOpenedDivisionDetails={setIsOpenedDivisionDetails} getData={getData} />}
            {/* {useConfirmAlert(
                'Delete Shift?',
                'Are you sure you want to delete this Shift?',
                onSuccess,
                () => { }
            )} */}
            <Backdrop style={{ zIndex: 1 }} open={isLoading} >
                <CircularProgress color="inherit" />
            </Backdrop>
        </Stack>
    );
}